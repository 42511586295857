//import api from '@/services/api';
import {api} from "../main";
export default{
    getPalabraClave(){
        return api({
            url: `/api/palabraClave`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
/*
    delete(id){
        let url = `/api/cursos/destroy/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
*/
    save(formData){
        let url          = `/api/palabraClave/store`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },
/*
    update(curso_id, formData){
        return api({
            url:  `/api/cursos/update/${curso_id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },*/
}