<template>
  <div class="container-fluid">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol id="check2" viewBox="0 0 16 16">
        <path
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        />
      </symbol>
      <symbol id="circle-half" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z" />
      </symbol>
      <symbol id="moon-stars-fill" viewBox="0 0 16 16">
        <path
          d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"
        />
        <path
          d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"
        />
      </symbol>
      <symbol id="sun-fill" viewBox="0 0 16 16">
        <path
          d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
        />
      </symbol>
    </svg>

    <header>
      <!-- Fixed navbar -->
      <nav
        class="navbar navbar-expand-lg fixed-top navbar-light py-3"
        data-navbar-on-scroll="light"
        style="
          background-image: none;
          transition: none 0s ease 0s;
          background-color: rgba(245, 242, 252, 0);
        "
      >
        <div class="container-fluid">
          <a class="navbar-brand text-right" href="#">
            <img
              class="ms-3"
              src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/assets/images/logoEstudioApp%20(1).png"
              height="45"
              alt="logo"
          /></a>
        </div>
      </nav>
    </header>

    <!-- Begin page content -->
    <main class="flex-shrink-0 my-5">
      <div class="container-fluid text-center">
        <div class="row d-flex align-items-center">
          <div class="col-sm-12 offset-md-2 col-md-4">
            <h1 class="txt_title">Bienvenido al siguiente nivel educativo</h1>
            <a
                :href="vurl"
              type="button"
              class="btn btn-lg btn-studioAPP my-2"
              variant="outline-warning"
              >Ingresar a nivelAndo: Aprende 👩‍🏫🕮</a
            >
            <iframe width="100%" height="550" src="https://www.youtube.com/embed/yqxStQAvPrQ?si=x2Fo8J8M6a-y_zJQ"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
          </div>

          <div class="col-sm-12 col-md-5">
            <img
              class="img-fluid mx-auto d-block"
              src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/assets/images/logon.png"
              alt=""
            />
          </div>
          <div class="col-1 col-sm-0"></div>
        </div>
      </div>
    </main>
        <!--fbdb4c-->
    <section style="background-color: #F8105E; text-align: right" class="py-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 offset-md-4">
            <h1 class="fw-bold mb-4" style="color: aliceblue">Unete a nuestra comunidad</h1>
            <p class="lead mb-2" style="font-size: x-large; color: aliceblue">
              Aprender nunca fue tan fácil, con nuestra plataforma educativa en
              línea, tu camino al éxito será inigualable, ¡Únete a nuestra
              comunidad y comienza a <b> brillar</b>!
            </p>
            <a
              href="https://nive.la/"
              type="button"
              class="btn btn-lg btn-studioAPP2 my-2"
              btn-lg
              fw-bold
              variant="outline-warning"
              >Conocenos</a
            >
          </div>
          <div class="col-2 col-sm-0"></div>
        </div>
      </div>
    </section>

    <section style="background-color: #0A00B5">
      <div class="container text-center">
        <div class="row">
          <div class="col">


            <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
            >

              <b-carousel-slide img-src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/landing_firebase_tulio/1.png"></b-carousel-slide>
              <b-carousel-slide img-src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/landing_firebase_tulio/2.png"></b-carousel-slide>

              <b-carousel-slide>
                <template #img>
                  <img
                      class="d-block img-fluid w-100"
                      width="1024"
                      height="480"
                      src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/landing_firebase_tulio/3.png"
                      alt="image slot"
                  >
                </template>
              </b-carousel-slide>
              <b-carousel-slide img-src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/landing_firebase_tulio/4.png"></b-carousel-slide>
              <b-carousel-slide img-src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/landing_firebase_tulio/5.png"></b-carousel-slide>
              <b-carousel-slide img-src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/landing_firebase_tulio/6.png"></b-carousel-slide>

            </b-carousel>


          </div>



          </div>
        </div>
    
    </section>


    <footer
      class="footer mt-auto py-5 bg-body-tertiary"
      style="background-color: #212240 !important"
    >
      <div class="container">
        <div class="row d-flex align-items-center py-7">
          <div class="col-12 col-sm-12 col-lg-6 mb-4">
            <a class="text-decoration-none" href="#">
              <img
                src="https://storage.googleapis.com/estudiolab-aprende-cloud-run/assets/images/EstudioAppLogoBlanco.png"
                height="51"
                alt=""
              />
            </a>
            <p class="mt-4 mb-0 text-subtitle">NIVELA MÉXICO</p>
            <p class="text-light mb-0">
              Mezzanine 2B, Av Sta Fe 505-Piso 1 Lomas de Santa Fe, Contadero,
              Cuajimalpa de Morelos, 05348 CDMX, DIF
            </p>
            <p class="text-light mb-0">
              T:
              <a class="text-light" href="tel:+525550232025"
                >+52 55 50 23 2025</a
              ><br />
              E:
              <a class="text-light" href="mailto:info@nive.la">info@nive.la</a>
            </p>
            <p class="mt-4 mb-0 text-subtitle">NIVELA CHILE</p>
            <p class="text-light mb-0">
              T:
              <a class="text-light" href="tel:+56998310222">+56 99 831 0222</a
              ><br />
              E:
              <a class="text-light" href="mailto:quiero@nive.la"
                >quiero@nive.la</a
              >
            </p>
            <p class="mt-4 mb-0 text-subtitle">ELEVA PERÚ</p>
            <p class="text-light mb-0">
              T:
              <a class="text-light" href="tel:+51934007283">+51 934 007 283</a
              ><br />
              E:
              <a class="text-light" href="mailto:info@nive.la">info@nive.la</a>
            </p>
            <p class="mt-4 mb-0 text-subtitle">ELEVA COLOMBIA</p>
            <p class="text-light mb-0">
              T:
              <a class="text-light" href="tel:+573175182065">+57 317 518 2065</a
              ><br />
              E:
              <a class="text-light" href="mailto:info@nive.la">info@nive.la</a>
            </p>
            <div class="d-flex">
              <a href="https://www.linkedin.com/company/nive-la">
                <img
                  class="me-xl-3 me-1"
                  src="https://analiza.estudio.app/landing/assets/img/gallery/linkedin-line1.svg"
                  alt="in"
                  width="20px"
                  height="20px"
                />
              </a>
              <a href="https://www.facebook.com/nivelAedu">
                <img
                  class="me-xl-3 me-1"
                  src="https://analiza.estudio.app/landing/assets/img/gallery/facebook-line1.svg"
                  alt="fb"
                  width="20px"
                  height="20px"
                />
              </a>
              <a href="https://twitter.com/nivela_edu">
                <img
                  class="me-xl-3 me-1"
                  src="https://analiza.estudio.app/landing/assets/img/gallery/twitter-line1.svg"
                  alt="twitter"
                  width="20px"
                  height="20px"
                />
              </a>
              <a href="https://www.instagram.com/nivela_edu/"
                ><img
                  class="me-xl-3 me-1"
                  src="https://analiza.estudio.app/landing/assets/img/gallery/instagram-line1.svg"
                  alt="instragram"
                  width="20px"
                  height="20px"
              /></a>
            </div>
          </div>
          <div class="col-6 col-sm-4 col-lg-4 mb-3 order-3 order-sm-2">
            <div
              class="twitter-timeline twitter-timeline-rendered"
              style="
                display: flex;
                max-width: 100%;
                margin-top: 0px;
                margin-bottom: 0px;
              "
            >
              <iframe
                id="twitter-widget-0"
                scrolling="no"
                frameborder="0"
                allowtransparency="true"
                allowfullscreen="true"
                class=""
                style="
                  position: static;
                  visibility: visible;
                  width: 368px;
                  height: 550px;
                  display: block;
                  flex-grow: 1;
                "
                title="Twitter Timeline"
                src="https://syndication.twitter.com/srv/timeline-profile/screen-name/nivela_edu?dnt=true&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfbWl4ZWRfbWVkaWFfMTU4OTciOnsiYnVja2V0IjoidHJlYXRtZW50IiwidmVyc2lvbiI6bnVsbH0sInRmd19leHBlcmltZW50c19jb29raWVfZXhwaXJhdGlvbiI6eyJidWNrZXQiOjEyMDk2MDAsInZlcnNpb24iOm51bGx9LCJ0ZndfZHVwbGljYXRlX3NjcmliZXNfdG9fc2V0dGluZ3MiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3ZpZGVvX2hsc19keW5hbWljX21hbmlmZXN0c18xNTA4MiI6eyJidWNrZXQiOiJ0cnVlX2JpdHJhdGUiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2xlZ2FjeV90aW1lbGluZV9zdW5zZXQiOnsiYnVja2V0Ijp0cnVlLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3R3ZWV0X2VkaXRfZnJvbnRlbmQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfX0%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;maxHeight=550px&amp;origin=https%3A%2F%2Fanaliza.estudio.app%2F&amp;sessionId=11a1dbe94aee3623cfba8488aa857393388d21d3&amp;showHeader=true&amp;showReplies=false&amp;theme=dark&amp;transparent=false&amp;widgetsVersion=aaf4084522e3a%3A1674595607486"
              ></iframe>
            </div>
            <!--script async="" src="https://platform.twitter.com/widgets.js" charset="utf-8"></script-->
          </div>
        </div>
      </div>
      <section class="py-0 bg-1000">
        <div class="container">
          <div
            class="row justify-content-md-between justify-content-evenly py-3"
          >
            <div
              class="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start"
            >
              <p class="fs--1 my-2 fw-bold text-light">© 2023 nivelA | elevA</p>
            </div>
            <div class="col-12 col-sm-8 col-md-6">
              <p class="fs--1 my-2 text-center text-md-end text-light">
                EstudioApp es un producto de nivelA
              </p>
            </div>
          </div>
        </div>
        <!-- end of .container-->
      </section>
    </footer>
  </div>
</template>
<script>
//import router from '@/router';
export default {
  name: "BlankPage",
  data() {
    return {
      slide: 0,
      logged:0,
      sliding: null,
     // vurl: process.env.VUE_APP_API_URL + "/redirect",
      vurl: process.env.VUE_APP_URL+"/login"
   //   vurl: "https://aprende.estudio.app/login"
    }
  }, methods: {

    async getUserisLogged() {
      let url = `/api/user/logged`;
      const response = await this.$api.get(url);
      let { resultSet } = response.data;
      console.log(resultSet);
      this.logged=resultSet;

      if(this.logged==1){

        this.$router.push({
          path:`/crear-contenido-curricula`
        });

      }

    },

    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  },
  mounted() {
      this.getUserisLogged();
  }
};
</script>

<style scoped>

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {

   padding-right: 0 !important;
   padding-left: 0 !important;
   margin-right: 0 !important;
   margin-left: 0 !important;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}
.bd-mode-toggle {
  z-index: 1500;
}
.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

.txt_title {
  font-family: "Roboto", sans-serif;
}

.btn-studioAPP {
  color: white !important;
  border: 1px solid #0A00B5;
  background-color: #0A00B5 !important;
}

.btn-studioAPP:hover,
.btn-studioAPP:active {
  background-color: white !important;
  color: #952f81 !important;
}


.btn-studioAPP2 {
  color: #952f81 !important;
  border: 1px solid #952f81;
  background-color: white !important;
}

.btn-studioAPP2:hover,
.btn-studioAPP2:active {
  background-color: #952f81 !important;
  color: white !important;

}

.text-light {
  font-size: small;
  font-size: small;
  font-weight: 400;
  line-height: 1.5;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  opacity: 1;
}

.text-subtitle {
  font-size: small;
  font-weight: 400;
  line-height: 1.5;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  opacity: 1;
  color: #717075 !important;
}
body {
  background-image: url("https://storage.googleapis.com/estudiolab-aprende-cloud-run/assets/images/2.png");
  background-repeat: repeat;
}
</style>
