<template>
  <b-container>


    <!-- modal borrar -->
    <b-modal
        v-model="ModalEliminar"
        persistent
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar al usuario <b>"{{ user.name }} </b> con el correo <b>{{user.email}} </b> de la base de datos? Esta accion NO SE PUEDE REVERTIR
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalEliminar"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="deleteUser"
          >
            Eliminar
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- modal editar -->
    <b-modal no-close-on-backdrop
             centered size="lg"
             hide-footer
             no-close-on-esc
             hide-header-close
             ref="ModalUser"
             title="Usuario"
             v-model="ModalUser"
             persistent>
      <b-card
          tag="article"
          class="mb-2">

        <b-alert show variant="info" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          En esta sección, se pueden actualizar ciertos datos del usuario, algunos de estos datos, se actualizan
          automáticamente al momento de que el usuario accede con sus credenciales de google, por ejemplo el avatar.
        </b-alert>

        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>
                <div class="mb-1 my-2">
                  <label for="form_user.name"> <small>Nombre</small></label>
                  <b-form-input
                      class="my-2"
                      ref="form_user.name"
                      v-model="form_user.name"
                      type="text"
                      placeholder="Nombre">
                  </b-form-input>
                </div>

                <label for="" class="mb-2 mt-2"> <small> Roles disponibles</small></label>
                  <v-select
                    class="my-3"
                    placeholder="Listado completo de roles en la plataforma Aprende"
                    v-model="form_user.roles"
                    multiple
                    :options="item_roles"
                    >

                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No resultados para <em>{{ search }}</em
                      >.
                      </template>
                      <em v-else style="opacity: 0.5">Elegir primero.</em>
                    </template>

                </v-select>

                <div class="mb-2 mt-2">
                  <label for="form_user.email"> <small>Correo principal</small></label>
                  <b-form-input
                      ref="form_user.email"
                      class="my-2"
                      v-model="form_user.email"
                      type="text"
                      placeholder="Correo principal"
                  ></b-form-input>
                </div>

                <div class="mb-2 mt-2">
                  <label for="form_user.correo_alternativo"> <small>Correo Alternativo</small></label>
                  <b-form-input
                      ref="form_user.correo_alternativo"
                      class="my-2"
                      v-model="form_user.correo_alternativo"
                      type="text"
                      placeholder="Correo alternativo (opcional)"
                  ></b-form-input>
                </div>

                <div class="mb-2 mt-2">
                  <label><small>Escuelas disponibles en Aprende</small></label>
                  <v-select
                      class="my-3"
                      placeholder="Listado completo de escuelas en la plataforma Aprende"
                      v-model="form_user.escuela"
                      :options="item_escuelas">
                  </v-select>

                </div>

                <div class="mb-2 mt-2">
                  <label><small>Grupos</small></label>
                  <v-select
                      class="my-3"
                      placeholder="Listado completo de grupos en la plataforma Aprende"
                      v-model="form_user.grupos"
                      multiple
                      :options="gruposAprende_items">
                  </v-select>

                </div>

                <b-row>
                  <b-col>
                    <label for="form_user.fecha_creacion"> <small>Fecha de creación</small></label>
                    <b-form-input
                        ref="form_user.fecha_creacion"
                        class="my-2"
                        v-model="form_user.fecha_creacion"
                        type="text"
                        disabled
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <label for="form_user.ultima_fecha_inicio_sesion">
                      <small>
                        Ultimo inicio de sesión a la plataforma
                      </small>
                    </label>
                    <b-form-input
                        ref="form_user.ultima_fecha_inicio_sesion"
                        class="my-2"
                        v-model="form_user.ultima_fecha_inicio_sesion"
                        type="text"
                        disabled
                    ></b-form-input>

                  </b-col>

                </b-row>
                <b-row class="my-2">
                  <b-col>

                    <div v-if="form_user.id>0">
                      <label for="form_user.ultima_fecha_inicio_sesion"> <small>¿Usuario activo?</small></label>
                      <b-form-checkbox
                          class="mt-3"
                          v-model="form_user.estatus_user"
                          value="1">
<!--                        {{ (form_user.estatus_user == 1 ? 'Desactivar' : 'Activar') }}-->
                        Usuario activo
                      </b-form-checkbox>
                    </div>

                  </b-col>
                </b-row>


                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hiddenModalUser()"
                    >
                      Cancelar
                    </b-button>

                    <b-button v-if="form_user.editar===true"
                              variant="info"
                              size="sm"
                              class="float-right mx-2"
                              @click="update"
                    >
                      Actualizar registro
                    </b-button>
                    <b-button v-if="form_user.editar===false"
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                              @click="save()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>


              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>

    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Gestión de usuario"></b-jumbotron>
            </div>

            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible
            >
              En esta sección puede gestionar un usuario por su email, editar sus roles y gestionar su grupos
            </b-alert>

            <b-row>
              <b-col cols="9">

                <b-form-input
                    id="filter-input"
                    v-model="search"
                    type="search"
                    placeholder="Buscar por medio de un correo electrónico"
                >
                </b-form-input>

              </b-col>
              <b-col cols="3" class="d-flex align-items-end justify-content-end ">
                <b-button   @click="searchEmail"
                            class="btn-studioAPP"
                            variant="outline-warning">Buscar
                </b-button>
                <b-button   @click="nuevoUser"
                            class="ml-5 btn-studioAPP"
                            variant="outline-warning"> Nuevo
                </b-button>
              </b-col>

            </b-row>

            <b-row class="my-2">
              <b-col cols="8" class="d-flex align-items-center justify-content-center my-1">

                <b-card
                    :title="user.name"
                    :img-src="user.avatar"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 25rem;"
                    class="mb-2"
                >
                  <hr>
                  <b-card-text>
                    <b>Id</b> - {{user.id}} <br>
                    <b> Email </b> - {{user.email}} <br>
                    <b> Correo alternativo </b> - {{user.correo_alternativo}} <br>
                    <b> Escuela </b> - {{user.escuela_nombre}} <br>
                    <b> Grupos  </b> -
                    <span v-for="(grupo, index) in grupoNames" :key="index">
                      {{ grupo }},
                    </span>
                    <br>
                    <b> Rol (es) </b> -
                    <span v-for="(role, index) in roleNames" :key="index">
                      {{ role }},
                    </span>
                    <br>
                    <b> Fecha de creación </b> - {{user.fecha_creacion}} <br>
                    <b> Última fecha de inicio de sesión </b> - {{user.ultima_fecha_inicio_sesion}} <br>

                    <div v-if="`${user.estatus_user}`==='1' ">
                      <b> Estatus </b> -
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </div>
                    <div v-if="`${user.estatus_user}`==='0' ">
                      <b> Estatus </b> -
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </div>

                    <br>
                  </b-card-text>
                  <div>
                    <b-button @click="EditMode" size="sm" href="#" variant="primary">Editar ✏️</b-button>
                    <b-button variant="danger" size="sm" @click="showModalEliminar()" class="ml-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path
                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                      </svg>
                    </b-button>
                  </div>

                </b-card>

              </b-col>
              <b-col cols="4" class="my-2">
<div class="row">
  <h5>
    <i>
      Este usuario, en su pantalla inicial se cargan estas currículas/Programas de capacitación
    </i>
  </h5>
</div>
     <div class="row my-2" >
       <b-list-group>
         <b-list-group-item v-for="curricula in user.curriculas" :key="curricula.id">
          {{ curricula.nombre_curricula }} <b-button size="sm" class="btn-studioAPP"
                                                      variant="outline-warning" @click="verCursos(curricula.id)"> Ver cursos
                                                      </b-button>
         </b-list-group-item>
       </b-list-group>
     </div>


              </b-col>
            </b-row>




            <hr>
            <h5>
              Cursos
            </h5>
            <br>
            <div class="row my-2" >



                  <b-col v-for="cursos in user.cursos_curricula" cols="12" lg="4" md="6" sm="12" :key="cursos.id">

                    <b-card
                        :title="`${cursos.nombre_curso}`"
                        :img-src=" cursos.imagen "
                        img-alt="Image"
                        img-top
                        tag="article"
                        style="max-width: 20rem;"
                        class="my-2"
                    >
                      <div v-if="cursos.puede_cursar == 0" >  Sin acceso</div>
                      <br>
                      Cursos Faltantes {{cursos.cursos_faltantes}}<br>
                      Curso en vivo? <div v-if="cursos.cursoEnVivo == 1" > Si</div>
                      <div v-else>No</div>
                      ¿Terminado? {{cursos.terminado}}<br>
                      ¿Curso Expirado?
                      <div v-if="cursos.expired==1">
                        ️ Curso con fecha fuera de rango ⚠️
                      </div>
                      Cantidad de Unidades en el {{cursos.unidadesCantidad}}
                    </b-card>

                  </b-col>



            </div>

          </div>

        </div>



      </b-col>



    </b-row>



  </b-container>
</template>

<script>
import GestionUsuariosService from '@/services/GestionUsuariosService';
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
export default {
  mixins:[toast],
  name: "UserGestion",
  mounted() {
    this.getEscuelas();
    this.getRoles();
    this.getGruposAprende();
  },
  data() {
    return {

      ModalEliminar:false,

      grupoNames:[],

      roleNames:[],
      gruposAprende_items:[],
      item_roles:[],
      item_escuelas:[],
      ModalUser:false,
      search:"",

      resultSetEdit:{},
      resultSet:{},

//saven and edit
      form_user: {
        name: '',
        email: '',
        correo_alternativo: '',
        roles:[],
        grupos:[],
        escuela:[],
        estatus_user: 0,
        id: 0,
        fecha_creacion: '',
        ultima_fecha_inicio_sesion: '',
        editar: false,
      },

      //display only
      user: {
        id:0,
        name:"",
        email:"",
        correo_alternativo:"",
        ultima_fecha_inicio_sesion:"",
        avatar:"",
        escuela:0,
        escuela_nombre:"",
        roles:[],
        grupos:[],
        estatus_user:0,
        fecha_creacion:"",
        curriculas:[],
        cursos_curricula:[]
      },
    }
  },
  methods: {

    async verCursos(curricula_id){
      this.user.cursos_curricula=[];
      try {
        const response = await this.$api.get(`/api/gestion-u/curricula-user/${curricula_id}/${this.user.id}`);
        this.user.cursos_curricula   = response.data.cursos_curricula;

        //

        if(!response.data.success){
          this.toast('b-toaster-bottom-center', true, response.data.message, "danger" );
        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, error.message, "danger" );
        Bugsnag.notify(
            new Error('Método: verCursos() ' + error.message)
        );
      }

    },

    async deleteUser(){

      try{
      const response = await GestionUsuariosService.delete(this.form_user.id);
        let {
          success,
          message,
          color
        } = response.data;

      if (success) {
        this.resetForm();
        this.resultSet2 = {};
        this.ModalUser = false;
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    } catch (error) {
      this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      Bugsnag.notify(
          new Error('Método: deleteUser() ' + error.message)
      );
    }

    },
    async update(){
  try{
        let formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("id", this.form_user.id);
            formData.append("name", this.form_user.name);
            formData.append("email", this.form_user.email);
            formData.append("correo_alternativo", this.form_user.correo_alternativo);
            if (this.form_user.estatus_user === false || this.form_user.estatus_user == 0) {
              formData.append("estatus_user", 0);
            } else {
              formData.append("estatus_user", 1);
            }
            formData.append("escuela", this.form_user.escuela.id);

            let arrGrupos = [];
            this.form_user.grupos.forEach(el => {
              arrGrupos.push(el.id);
            });
            formData.append("arrGrupos", arrGrupos);

            let arrRoles = [];
            this.form_user.roles.forEach(el => {
              arrRoles.push(el.id);
            });
            formData.append("arrRoles", arrRoles);

          const response = await GestionUsuariosService.update(this.form_user.id, formData);
          let {
              success,
              message,
              color
          } = response.data;

       if (success) {
         await this.searchEmail(this.form_user.email);
         this.ModalUser = false;
       }
       this.toast('b-toaster-bottom-center', true, message, color);
     } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: update() ' + error.message)
        );
      }

        },//update
    async save(){

        try{
          let formData = new FormData();
              formData.append("name", this.form_user.name);
              formData.append("email", this.form_user.email);
              formData.append("correo_alternativo", this.form_user.correo_alternativo);
              formData.append("escuela", this.form_user.escuela.id);

              let arrGrupos = [];
              this.form_user.grupos.forEach(el => {
                arrGrupos.push(el.id);
              });
              formData.append("arrGrupos", arrGrupos);

              let arrRoles = [];
              this.form_user.roles.forEach(el => {
                arrRoles.push(el.id);
              });
              formData.append("arrRoles", arrRoles);

              const response = await GestionUsuariosService.save(formData);
              let {
                success,
                message,
                color
              } = response.data;

              if (success) {
                //await this.searchEmail(this.form_user.email);
                this.ModalUser = false;
              }
          this.toast('b-toaster-bottom-center', true, message, color);



        } catch (error) {
          this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
          Bugsnag.notify(
              new Error('Método: update() ' + error.message)
          );
        }



      },
    showModalEliminar(){
      this.resultSet          = this.resultSet2;
      this.form_user.id       =  this.resultSet.id;
      this.ModalEliminar=true;

    },
    hideModalEliminar(){
      this.ModalEliminar=false;
    },
    nuevoUser(){
      this.resetForm();
      this.showModalUser();
    },
    resetForm(){
      this.resultSet={};
      this.form_user.name =  this.resultSet.name;
      this.form_user.email =  this.resultSet.email;
      this.form_user.correo_alternativo = this.resultSet.correo_alternativo;
      this.form_user.estatus_user = this.resultSet.estatus_user;
      this.form_user.ultima_fecha_inicio_sesion =  this.resultSet.ultima_fecha_inicio_sesion;
      this.form_user.fecha_creacion =  this.resultSet.created_at;

      this.form_user.escuela=[];
      this.form_user.grupos =[];
      this.form_user.roles =  [];
    },
    EditMode(){

      this.form_user.editar   = true;
      this.resultSet          = this.resultSet2;
      this.showModalUser();
      this.form_user.id       =  this.resultSet.id;
      this.form_user.name     =  this.resultSet.name;
      this.form_user.email    =  this.resultSet.email;
      this.form_user.correo_alternativo = this.resultSet.correo_alternativo;
      this.form_user.estatus_user = this.resultSet.estatus_user;
      this.form_user.ultima_fecha_inicio_sesion =  this.resultSet.ultima_fecha_inicio_sesion;
      this.form_user.fecha_creacion =  this.resultSet.created_at;

      this.form_user.escuela={ id: this.resultSet.escuela.id, label: this.resultSet.escuela.nombre };
      this.form_user.grupos = this.resultSet.grupos.map(group => {
        return {
          id: group.grupos.id,
          label: group.grupos.nombre_grupo
        };
      });

      this.form_user.roles = this.resultSet.roles.map(role => {
        return {
          id: role.id,
          label: role.name
        };
      });

    },
    showModalUser(){
      this.ModalUser=true;
      console.log(this.form_user.editar);
    },
    hiddenModalUser(){
      this.ModalUser=false;
      this.resetForm();
    },
    async getGruposAprende() {
      const response = await this.$api.get("/api/users/GruposUsuario");
      let {gruposUsuarios} = response.data;
      this.gruposAprende_items = gruposUsuarios;
    },
    async getEscuelas(){
      try {
       // let response = await GestionUsuariosService.getEscuelas();
        const response = await this.$api.get("/api/gestion-u/escuelas");
        this.item_escuelas   = response.data.resultSet;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getEscuelas() ' + error.message)
        );
      }
    },
    async getRoles(){
      try {
     //   let response = await GestionUsuariosService.getRoles();
        const response = await this.$api.get("/api/gestion-u/roles");
        this.item_roles   = response.data.resultSet;

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getRoles() ' + error.message)
        );
      }
    },
    async searchEmail() {
      if (this.search === null || this.search.length === 0) {
        this.toast('b-toaster-bottom-center', true, "Favor de ingresar un correo electrónico", "danger" );
      }
      const response = await GestionUsuariosService.getUser(this.search);
            let {
              curriculas,
              resultSet,
              message,
              color,
              success
            } = response.data;

           // console.log(response.data);

      if (success) {
        this.user.cursos_curricula=[];
        this.resultSet = resultSet;
        this.resultSet2 = resultSet;

        this.user.id = resultSet.id;
        this.user.name = resultSet.name;
        this.user.email = resultSet.email;
        this.user.correo_alternativo = resultSet.correo_alternativo;
        this.user.ultima_fecha_inicio_sesion = resultSet.ultima_fecha_inicio_sesion;
        this.user.avatar = resultSet.avatar;
        this.user.escuela_nombre = resultSet.escuela.nombre;
        this.user.estatus_user = resultSet.estatus_user;
        this.roleNames = resultSet.roles.map(role => role.name);
        this.grupoNames = resultSet.grupos.map(grupo => grupo.grupos.nombre_grupo);
        this.user.fecha_creacion = resultSet.fecha;
        this.user.curriculas =curriculas;

        this.toast('b-toaster-bottom-center', true, message, color);
      }else{
        this.toast('b-toaster-bottom-center', true, message, color);
      }

    },
  },
}
</script>

<style lang="scss" scoped>
$studioAPPColor:#FF8250;
.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

.bi-check-circle-fill {
  fill: green;
}

.bi-x-circle-fill {
  fill: red;
}

</style>