//import api from '@/services/api';
import {api} from "../main";

export default{

    getData(){
        return api({
            url: '/api/objetivo-video',
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    get(id){
        return api({
            url:  `/api/objetivo-video/show/${id}`,
            method: 'GET',
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    },

    save(formData){
        let url          = `/api/objetivo-video/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

    update(id, formData){
        return api({
            url:  `/api/objetivo-video/update/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },
}