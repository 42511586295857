import apiAvanza from '@/services/apiAvanza';
export default
{
    requestToken(formData){
        let url          = `/api/generate-token`;
        let response     = apiAvanza({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    },
    getRegistro(user_id,uuid_examen,access_token)
    {
        let url          = `/api/registros/${user_id}/${uuid_examen}`;
        let response   = apiAvanza({
            url: url,
            method: 'GET',
            withCredentials: true,
            /*            headers: {
                            'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
                        }*/
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return response;
    },
    crearCookie(formData){
        let url          = `/api/crear-cookie`;
        let response     = apiAvanza({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, X-Token-Auth, Authorization',
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
        return response;
    }
}