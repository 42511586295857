<template>
  <div class="container-fluid">
    <!-- Begin page content -->
    <main class="flex-shrink-0 my-5">
      <div class="container-fluid text-center">
        <div class="row d-flex align-items-center">

          <img
              class="img-fluid mx-auto d-block"
              src="https://storage.googleapis.com/jalisco-aprende/pagina-web/nivelando.gif"
              alt=""
          />


        </div>
      </div>
    </main>
    <!--fbdb4c-->
    <section style=" text-align: justify">
      <div class="container-fluid">
        <div class="row py-2">
          <div class="col-3">
          </div>
          <div class="col-6">
            <!-- wp:paragraph -->
            <p class="mb-2" style="font-size: large; color: #707070">La suspensión de clases presenciales por COVID-19
              revolucionó la Educación a nivel mundial. Los procesos de enseñanza-aprendizaje se digitalizaron de la
              noche a la mañana, obligando a los docentes a capacitarse intensivamente en los aspectos técnicos de los
              dispositivos y aquellas herramientas que les permitieran continuar impartiendo clase. </p>

            <p class="mb-2" style="font-size: large; color: #707070">
              Dos años después, y con el inminente regreso a clases, nos preguntamos cómo volver al aula y dar el
              siguiente paso. Es momento de integrar todos los conocimientos y habilidades digitales adquiridas y,
              ¡redefinir nuestra práctica educativa!
            </p>

            <p class="mb-2" style="font-size: large; color: #707070">
              Como ocurre en cada ciclo escolar, nivelA renueva su oferta y se adapta a las necesidades de sus escuelas
              en alianza. Por esta razón, queremos presentarte nuestro programa bandera, nivelAndo 2.0, el cual se
              convertirá en un gran aliado de la formación y práctica educativa de hoy.
            </p>

            <p style="font-size: 22PX; color: #F8365D">¿Qué es nivelAndo 2.0?</p>

            <p class="mb-2" style="font-size: large; color: #707070">
              En nivelA hemos desarrollado un currículum que te permitirá familiarizarte con prácticas efectivas que
              incluyen el uso de herramientas digitales + pedagogías/metodologías/secuencias didácticas para facilitar
              el alcance de los objetivos de aprendizaje.
            </p>
            <p class="mb-2" style="font-size: large; color: #707070">
              Cada semana ofertaremos 3 talleres, completamente en vivo, a través de nuestra plataforma de capacitación
              EstudioApp, enfocados en diferentes materias y secciones académicas. De la mano de nuestros coaches
              internacionales, aprenderás a combinar herramientas y pedagogías para diseñar un ambiente de aprendizaje
              balanceado, con el fin de maximizar el tiempo de clase para el desarrollo de procesos cognitivos de mayor
              complejidad, los cuales favorezcan el aprendizaje significativo, ayuden a fortalecer el desarrollo de
              habilidades y competencias, y faciliten las conexiones humanas.
            </p>
            <p align="center" class="py-5">
              <img
                  class="img-fluid mx-auto d-block"
                  src="https://storage.googleapis.com/jalisco-aprende/pagina-web/nivelando2.0/nivelAndo2.jpg"
                  alt=""
              />
            </p>

            <p style="font-size: 22PX; color: #F8365D">¿Cómo funcionan los talleres?</p>
            <p class="mb-2" style="font-size: large; color: #707070">
              Cada taller tiene 4 componentes, que distribuidos a lo largo de 1 hora, permitirán que los docentes puedan
              implementar de manera casi inmediata. Nuestro modelo pedagógico está diseñado para adultos y se encuentra
              alineado a los indicadores de competencias docentes digitales de la UNESCO 3.0 y los estándares para
              educadores de ISTE.
            </p>

            <p class="mb-2" style="font-size: large; color: #707070">
              Nuestro equipo de coaches impartirá los talleres completamente en vivo. Te invitamos a conectarte para
              aprovecharlos al máximo. Además de tener la oportunidad de interactuar con otros docentes, podrás resolver
              tus dudas de manera inmediata. No obstante, sabemos que en algunas ocasiones tu agenda no permitirá que te
              capacites sincrónicamente. Por este motivo los talleres y sus contenidos estarán disponibles durante toda
              la semana para que puedas aprovecharlos de forma práctica.
            </p>

            <p style="font-size: 28PX; color: #F8365D; text-align: center" class="py-5">Cursos autogestivos de nivel 1 y
              nivel 2</p>

            <p class="mb-2" style="font-size: large; color: #707070">nivelA pone a disposición de sus escuelas en
              alianza el curso autogestivo de nivel 1 y 2 para Educador Certificado de Google a través de su plataforma
              <em>EstudioApp</em>.</p>
            <!-- /wp:paragraph -->

            <!-- wp:paragraph -->
            <p class="mb-2" style="font-size: large; color: #707070">Desde esta semana, podrás acceder a la currícula
              autogestiva para cursar la ruta de formación que te ayudará a prepararte para el examen de certificación
              de Educador Certificado de Google de nivel 1 y nivel 2. Nuestra currícula está disponible las 24 horas al
              día, 7 días a la semana, para que puedas avanzar a tu ritmo de aprendizaje, en tus tiempos disponibles.&nbsp;</p>


            <!-- /wp:paragraph -->

            <!-- wp:paragraph -->
            <p style="font-size: 22PX; color: #F8365D">¿En qué consisten los cursos autogestivos de nivel 1
              y nivel 2?</p>
            <!-- /wp:paragraph -->
            <p class="mb-2" style="font-size: large; color: #707070">
              Cada curso está enfocado en una herramienta de Google Workspace para la Educación. A través de un diseño
              instruccional especializado, que incluye microvideos, prácticas y entregables, conocerás las funciones y
              desarrollarás las habilidades básicas de cada herramienta.
            </p>

            <p class="mb-5" style="font-size: large; color: #707070">
              Los cursos son secuenciales, por lo que empezarás por conocer los aspectos más básicos de tu cuenta, y
              progresivamente avanzarás con las funciones técnicas y pedagógicas de las herramientas.
            </p>

            <p style="font-size: 22PX; color: #F8365D">
              ¿Puedo cursar únicamente los cursos de las herramientas que yo decida?
            </p>

            <p class="mb-2" style="font-size: large; color: #707070">
              Nuestro curso está diseñado para asegurarnos que adquieras los conocimientos y desarrolles las habilidades
              necesarias para presentar el examen de Educador Certificado de Google de nivel 1 y nivel 2, por lo que no
              podrás saltarte cursos o adelantarte en la currícula. Deberás acreditar cada unidad para pasar a la
              siguiente. El reporte de tu progreso se hará llegar a tu coordinador mensualmente.
            </p>

            <p class="mb-2" style="font-size: large; color: #707070">
              En nivelA creemos firmemente que el desarrollo profesional de los docentes es el primer paso hacia la
              transformación educativa, y debe ser constante. Te invitamos a conocer nuestro programa de capacitación
              autogestivo de nivel 1 y nivel 2.
            </p>

          </div>
          <div class="col-3">
          </div>

        </div>
      </div>
    </section>


    <footer
        class="footer mt-auto py-5 bg-body-tertiary"
        style="background-color: #373737 !important"
    >
      <div class="container">
        <div
            class="row justify-content-md-between justify-content-evenly py-3"
        >
          <div
              class="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start"
          >
            <p class="fs--1 my-2 fw-bold text-light">© 2023 nivelA | elevA</p>
          </div>
          <div class="col-12 col-sm-8 col-md-6">
            <p class="fs--1 my-2 text-center text-md-end text-light">
              EstudioApp es un producto de nivelA
            </p>
          </div>
        </div>
      </div>

    </footer>
  </div>
</template>
<script>
//import router from '@/router';
export default {
  name: "Programa",
  data() {
    return {
      slide: 0,
      logged: 0,
      sliding: null,
      // vurl: process.env.VUE_APP_API_URL + "/redirect",
      vurl: process.env.VUE_APP_URL + "/login"
    }
  }, methods: {


    async getUserisLogged() {
      let url = `/api/user/logged`;
      const response = await this.$api.get(url);
      let {resultSet} = response.data;

      this.logged = resultSet;

      if (this.logged == 1) {

        this.$router.push({
          path: `/crear-contenido-curricula`
        });

      }

    },

    onSlideStart(slide) {
      console.log(slide + "-");
      this.sliding = true
    },
    onSlideEnd(slide) {
      console.log(slide + "-");
      this.sliding = false
    }
  },
  mounted() {
    //this.getUserisLogged();
  }
};
</script>

<style scoped>

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {

  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
  inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

.txt_title {
  font-family: "Roboto", sans-serif;
}

.btn-studioAPP {
  color: white !important;
  border: 1px solid #0A00B5;
  background-color: #0A00B5 !important;
}

.btn-studioAPP:hover,
.btn-studioAPP:active {
  background-color: white !important;
  color: #952f81 !important;
}


.btn-studioAPP2 {
  color: #952f81 !important;
  border: 1px solid #952f81;
  background-color: white !important;
}

.btn-studioAPP2:hover,
.btn-studioAPP2:active {
  background-color: #952f81 !important;
  color: white !important;

}

.text-light {
  font-size: small;
  font-size: small;
  font-weight: 400;
  line-height: 1.5;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol;
  opacity: 1;
}

.text-subtitle {
  font-size: small;
  font-weight: 400;
  line-height: 1.5;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol;
  opacity: 1;
  color: #717075 !important;
}

body {
  background-image: url("https://storage.googleapis.com/estudiolab-aprende-cloud-run/assets/images/2.png");
  background-repeat: repeat;
}
</style>


<!--
<template>
  <div>Programa</div>
</template>

<script>
export default {
  name: "Programa"
}
</script>

<style scoped>

</style>-->
