<template>
    <b-container>

    <!-- modal administrar usuarios -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="update_students" 
                title="Administrar usuarios"
                v-model="update_students"
                persistent>
            <b-card
                    tag="article"
                    class="mb-2"> 
                <b-card-text>
                    <b-row class="d-flex align-items-start justify-content-start my-1">
                        <b-alert show variant="info" class="p-3 text-justify">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                            </svg>
                            ¿Confirma actualizar la lista de estudiantes? Esto agregará y quitará estudiantes en el grupo de acuerdo a su lista
                        </b-alert>      
                    </b-row>
                   <b-row class="d-flex align-items-start justify-content-start my-1">
                        <div class="w-100">
                        <b-button
                                variant="secondary"
                                size="sm"
                                class="float-right"
                                 @click="importarEstudiantes"
                            >
                           Confirmar
                        </b-button>  

                        <b-button 
                            variant="dark" 
                            size="sm" 
                            class="float-right mx-2"
                           @click="cerrarUpdateStudents()"
                           >
                         Cancelar
                        </b-button>                             
                    </div>
                </b-row>
                </b-card-text>

                </b-card>
     </b-modal>


    <!-- modal administrar usuarios clic administrar -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="modal_administrar_alumnos" 
                title="Administrar usuarios"
                v-model="modal_administrar_alumnos"
                persistent>
            <b-card
                    tag="article"
                    class="mb-2"> 
                <b-card-text>
                    <b-row class="d-flex align-items-start justify-content-start my-1">
                        <b-alert show variant="info" class="p-3 text-justify">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                            </svg>
                            Recuerde que cualquier grupo puede asignarse a una oferta académica desde el momento de su creación de la
                            oferta. Los estudiantes del grupo visualizarán la oferta que usted les haya asignado previamente en la
                            creación de la Oferta Académica.
                        </b-alert>      
                    </b-row>
                   <b-row class="d-flex align-items-start justify-content-start my-1">
                        <div class="w-100">
                        <b-button   target='_blank'
                                    pill
                                    variant="outline-success"
                                    size="sm"
                                    class="float-right"
                                    :href="( (this.grupo_usuario_actual.sheet_url)  ? this.grupo_usuario_actual.sheet_url : '#'  ) "
                            >
                          Lista de estudiantes
                        </b-button >  

                        <b-button 
                            variant="dark" 
                            size="sm" 
                            class="float-right mx-2"
                           @click="actualizarLista"
                           >
                           Sincronizar
                        </b-button>

                          <b-button
                              variant="dark"
                              size="sm"
                              class="float-right mx-2"
                              @click="createUsers"
                          >
                           Nueva carga
                          </b-button>

                          <b-button
                              variant="dark"
                              size="sm"
                              class="float-right mx-2"
                              @click="deleteUsers"
                          >
                            Remover alumnos
                          </b-button>

                    </div>
                </b-row>

                    <b-row>
                    <div class="col-sm-12">
                    
                         <div class="mx-5 px-5">
                         <b-row class="d-flex align-items-end justify-content-end my-1">
                            <div class="col-sm-12 my-2">
                                <b-form-input
                                            id="filter-input"
                                            v-model="filter2"
                                            type="search"
                                            placeholder="Buscar"
                                            >
                                </b-form-input>

                    <b-table class="table data-table my-2"
                            striped="striped"
                            hover="hover"
                            outlined="outlined"
                            :items="grupo_actual_aprende.estudiantes"
                            :fields="grupo_actual_aprende.headers"
                            :current-page="currentPage2"
                            :per-page="perPage2"
                            :filter="filter2"
                            :filter-included-fields="filterOn2"
                            stacked="md"
                            show-empty
                            small
                       >
                        <template #empty="scope">
                            <h5 class="text-center">
                                {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                            </h5>
                        </template>        
                        <template #emptyfiltered="scope">
                            <h5 class="text-center">
                                {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                            </h5>
                        </template>    

                        </b-table>
                              <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                  <div>
                                    <b-pagination
                                        v-model="currentPage2"
                                        :total-rows="totalRows2"
                                        :per-page="perPage2"
                                        align="fill"
                                        size="sm"
                                        class="my-0"
                                    >
                                    </b-pagination>
                                  </div>
                                </div>
                              </div>

                            </div><!-- col-sm-12 my-2  -->
                        </b-row>


                         </div><!--mx-5 px-5 -->
                      
                    </div>
                    </b-row>

                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <b-row class="d-flex align-items-start justify-content-start my-1">
                                <div class="w-100">
                                <b-button
                                        variant="primary"
                                        size="sm"
                                        class="float-right"
                                        @click="cerrarModalAdministrarUsuarios()"
                                    >
                                    Cancelar
                                </b-button>  
                            </div>
                        </b-row>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>

    <!-- modal crear grupo de usuarios -->
     <b-modal   no-close-on-backdrop centered size="lg" 
                hide-footer
                ref="ModalGrupoDeUsuarios" 
                title="Crear grupo de usuarios"
                v-model="ModalGrupoDeUsuarios"
                persistent>
            <b-card
                    tag="article"
                    class="mb-2">
                <b-card-text>
                 <b-row>
                  <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                                <b-form-input
                                    class="my-2"
                                    ref="grupo_usuario_form.nombre"
                                    v-model="grupo_usuario_form.nombre"
                                    type="text"
                                    placeholder="Nombre del curso">
                                </b-form-input>
                                <b-form-textarea
                                    ref="grupo_usuario_form.descripcion"
                                    v-model="grupo_usuario_form.descripcion"
                                    placeholder="Descripción del grupo..."
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                                <b-row class="d-flex align-items-start justify-content-start my-1">
                                        <div class="w-100">
                                    <b-button
                                            variant="primary"
                                            size="sm"
                                            class="float-right"
                                            @click="cerrarModalCrearGrupoUsuarios()"
                                        >
                                            Cancelar
                                        </b-button>  
        
                                        <b-button 
                                            variant="success" 
                                            size="sm" 
                                            class="float-right mx-2"
                                            @click="guardarGrupoUsuario()"
                                        >
                                        Guardar
                                        </b-button>                             
                                    </div>
                                </b-row>
                
                      </b-form>
                   </div>
                   <div class="col-sm-1"></div>
                 </b-row>
                </b-card-text>

                </b-card>
     </b-modal>


        <b-row>                
            <b-col cols="12">
                <div class="card card-block">
                  <div class="card-body p-2">
                    <div class="d-flex justify-content-between align-items-start p-2">
                        <b-jumbotron lead="Grupos"></b-jumbotron>
                    </div>

                  <b-alert
                      class="p-3"
                      show
                      variant="info"
                      dismissible>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                    </svg>
                    Aquí podrá agregar a las personas que podrán consumir las ofertas que usted cree dentro de Aprende
                  </b-alert>
                    <b-row class="d-flex align-items-end justify-content-end my-3">
                            <div class="col-sm-12 text-right">
                                <b-button 
                                    @click="showModalCrearGrupoUsuarios()"
                                    class="btn-studioAPP" 
                                    variant="outline-warning"> Nuevo grupo de usuarios
                                </b-button>
                            </div>
                    </b-row>

                    <div class="mx-5 px-5">
                        <b-row class="d-flex align-items-end justify-content-end my-1">
                        <div class="col-sm-12 my-2">
                        <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Buscar"
                                    >
                            </b-form-input>
                            </div>
                        </b-row>
                       
                   <b-table class="table data-table"
                        striped="striped"
                        hover="hover"
                        outlined="outlined"
                        :items="usuario_items"
                        :fields="usuario_headers"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        small
                       >
                        <template #empty="scope">
                            <h5 class="text-center">
                                {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                            </h5>
                        </template>        
                        <template #emptyfiltered="scope">
                            <h5 class="text-center">
                                {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                            </h5>
                        </template>    

                        <template #cell(administrar)="row">   
                                <b-button variant="outline-primary" size="sm" @click="showModalAdministrarUsuarios(row.item)" class="ml-1">
                                    Administrar
                                </b-button>
                        </template>

                        </b-table>
                        <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        align="fill"
                                        size="sm"
                                        class="my-0"
                                    >
                                    </b-pagination>
                                </div>
                                </div>
                        </div>
                    </div>
                    </div>
                  </div><!-- card card-block -->

            </b-col>


        </b-row>
    </b-container>
</template>

<script>
export default {
data() {
    return {
        update_students:false,
        modal_administrar_alumnos: false,
        grupo_usuario_actual : {
            sheet_url:'#',
            id:0
        },
        grupo_actual_aprende: {
        estudiantes: [],
            headers: [
                {label: 'Nombre',       key: 'nombres'},
                {label: 'Apellidos',    key: 'apellidos'},
                {label: 'Email',        key: 'email'}
            ],
        },
        headersImportar: [
            {label: 'Nombre documento',       key: 'detalles_importacion'},
            {label: 'Escuela',                key: 'escuela_importacion'},
            {label: 'Estatus importación',    key: 'string_estatus_importacion'},
            {label: 'Documento',              key: 'ruta_archivo'},
            {label: 'Importar',               key: 'importar_documento'},
            {label: 'Eliminar',               key: 'eliminar_documento'}
        ],

        ModalGrupoDeUsuarios:false, 

        grupo_usuario_form: {
            nombre: '',
            descripcion: '',
        },
           
        usuario_headers: [
                    {   label: 'Grupo',        key: 'nombre_grupo', class: 'text-start'},
                    {   label: '# Usuarios en el grupo',         key: 'numero_usuarios', class: 'text-center' },
                    {   label: 'Administrar',   key: 'administrar', class: 'text-center' }
                ],
        usuario_items: [],

             ///
            totalRows: 1,
            currentPage: 1,
            perPage: 8,
            pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter: null,
            filterOn: [],
            search: '',  

             ///
            totalRows2: 1,
            currentPage2: 1,
            perPage2: 8,
            pageOptions2: [5, 10, 15, { value: 100, text: "Ver muchos" }],
            filter2: null,
            filterOn2: [],
            search2: '',  
    }
},
mounted () {
    this.grupo_usuario_actual.sheet_url=null;
    this.getGruposUsuarios();
},

methods: {
cerrarUpdateStudents(){
    this.update_students=false;
 //   console.log('cerrar administrar' );
},

  async deleteUsers(){
    const config = {  headers: { 'content-type': 'multipart/form-data' }  }
    let url      = `/api/users/delete-students`;
    let formData = new FormData();
    formData.append("grupo_usuario_aprende_id",  this.grupo_usuario_actual.id);

    const response   = await this.$api.post(url, formData, config );
    console.log(response.data+"<---");

    this.importando_usuarios = false;
  },

  async createUsers(){
    const config = {  headers: { 'content-type': 'multipart/form-data' }  }
    let url      = `/api/users/create-students`;
    let formData = new FormData();
    formData.append("grupo_usuario_aprende_id",  this.grupo_usuario_actual.id);

    const response   = await this.$api.post(url, formData, config );
    console.log(response.data+"<---");

    this.importando_usuarios = false;
    this.updateGrupoEstudiantes();
    await this.getGruposUsuarios();
  },

  async importarEstudiantes() {
        const config = {  headers: { 'content-type': 'multipart/form-data' }  }
        let url      = `/api/users/update-students`;
        let formData = new FormData();
            formData.append("grupo_usuario_aprende_id",  this.grupo_usuario_actual.id);  
            
        const response   = await this.$api.post(url, formData, config );
        console.log(response.data+"<---");
        
        this.importando_usuarios = false;
        this.updateGrupoEstudiantes();
        await this.getGruposUsuarios();
        this.update_students=false;
  
    },
   updateGrupoEstudiantes() {
      this.getEstudiantesGrupo(this.grupo_usuario_actual)
    },
    actualizarLista() {
      this.update_students = true;
    },
    cerrarModalAdministrarUsuarios(){
        this.modal_administrar_alumnos=false;
        this.resetForm();
    },
    showModalAdministrarUsuarios(item){
        //console.log(item);
        this.grupo_usuario_actual.id               = item.id;
        this.grupo_usuario_actual.sheet_url     =item.sheet_url;
        this.modal_administrar_alumnos          =true;

        this.getEstudiantesGrupo(item);
        
    },
    async getEstudiantesGrupo(grupo) {
  
        this.grupo_actual_aprende.estudiantes = [];

        let url      = `/api/users/get-users-grupo-aprende`;
        const config = {  headers: { 'content-type': 'multipart/form-data' }  }
        let formData = new FormData();
            formData.append("sheet_url",                    grupo.sheet_url);
            formData.append("grupo_usuario_aprende_id",     grupo.id);
        const response = await this.$api.post(url, formData, config );


        this.grupo_actual_aprende.estudiantes = response.data.resultSet;
        this.totalRows2              = response.data.cantidad;

    },    
    async getGruposUsuarios(){
            const response   = await this.$api.get(`/api/users/get-grupos-usuarios-aprende`);
            let {   success,
                    message,
                    color,
                    grupos_usuarios,
                } = response.data;
                    this.totalRows      = await grupos_usuarios.length;
                    this.usuario_items = await grupos_usuarios;
                    
            if(success){
                this.toast('b-toaster-bottom-center', true, message, color );   
            }
    },
    cerrarModalCrearGrupoUsuarios(){
        this.ModalGrupoDeUsuarios = false;
        this.resetForm();
    },
    showModalCrearGrupoUsuarios(){
        this.ModalGrupoDeUsuarios = true;
    },
    async guardarGrupoUsuario() {
        if (this.grupo_usuario_form.nombre.trim().length <= 0) {
              this.toast('b-toaster-bottom-center', true, "Todos los campos son obligatorios", 'danger' );
        }
        if (this.grupo_usuario_form.descripcion.trim().length <= 0) {
            this.toast('b-toaster-bottom-center', true, "Todos los campos son obligatorios", 'danger' );
        }
      
        let url      = `/api/users/create-grupos-usuarios-aprende`;
        const config = {  headers: { 'content-type': 'multipart/form-data' }  }
        let formData = new FormData();
            formData.append("nombre_grupo",         this.grupo_usuario_form.nombre);   
            formData.append("descripcion_grupo",    this.grupo_usuario_form.descripcion);
        const response   = await this.$api.post(url, formData, config );

      console.log('response.data');
        console.log(response.data);

        let {       success,
                    message,
                    color
                    } = response.data;

        if(success){
                await this.getEstudiantesGrupo();
                await this.getGruposUsuarios();
                this.ModalGrupoDeUsuarios = false;
                this.resetForm();
            }
            this.toast('b-toaster-bottom-center', true, message, color ); 
    }
    ,resetForm(){
        this.grupo_usuario_form.nombre      = '';
        this.grupo_usuario_form.descripcion = '';
        this.grupo_usuario_actual.sheet_url = '#';
        },

            //método del toast
       toast(toaster, append = false,content,color) {
        this.counter++
        this.$bvToast.toast(content, {
            title: 'Nivelando',
            autoHideDelay: 3500,
            toaster: toaster,
            variant: color,
            appendToast: append
        });
        },
},
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

</style>