<template>
  <b-container>
    <b-row class="d-flex align-items-end justify-content-end my-1">


      <b-link href="#" @click="examen_avanza()" class="card-link">Examen</b-link>

<!--      <iframe
          :src="iframeSrc"
          width="100%"
          height="600"
          sandbox="allow-same-origin allow-scripts"
      >
      </iframe>-->





    </b-row>


  </b-container>
</template>

<script>

import ExamenNivelando from '@/services/ExamenNivelando';

export default {
  name: "ExamenAvanza",
  data() {
    return {
      iframeSrc: null
    }
  },
  created() {
    //this.examen_avanza();
    },
  methods: {
    async examen_avanza(){
      //creando cookie
      let user_id=10;        //<----------AQUI VA EL ID DEL USUARIO
      let uuid_examen=1;     //<----------AQUI VA EL ID DEL EXAMEN Q QUIERES RECIBIR
      let formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("uuid_examen", uuid_examen);
      const cookie = await ExamenNivelando.crearCookie(formData);
      console.log(cookie+"");

      let formToken = new FormData();
      formToken.append("user_id", user_id);
      const response = await ExamenNivelando.requestToken(formToken);
      let {
        access_token
      } = response.data;

      const registroResponse = await ExamenNivelando.getRegistro(user_id,uuid_examen,access_token);
      this.iframeSrc = `${registroResponse.data.ruta}`;
      window.open(this.iframeSrc, '_blank', 'width=800,height=600');
    },



    openIFrame() {
      this.examen_avanza();
      //const url = `https://avanza.estudiolab.app/aprende/examen?uuidexamen=${examen}&user=${usr}`;
      //window.open(url, '_blank', 'width=800,height=600');
      window.open(this.iframeSrc, '_blank', 'width=800,height=600');
    }
  }
}
</script>

<style scoped>

</style>