//import api from '@/services/api';
import {api} from "../../main";
export default{
    get(){
        return api({
            url: `/api/client/keys`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

}