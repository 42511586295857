<template>
  <b-container fluid="lg">

    <!-- modal INSCRIPCION access -->
    <b-modal
        hide-header-close
        v-model="ModalInscripcion"
        persistent
        no-close-on-esc
        title="Inscripción a simulacro"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="text-center">
          <b-col cols="12">
            Vas a inscribir a este alumno
          </b-col>
        </b-row>

        <b-row class="text-center">
          <b-col cols="12">
            <v-select
                class="my-3"
                placeholder="Opciones"
                v-model="inscripciones"
                :options="inscripciones_items"
                @input="cargarOpciones"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No resultados para <em>{{ search }}</em
                >.
                </template>
                <em v-else style="opacity: 0.5">Elegir primero.</em>
              </template>
            </v-select>

            <v-select
                class="my-3"
                placeholder="Opciones"
                v-model="inscripcionesOpcion"
                :options="inscripcionesOpciones_items"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  No resultados para <em>{{ search }}</em
                >.
                </template>
                <em v-else style="opacity: 0.5">Elegir primero.</em>
              </template>
            </v-select>
          </b-col>
        </b-row>

      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="HideModalInscripcion"
          >
            Cancelar
          </b-button>
          <b-button
              @click="inscribir"
              variant="info"
              size="sm"
              class="float-right mx-2"
          >
            Inscribir
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- modal resend email access -->
    <b-modal
        hide-header-close
        v-model="ModalResendAccess"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered>
      <b-container fluid>
        <b-row class="text-center">
          <b-col cols="12">
            ¿ Estás a punto de reenviar un correo al usuario con el siguiente correo : <b>"{{ invitado.email }}"</b> ?,
            Esto con el fin de que el usuario vuelva a entrar a su cuenta
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="HideModalResendInvConfirmacion"
          >
            Cancelar
          </b-button>
          <b-button
              variant="info"
              size="sm"
              class="float-right mx-2"
              @click="resendInvitation"
          >
            Reenviar correo
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- modal disable access -->
    <b-modal
        hide-header-close
        v-model="ModalDisableAccess"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="text-center">
          <b-col cols="12">
            ¿ Estás seguro de eliminar el acceso al usuario con el siguiente correo: <b>"{{ invitado.email }}"</b> ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="HideModalBorrarConfirmacion"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="disableAccess"
          >
            Eliminar acceso
          </b-button>
        </div>
      </template>

    </b-modal>

    <b-row>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">

            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Centro escolar"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              Esta sección te permite Administrar el plantel escolar, la información del plantel escolar y los docentes
              que tienen acceso
            </b-alert>

            <div class="mx-5 ">
              <b-row class="d-flex align-items-center justify-content-center my-1">
                <div>
                  <!--                      img-src="https://picsum.photos/id/192/600/300"-->
                  <b-card
                      :title="escuela.nombre"

                      img-alt="Image"
                      img-top
                      tag="article"
                      style="max-width: 20rem;"
                      class="mb-2"
                  >
                    <b-card-text>
                      <p>📧: {{ this.escuela.domain }}</p>
                      <p>♯ Asientos: {{ this.escuela.cantidad }}</p>
                      <p>🏙️: {{ this.escuela.ciudad }}</p>
                      <p>🚕: {{ this.escuela.direccion }}</p>
                      <p>☎️ {{ this.escuela.telefono }}</p>
                    </b-card-text>

                    <b-button @click="irAgestionEscolar()" v-b-tooltip.right="'Editar la información básica'"
                              variant="primary">Agregar Usuarios ️
                    </b-button>
                  </b-card>
                </div>
              </b-row>

              <b-row class="d-flex align-items-end justify-content-end my-1">
                <div class="col-sm-12 my-2">
                  <b-form-input v-model="filter" debounce="500" placeholder="Buscar" id="filter-input"></b-form-input>
                </div>

                <!--                @sort-changed="onSortChanged"-->
                <b-table
                    :busy="isBusy"
                    class="table data-table"
                    striped="striped"
                    hover="hover"
                    outlined="outlined"
                    :items="invitados.items"
                    :fields="invitados.headers"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    small
                    @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>
                  <template #cell(updated_at)="row">
                    <div v-if="row.item.updated_at !== null ">
                      {{ (row.item.updated_at)?.substring(0, 10) }}
                    </div>
                    <div v-else>
                      <i> <small> No ha entrado</small></i> ⚠️
                    </div>
                  </template>

                  <template #cell(email)="row">
                    <small><i>{{ row.item.email }}</i></small>
                  </template>

                  <template #cell(name)="row">
                    <small><i>{{ row.item.name }}</i></small>
                  </template>

                  <template #empty="scope">
                    <h5 class="text-center">
                      {{ scope.emptyText = 'Por el momento no hay registros que mostrar.' }}
                    </h5>
                  </template>
                  <template #emptyfiltered="scope">
                    <h5 class="text-center">
                      {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                    </h5>
                  </template>


                  <template #cell(estatus_user)="row">
                    <div v-if="row.item.estatus_user !== 0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </div>
                    <div v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </div>
                  </template>

                  <template #cell(actions)="row">
                    <div class="d-flex justify-content-around align-items-center">

                      <div v-if="row.item.estatus_user !== 0">
                        <b-button v-b-tooltip.right="'Inscribir a evento'" variant="link" size="sm"
                                  @click="ShowModalInscripcion(row.item)" class="ml-1">
                          📓
                        </b-button>
                      </div>

                      <div v-if="$store.getters.usuario.role_id==1">
                        <div v-if="row.item.estatus_user !== 0">
                          <b-button v-b-tooltip.right="'Eliminar acceso a Aprende'" variant="danger" size="sm"
                                    @click="ShowModalBorrarConfirmacion(row.item)" class="ml-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-x-lg" viewBox="0 0 16 16">
                              <path
                                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                            </svg>
                          </b-button>
                        </div>
                        <div v-else>
                          <b-button v-b-tooltip.right="'Reenviar correo para acceso a Aprende'" variant="success"
                                    v-b-modal.modal-prevent-closing size="sm"
                                    @click="ShowModalResendInvConfirmacion(row.item)" class="mr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-send" viewBox="0 0 16 16">
                              <path
                                  d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                            </svg>
                          </b-button>
                        </div>
                      </div>
                    </div>

                    <div v-if="$store.getters.usuario.role_id != 1">

                      <div v-if="row.item.estatus_user !== 0">

                      </div>
                      <div v-else>
                        <b-button v-b-tooltip.right="'Reenviar correo para acceso a Aprende'" variant="success"
                                  v-b-modal.modal-prevent-closing size="sm"
                                  @click="ShowModalResendInvConfirmacion(row.item)" class="mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-send" viewBox="0 0 16 16">
                            <path
                                d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                          </svg>
                        </b-button>
                      </div>
                    </div>

                  </template>

                </b-table>

                <div class="d-flex justify-content-between row p-2">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                  <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div>
                      <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          align="fill"
                          size="sm"
                          class="my-0">
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-row>

            </div>

          </div>

        </div>

      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import Bugsnag from "@bugsnag/js";
import Service from '@/services/CentroEscolar/CentroEscolarService.js';
import toast from "@/mixins/ToastMixin.js";
import InscripcionesService from "@/services/InscripcionesService";

export default {
  name: "CentroEscolar",
  mixins: [toast],

  mounted() {
    this.getDocenteInvitados();
    this.getEventosInscripciones();
    this.getData();

  },
  data() {
    return {
      inscripciones: "",
      inscripciones_items: [],
      inscripcionesOpciones_items: [],
      inscripcionesOpcion: null,
      //inscripcionesOpcion: { id: 0, label: 'No inscribir' },
      ModalInscripcion: false,

      ModalDisableAccess: false,
      ModalResendAccess: false,

      escuela: {
        cantidad: 0,
        ciudad: "",
        direccion: "",
        domain: "",
        nombre: "",
        telefono: "",
      },

      invitacionesDisponbiles: 0,

      invitado: {
        id: 0,
        email: ""
      },

      invitados: {
        items: [],
        //$store.getters.usuario.role_id==1 admin
        headers: [{label: 'Correo', key: 'email'},
          {label: 'Nombre', key: 'name'},
          {label: 'Último acceso', key: 'updated_at'},
          {label: 'Activo', key: 'estatus_user', sortable: true},
          //   { label: 'Opción', key: 'inscripciones_usuarios[0].opcion_label', sortable: true },


          /*  { label: 'Opción elegida', key: 'inscripciones_usuarios', formatter: (value, key, item) => {
                return item.inscripciones_usuarios && item.inscripciones_usuarios.length > 0 ? item.inscripciones_usuarios[0].opcion_label : 'No inscrito';
              }},

            { label: 'Evento y Opción', key: 'inscripciones_usuarios', formatter: (value, key, item) => {
                if (item.inscripciones_usuarios && item.inscripciones_usuarios.length > 0) {
                  const primeraInscripcion = item.inscripciones_usuarios[0];
                  const evento = primeraInscripcion.inscripcion ? primeraInscripcion.inscripcion.nombreEvento : 'Evento no especificado';
                  const opcion = primeraInscripcion.opcion_label ? primeraInscripcion.opcion_label : 'Opción no especificada';
                  return `${evento} - ${opcion}`;
                } else {
                  return 'No inscrito';
                }
              }},
  */
          {
            label: 'Evento-Opción', key: 'inscripciones_usuarios', formatter: (value, key, item) => {
              if (item.inscripciones_usuarios && item.inscripciones_usuarios.length > 0) {
                const primeraInscripcion = item.inscripciones_usuarios[0];
                const opcion = primeraInscripcion.opcion_label ? primeraInscripcion.opcion_label : 'Opción no especificada';
                if (this.$store.getters['usuario'].role_id == 1) {
                  const evento = primeraInscripcion.inscripcion ? primeraInscripcion.inscripcion.nombreEvento : 'Evento no especificado';
                  return `${evento} - ${opcion}`;
                } else {
                  return opcion;
                }
              } else {
                return 'No inscrito';
              }
            }
          },

          {label: 'Acciones', key: 'actions', class: 'text-center'}
        ],
      },

      ///
      isBusy:false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, {value: 100, text: "Ver muchos"}],
      filter: "",
      /*filter: 'monica.villanueva@g.nive.la',*/
      filterOn: [],
      search: '',
    }
  },
  computed: {
    formattedData() {
      return this.invitados.items.map(user => {
        const eventos = user.inscripcionesUsuarios.map(inscripcionUsuario => {
          const evento = inscripcionUsuario.inscripcion ? inscripcionUsuario.inscripcion.nombreEvento : 'Evento desconocido';
          const opcion = inscripcionUsuario.opcion_label ? inscripcionUsuario.opcion_label : 'Opción no especificada';
          return `${evento} - ${opcion}`;
        });
        return {
          ...user,
          eventos: eventos.join(', ')
        };
      });
    }
  },
  methods: {

    /*  onSortChanged(ctx) {
        const { sortBy, sortDesc } = ctx;

      },*/

    async inscribir() {
      try {

        let formData = new FormData();
        formData.append("inscripcion_id", this.inscripciones.id);
        formData.append("opcion_uuid", this.inscripcionesOpcion.id);
        formData.append("opcion_label", this.inscripcionesOpcion.label);
        formData.append("user_id", this.invitado.id);

        const response = await InscripcionesService.inscribir(formData);
        let {
          success,
          message,
          color
        } = response.data;

        if (success) {
          this.ModalUser = false;
        }
        this.toast('b-toaster-bottom-center', true, message, color);
        this.HideModalInscripcion();
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: inscribir() ' + error.message)
        );
      }

    },
    cargarOpciones() {
      this.inscripcionesOpcion = "";
      this.inscripcionesOpciones_items = this.inscripciones.opciones;
    },

    async getEventosInscripciones() {
      //cristina.franco@g.nive.la
      try {
        let response = await InscripcionesService.getInscripcionesPorCurricula();
        let {
          message,
          resultSet,
          color
        } = response.data;
        this.inscripciones_items = resultSet;
        // this.totalRows              = cantidad;

        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: getEventosInscripciones() ' + error.message)
        );
      }
    },

    ShowModalInscripcion(item) {
      this.invitado.id = item.id;
     // this.cargarOpciones();
      this.ModalInscripcion = true;
    },
    HideModalInscripcion() {
      this.ModalInscripcion = false;
      this.inscripciones = "";
      this.inscripciones = null;

      this.inscripcionesOpcion = "";
      this.inscripcionesOpcion = null;
      this.inscripcionesOpciones_items = [];

      this.invitado.id = 0
    },
    resetForm() {
      this.invitado.id = 0;
      this.invitado.email = "";
    },
    async resendInvitation() {

      try {
        let response = await Service.resendAccess(this.invitado.id);
        let {success} = response.data;
        if (success) {
          this.resetForm();
          this.ModalResendAccess = false;
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: resendInvitation() ' + error.message)
        );
      }

    },
    async disableAccess() {

      try {
        let response = await Service.disableAccess(this.invitado.id);
        let {success} = response.data;

        // console.log('response.data');
        // console.log(response.data);

        if (success) {
          this.resetForm();
          await this.getDocenteInvitados();
          this.ModalDisableAccess = false;

        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: disableAccessAlumno() ' + error.message)
        );
      }


    },
    HideModalResendInvConfirmacion() {
      this.resetForm();
      this.ModalResendAccess = false;
    },
    ShowModalResendInvConfirmacion(item) {
      this.invitado.email = item.email;
      this.invitado.id = item.id;
      this.ModalResendAccess = true;
    },
    HideModalBorrarConfirmacion() {
      this.resetForm();
      this.ModalDisableAccess = false;
    },
    ShowModalBorrarConfirmacion(item) {
      //console.log(item);
      this.invitado.email = item.email;
      this.invitado.id = item.id;
      this.ModalDisableAccess = true;
    },
    irAgestionEscolar() {
      this.$router.push({
        path: `/admin-docente-escolar`
      });
    },
    async getDocenteInvitados() {
      try {
        this.isBusy = true;
        let response = await Service.getInvitados();
        let {resultSet, cantidad, success} = response.data;
        if (success) {
          //docentes actuales con acceso
          this.totalRows = cantidad;
          this.invitados.items = resultSet;

          console.log("getDocenteInvitados-------->");
          console.log(response.data);
          console.log("getDocenteInvitados-------->");
         // await this.getEventosInscripciones();
          this.currentPage = 1
        }
        this.isBusy = false;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: getDocenteInvitados() ' + error.message)
        );
      }

    },
    //get info current escuela
    async getData() {

      try {
        let response = await Service.get();
        let {resultSet, success} = response.data;

        if (success) {
          this.escuela.ciudad = resultSet.ciudad;
          this.escuela.direccion = resultSet.direccion;
          this.escuela.domain = resultSet.domain;
          this.escuela.nombre = resultSet.nombre;
          this.escuela.telefono = resultSet.telefono;
          this.escuela.cantidad = resultSet.cantidad;
        }
        this.invitacionesDisponbiles = ((this.escuela.cantidad) - (this.totalRows))
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: getData() ' + error.message)
        );
      }

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style scoped>
.bi-check-circle-fill {
  fill: green;
}

.bi-x-circle-fill {
  fill: red;
}
</style>