import { render, staticRenderFns } from "./YoutubeEmb.vue?vue&type=template&id=2851cfbf&scoped=true&"
import script from "./YoutubeEmb.vue?vue&type=script&lang=js&"
export * from "./YoutubeEmb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2851cfbf",
  null
  
)

export default component.exports