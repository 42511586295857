//import api from '@/services/api';
import {api} from "../../main";

export default{

    getOfertas(){
        let url          = `/api/centroEscolar/ofertasParaGestores`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getData(){
        let url          = `/api/centroEscolar`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    resendAccess(id){
        let url = `/api/centroEscolar/resendAccess/${id}`;
        return api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    disableAccess(id){
        let url = `/api/centroEscolar/disableAccess/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getInvitados(){
        let url          = `/api/centroEscolar/invitados`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    get(){
        let url          = `/api/centroEscolar/show`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    save(formData){
        let url          = `/api/centroEscolar/store`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    sendInvitations(formData){
        let url          = `/api/centroEscolar/sendInvitations`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
          //  ,    'X-XSRF-TOKEN-APRENDE': $('meta[name="csrf-token"]').attr('content')
            },
            data: formData
        });
    },

    update(id, formData){
        console.log('entra a servicio update');
        let url          = `/api/centroEscolar/update/${id}`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

    delete(id){
        let url = `/api/centroEscolar/destroy/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    updateUser(id, formData){
        let url          = `/api/users/update/${id}`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

}