<template>
<div style="text-align: center">

  <section>
    <button v-if="corriendo==true" class="mx-2" type="button" @click="stop">
      Stop
    </button>

    <button v-if="corriendo==false" class="mx-2" type="button" @click="playVideo">
      play
    </button>

    <button v-if="corriendo==true" class="mx-2" type="button" @click="pause">
      pause
    </button>
    <button  class="mx-2" type="button" @click="atrasar">
      atrasar
    </button>
    <button  class="mx-2" type="button" @click="adelantar">
    adelantar
    </button>
  </section>

  <section>
    <h2>listening events</h2>
    <youtube
        :video-id="videoId"
        @ready="ready"
        @playing="playing"
        :player-vars="{ rel: 0, showinfo: 0, autoplay: 0 }"
    >
    </youtube>
  </section>

  <button  class="mx-2" type="button" @click="mutear">
    mute
  </button>
  <button  class="mx-2" type="button" @click="desMutear">
    des-mute
  </button>

  <button  class="mx-2" type="button" @click="subirVol">
    subir vol
  </button>

  <button  class="mx-2" type="button" @click="bajarVol">
    bajar vol
  </button>

</div>
</template>

<script>
export default {
  name: "YoutubeEmb",
  data() {
    return {
      time: '00:00',
      duration: '00:00',
      timeId: null,

      corriendo:false,
      videoId: "36YnV9STBqc"
    }
  },
  async mounted() {
   // this.duration = this.formatTime(await this.player.getDuration());
  },
  methods: {

    async mutear(){
      await this.player.mute();
    },

    async subirVol(){
      let vol = await this.player.getVolume();
      this.player.setVolume(vol+20);

      if( this.player.isMuted() ){
        await this.player.unMute();
        this.muteado=false;
      }
    },

    async bajarVol(){
      let vol = await this.player.getVolume();
      this.player.setVolume(vol-20);
    },

    async desMutear(){
      await this.player.unMute();
    },

    async adelantar(){
      let time = await this.player.getCurrentTime();
      console.log(time+15);
      this.player.seekTo(time+15);
    },

    async atrasar(){
      let time = await this.player.getCurrentTime();
      console.log(time+15);
      this.player.seekTo(time-15);
    },

    formatTime(time) {
      time = Math.round(time);
      let minutes = Math.floor(time / 60);
      let seconds = time - minutes * 60;

      seconds = seconds < 10 ? '0' + seconds : seconds;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      return minutes + ':' + seconds;
    },
    ended() {
      this.time = '00:00';
      clearInterval(this.timeId)
    },

    ready (event) {
      this.player = event.target

    },
    playing (event) {
      console.log("playing event..." + event);
/*      this.timeId = setInterval(() => {
        this.player.getCurrentTime().then((time) => {
          this.time = this.formatTime(time + 1);
        });
      }, 100);*/

    },
    change () {
      this.videoId = 'another video id'
    },
    stop () {
      this.player.stopVideo();
      this.corriendo=false;
      console.log("stop method");
    },
    pause () {
      this.player.pauseVideo();
      this.corriendo=false;
      console.log("stop pause");
    },
    async playVideo() {
      this.corriendo=true;
      await this.player.playVideo();

    }
  },
}
</script>

<style scoped>

</style>