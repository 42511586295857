//import api from '@/services/api';
import {api} from "../main";

export default{
    getCsv(escucela_id){
        //escuelacorreoscsv
        return api({
            url: `/api/escuelas-correos-csv/${escucela_id}`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    get(){
        return api({
            url: `/api/escuelas-alumnos-correos`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    getByEscuelaId(escucela_id){
        let url          = `/api/escuela-alumnos-correos/${escucela_id}`;
        return api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
}