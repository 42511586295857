<template>
  <b-container>
    <b-row>
      <b-col cols="12">

        <h1>test v-select</h1>

        <v-select
            placeholder="Elegri"
            v-model="data"
            :options="dataSelect"
        >
        </v-select>

      </b-col>

    </b-row>

  </b-container>

</template>

<script>
export default {
  name: "vselect2",
  data() {
    return {
      data2:{ id: 0, label: 'Todos los grupos...' },
      items2: [
        { label: 'One',      id: 1 },
        { label: 'Two',      id: 2 },
      ],

      data: [
        { name: "Escribe el correo que enviarás", uuid_columnaA: "100",id:1,uuid_columnaB_fk:"" },
        { name: "Da clic en el botón de Redactar", uuid_columnaA: "200",id:2,uuid_columnaB_fk:"" },
        { name: "Abrir gmail", uuid_columnaA: "300",id:3,uuid_columnaB_fk:"" },
        { name: "Da clic en el botón enviar", uuid_columnaA: "400",id:4,uuid_columnaB_fk:"" },
        { name: "Llena los datos de destinatario y asunto", uuid_columnaA: "500",id:5,uuid_columnaB_fk:"57890" },
        //{ name: "Abrir gmail", id: 5 },
      ],
      dataSelect:[
        {id:"7890", label:"concepto 1"},
        {id:"17890", label:"concepto 2"},
        {id:"27890", label:"concepto 3"},
        {id:"37890", label:"concepto 4"},
        {id:"47890", label:"concepto 5"},
        {id:"57890", label:"concepto 6"},
      ]

    }
  },
}
</script>

<style scoped>

</style>