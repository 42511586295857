<template>
  <b-container>
    <Loader/>
  </b-container>
</template>

<script>
import Loader from '@/components/loader/Loader'
export default {
  name: "Login",
  components: {Loader},
  beforeCreate() {
    this.$store.dispatch("fetchUsuario");
  },

}
</script>

<style scoped>
.full-card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.expand-card {
  width: 100%;
  height: 100%;
}
</style>