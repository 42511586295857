      /*
       console.warn( window.location.href + "   <-- window.location.href ");         //  http://localhost:8080/show-conv-login?ayuda_User_ticket=57&amp;expires...
      console.warn( window.location.pathname + "  <-- window.location.pathname ");  //  update-user-info
      console.warn(  router.currentRoute.path + "   <-- router.currentRoute.path ");    // /                                                                         
      console.warn(window.location.search.substr(0)+"   <-- substr " ); // ?ayuda_User_ticket=57&amp%3Bexpires...
    */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import '@babel/polyfill';
import 'mutationobserver-shim';
import Raphael from 'raphael/raphael';
global.Raphael = Raphael;
import Vue from 'vue';
import './plugins';
import App from './App.vue';
import router from './router';
import store from './store';
import './directives';
import 'leaflet/dist/leaflet.css';
import DateFilter from "./filters/date";
import DateIsoFilter from "./filters/isoDate";
// @ is an alias to /src
import axios from "axios";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueYouTubeEmbed from 'vue-youtube-embed';
import './assets/scss/nivelandoGlobal.scss';
import { BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import GlobalMixin from "./mixins/GlobalMixin";

Bugsnag.start({
  apiKey: 'f6b7579a01d799303f1ab5122b0869ce',
  plugins: [new BugsnagPluginVue()]
});


/*      const getCsrfToken = () => {
          const csrfToken = document.cookie
              .split('; ')
              .find(row => row.startsWith('XSRF-TOKEN='))
              ?.split('=')[1];
          return csrfToken;
      };*/

const API_BASE_URL = process.env.VUE_APP_API_URL;

Vue.use({
  install(Vue) {
      Vue.prototype.$api = axios.create({
          baseURL: API_BASE_URL,
          withCredentials: true,
     /*     headers: {
              'X-CSRF-TOKEN': getCsrfToken(),
          }*/
      });
      

      let url = process.env.VUE_APP_API_URL;  //http://localhost:8000
      const routeParams  = window.location.search.substr(0);
      const currentRoute  = window.location.pathname;
      if (currentRoute === "/update-user-info") {
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
              // window.location.href =  url + "/api" + router.currentRoute.fullPath;
              window.location.href = url + "/api" +currentRoute+routeParams;
          });
      }

/*      if (currentRoute === "/registro") {
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
              window.location.href = url + currentRoute;
          });
      }*/


      Vue.prototype.$api.interceptors.request.use(function (config){
          let cookieArray = document.cookie.split(";");

          // this can probably be improved by using a regex.. but this works for now
          for(var i = 0; i < cookieArray.length; i++) {
              let cookiePair = cookieArray[i].split("=");
              if(cookiePair[0].trim() == 'XSRF-TOKEN-APRENDE') {
                  Vue.prototype.$api.defaults.headers.common['X-XSRF-TOKEN-APRENDE'] = decodeURIComponent(cookiePair[1]);
             //     axios.defaults.headers.common['X-XSRF-TOKEN-APRENDE'] = decodeURIComponent(cookiePair[1]);
              }
          }
          return config;
      }, function (error) {
          return Promise.reject(error);
      });

      Vue.prototype.$api.interceptors.response.use(
          function (response) {
            if (response.status === 200 || response.status === 201 || response.status === 204) {
              return Promise.resolve(response);
            } else {
              return Promise.reject(response);
            }
             // return response;



          },
          function (error) {

              if (error.response.status === 401) {
                  const currentRoute  = window.location.pathname;
                  let url = process.env.VUE_APP_API_URL;
                  if (currentRoute === "/update-user-info") {
                      Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                          //window.location.href = url + "/api" + router.currentRoute.fullPath;
                          window.location.href = url + "/api" + window.location.href;
                      });
                  }else{
                      Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                          window.location.href =  process.env.VUE_APP_API_URL + "/login";
                      });
                  }
              }

              if (error.response.status === 419) {
                  Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                     window.location.href =  process.env.VUE_APP_API_URL + "/login";
                  });
              }

              if (error.response.status === 403) {
                    window.location.href =  process.env.VUE_APP_API_URL + "/login";
              }



              return Promise.reject(error);
          }
      );
  },
});
      export const api = Vue.prototype.$api;
      Vue.use(VueYouTubeEmbed);
      Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false;
Vue.component('v-select', vSelect)
Vue.filter("date", DateFilter);
Vue.filter("isoDate", DateIsoFilter);
Vue.mixin(GlobalMixin);

new Vue({
  router,
  store,
  vSelect,
  render: h => h(App)
}).$mount('#app')
