//import api from '@/services/api';
import {api} from "../main";

export default{
    checkQuiz(formData){
        let url          = `/api/check-quiz-user`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },



    savePregunta(formData){
        let url          = `/api/preguntas/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    saveResponses(formData){
        let url          = `/api/preguntas/responsesUser/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },

    updateEntregable(activityId, tipoEntregable ){

        let url                   = `/api/actividades/update/entregable/${activityId}/${tipoEntregable}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;

    },
    updateItemActivity(idItem,descripcion_actividad){
        let url = `/api/actividad/items/update/${idItem}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                descripcion_actividad : descripcion_actividad,
            }
        });
        return response;
    }
    ,deleteItemActivity(id){
        let url = `/api/actividad/items/destroy/${id}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });

        return response;
    },
    saveItemActividad(formData){
        let url          = `/api/actividad/items/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });

        return response;
    },
    getItemsEnActividad(idActividad){
        let url        = `/api/actividad/items/show/${idActividad}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    updateUnidadActividad(idActividadUnidad,nombre_actividad_unidad){
        let url          = `/api/actividades/actividad-unidad/update/${idActividadUnidad}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                nombre_actividad_unidad : nombre_actividad_unidad,
            }

        });
        return response;
    },
    deletePregunta(idPregunta){

        let url          = `/api/preguntas/destroy/${idPregunta}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getPreguntasActivities(itemId){
        let url          = `/api/preguntas/all-preguntas/${itemId}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getAllPreguntasRespuestas(actividad_id){
        let url          = `/api/preguntas/all-preguntas-respuestas/${actividad_id}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getResultadosEvaluaciones(itemId){
        let url          = `/api/resultados/${itemId}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getNombreActividad(itemId){
        let url          = `/api/nombreActividad/${itemId}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getsheetResultadoEvaluacion(itemId){
        let url          = `/api/sheetResultadoEvaluacion/${itemId}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    }
}