<template>
  <b-container fluid="lg">
    <b-row>
      <b-col cols="12">


{{ this.tags.length }}

        <div v-if="this.tags.length>=this.maxTags">
          deshabilitado
              <vue-tags-input
                allow-edit-tags=true
                :style=" (this.tags.length>=this.maxTags) ? 'background-color: #FF4136' : '' "
                v-model="tag"
                :tags="tags"
                @tags-changed="newTags => tags = newTags"
            />
        </div>

        <div v-if="this.tags.length<this.maxTags">
          habilitado
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            @tags-changed="newTags => tags = newTags"
        />
        </div>


          <hr/>
     
      
          <vue-tags-input
            :placeholder=" (this.tags.length>=this.maxTags) ? 'Limite de correos alcanzado' : 'Escribir o pegar aquí los correos separados por un punto y coma' "
            v-model="tag"
            :tags="tags"
            :max-tags=2
            :readonly="(this.tags.length >= this.maxTags)"
            @tags-changed="checkTagLimit"
        />
        <br>

        <hr/>
      
        
        <b-button size="sm" @click="save">
          enviar
        </b-button>
{{this.tags}}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
//http://www.vue-tags-input.com/#/start
import VueTagsInput from '@johmun/vue-tags-input';
export default {
  name: "Tagsinput",
  components: {
    VueTagsInput,
  },
  methods: {
    save() {
      console.log(this.tags)
    },
    checkTagLimit(newTags) {
      if (newTags.length > this.maxTags) {
        // If the number of tags exceeds the limit, remove the last added tag
        newTags.pop();
      } else {
        // Update the 'tags' variable when new tags are added
        this.tags = newTags;
      }
    },
  },
  computed: {

  },
  data() {
    return {
      tag: '',
      tags: [],
      maxTags:2
    }
  },
}
</script>

<style scoped>
.ti-icon-close{
  z-index: 9999;
}





  .vue-tags-input[data-v-61d92e31] {
    max-width: 850px;
  }

    

</style>