import axios from 'axios';
//import {api} from "../main";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization'
    }
});

instance.interceptors.request.use(function (config) {
    let cookieArray = document.cookie.split(";");

    // this can probably be improved by using a regex.. but this works for now
    for (var i = 0; i < cookieArray.length; i++) {
        let cookiePair = cookieArray[i].split("=");

        if (cookiePair[0].trim() == 'XSRF-TOKEN-APRENDE') {
            instance.defaults.headers.common['X-XSRF-TOKEN-APRENDE'] = decodeURIComponent(cookiePair[1]);
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default instance;