//import api from '@/services/api';
import {api} from "../main";

export default{

    getRubrica(unidadActividad_id, unidad_id){
        let url           = `/api/rubricas/get/${unidadActividad_id}/${unidad_id}`;
        const response    =  api.get(url);
        return response;
    },

    getRubricaEdit(rubrica_id){
        let url           = `/api/rubricas/edit/${rubrica_id}`;
        const response    =  api.get(url);
        return response;
    },
    updateRubrica(nombreRubrica, criterios, id, delCriterios, delNiveles){
        
        let response     = api({
            url: '/api/rubricas/update',
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                    nombreRubrica       : nombreRubrica,
                    criterios           : criterios,
                    rubricaId           : id,
                    delCriterios        : delCriterios,
                    delNiveles          : delNiveles
                }      
        });
 
        return response;
       
    },
    saveRubrica(nombreRubrica,criterios,unidad,actividad){

        const response   =  api.post(`/api/rubricas/store`, 
            {
                nombreRubrica       : nombreRubrica,
                criterios           : criterios,
                unidad              : unidad,
                actividad           : actividad
            }
        );
        return response;
    },
    deleteRubrica(id){
        
        let url = `/api/rubricas/delete/${id}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
 
        return response;
    },
    checkRubricaExistsByAct(activity_id){
        let url           = `/api/rubricas/check/${activity_id}`;
        const response    =  api.get(url);
        return response;
    },
    getCriteriosDeEntregable(rubrica_id, entregable_id){
    
        let url           = `/api/rubricas/CriteriosDeEntregable/${rubrica_id}/${entregable_id}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }         
        });
        return response;
    }

    
}