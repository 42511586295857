<template>
  <b-container>

    <b-modal
        hide-header-close
        v-model="modalCalificar"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            Escriba una calificación a consideración entre <b>0 al 100</b> ?
          </b-col>
        </b-row>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            <b-form-input
                class="mt-1"
                ref="entregable.nuevaCalificacion"
                v-model="entregable.nuevaCalificacion"
                type="number"
            >
            </b-form-input>
            <label class="mt-2" for=""><small>Puede añadir comentarios</small></label>
            <b-form-textarea
                ref="entregable.comentarios"
                v-model="entregable.comentarios"
                placeholder="Espacio para redactar..."
                rows="4"
                max-rows="7"
            ></b-form-textarea>

          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalCalificar()"
          >
            Cancelar
          </b-button>
          <b-button
              variant="info"
              size="sm"
              class="float-right mx-2"
              @click="actualizarCalificacion()"
          >
            Actualizar calificación
          </b-button>
        </div>
      </template>

    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron :lead="mensaje"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              En esta sección se muestran todos los entregables y su alumno.
            </b-alert>
            <b-row style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">
              <b-button @click="volver()"
                        class="btn-studioAPP"
                        variant="outline-warning"> Volver
              </b-button>
            </b-row>
            <div class="col-lg-12 my-2">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
            <b-table class="table data-table"
                     striped="striped"
                     hover="hover"
                     outlined="outlined"
                     :items="items"
                     :fields="headers"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">
                  {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                </h5>
              </template>
              <template #emptyfiltered="scope">
                <h5 class="text-center">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </h5>
              </template>
              <template #cell(entregable)="row">
                <div v-if="`${row.item.entregable}`!=='0' ">
                  <a :href="`${row.item.entregable}`" target="blank" class="white--text"> 📃 </a>
                </div>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-around align-items-center">

                  <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                            @click="showModalCalificar(row.item)" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </b-button>

                </div>
              </template>

            </b-table>

            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
//http://localhost:8080/curso-entregables/102
import toast from "../../../mixins/ToastMixin";
import CursoService from "../../../services/CursoService";
import Bugsnag from "@bugsnag/js";
export default {
  name: "CursoEntregables",
  mixins:[toast],
  data() {
    return {
      curso_id : this.$route.params.id,
      mensaje:"",
      modalCalificar:false,
      entregable :{
        id:0,
        calificacion:0,
        entregable:"",
        nombre:"",
        nombre_unidad:"",
        comentarios:"",
        nuevaCalificacion:0
      },

      headers: [
        {   label: 'Nombre',        key: 'nombre', class: 'text-start'},
        {   label: 'Unidad',         key: 'nombre_unidad', class: 'text-center' },
        {   label: 'Entregable',   key: 'entregable', class: 'text-center' },
        {   label: 'Calificacion',   key: 'calificacion', class: 'text-center' },
        {   label: 'Comentarios',   key: 'comentarios', class: 'text-center' },
        {  label: 'Acciones',       key: 'actions', class: 'text-center' }
      ],
     items: [],

      ///
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: '',
    }
  },

  mounted() {
    this.showCursosConEntregables(this.$route.params.id);
  },

  methods: {

    async showCursosConEntregables(curso_id) {

      try {
        let response = await CursoService.getCursoEntregables(curso_id);
        let {   message,
                resultSet,
                color,
                cantidad,
                nombre_curso
        } = response.data;
        this.mensaje = `Todos los entregables de las unidades del curso ${nombre_curso}`
        this.totalRows              = cantidad;
        this.items = resultSet;
        console.log(response.data);

        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: showCursosConEntregables() ' + error.message)
        );
      }


    },

    volver(){
      this.$router.push({
        path:`/salon-cursos`
      });
    },

    async actualizarCalificacion(){
      try {
        let formData = new FormData();
        formData.append("nuevaCalificacion",           this.entregable.nuevaCalificacion);
        formData.append("comentarios",           this.entregable.comentarios);
        formData.append("_method", "PUT");

        const response = await CursoService.updateCalificacionEntregable(this.entregable.id, formData);
        let {   success,
          message,
          color
        } = response.data;
        if(success){
          await this.showCursosConEntregables(this.curso_id);
          this.hideModalCalificar();

        }
        this.toast('b-toaster-bottom-center', true, message, color );
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: actualizarCalificacion() ' + error.message)
        );
      }

    },
    resetForm(){
      this.entregable.calificacion = 0;
      this.entregable.entregable = "";
      this.entregable.id = 0;
      this.entregable.nombre = "";
      this.entregable.nombre_unidad = "";
      this.entregable.comentarios = "";
    },

    showModalCalificar(item){
      this.modalCalificar = true;
      this.entregable.calificacion = item.calificacion;
      this.entregable.comentarios = item.comentarios;
      this.entregable.entregable = item.entregable;
      this.entregable.nuevaCalificacion = item.calificacion
      this.entregable.id = item.id;
      this.entregable.nombre = item.nombre;
      this.entregable.nombre_unidad = item.nombre_unidad;
    },

    hideModalCalificar(){
      this.resetForm();
      this.modalCalificar = false;
    }
  },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>