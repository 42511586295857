//import api from '@/services/api';
import {api} from "../main";

export default{
    saveRespuesta(formData){
        let url          = `/api/respuestas/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },


    updateEntregable(activityId, tipoEntregable ){

        let url                   = `/api/actividades/update/entregable/${activityId}/${tipoEntregable}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;

    },
    updateItemActivity(idItem,descripcion_actividad){
        let url = `/api/actividad/items/update/${idItem}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                descripcion_actividad : descripcion_actividad,
            }
        });
        return response;
    }
    ,deleteItemActivity(id){
        let url = `/api/actividad/items/destroy/${id}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });

        return response;
    },
    saveItemActividad(formData){
        let url          = `/api/actividad/items/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });

        return response;
    },
    getItemsEnActividad(idActividad){
        let url        = `/api/actividad/items/show/${idActividad}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    updateCheckRespuestaCorrecta(paramIdRespuesta,paramIdPregunta){
        let url          = `/api/respuestas/update/${paramIdRespuesta}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                idPregunta : paramIdPregunta,
            }

        });
        return response;
    },
    deleteRespuesta(idRespuesta){

        let url          = `/api/respuestas/destroy/${idRespuesta}`;
        let response     = api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getRespuestas(idPregunta){
        let url          = `/api/respuestas/all-respuestas/${idPregunta}`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    }

}