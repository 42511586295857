import Vue from 'vue';
import Vuex from 'vuex';
import firebase from '@/firebaseConfRtdb/init';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingStatus: "notLoading",
    usuario: {},
    appName: 'Aprende',
    logo: require('../assets/images/logo.png'),
    img: require('../assets/images/logo-dark.png'),
    dark: false,
    namespaced: true,
    user:{
      name:'Bill Yerds',
      image:require('../assets/images/user/1.jpg'),
    },
    databaseRef: null, // Added property for database reference
    users: {}, // Added property for users data
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loadingStatus = status;
    },
    SET_USUARIO(state, usuario) {
      state.usuario = usuario;
   },
    layoutModeCommit (state, payload) {
      state.dark = payload
      if (!payload) {
        state.logo = require('../assets/images/logo.png')
        state.img = require('../assets/images/logo-dark.png')
      } else {
        state.logo = require('../assets/images/logo.png')
        state.img = require('../assets/images/logo.png')
      }
    }
  },
  actions: {

    updateIsOnline(context, userId) {
      const isOnline = 0; // Set the desired isOnline value
//      console.log(`User ${userId} is online: ${isOnline}`);

      const databaseRef = firebase.database().ref('users');
      const userRef = databaseRef.orderByChild('userId').equalTo(userId);
      userRef.once('value', (snapshot) => {
        const users = snapshot.val();
        const userKey = Object.keys(users)[0];
        if (userKey) {
          const userRefToUpdate = databaseRef.child(userKey);
          userRefToUpdate.update({ isOnline: isOnline });
        //  console.log(`User ${userId} is online: ${isOnline}`);
        }
      });

    },
/*    fetchLogin(context) {

      Vue.prototype.$api.get("/api/login-user").then((response) => {
        console.log(response.data);
      });
    },*/
    fetchUsuario(context) {
      context.commit("SET_LOADING_STATUS", "loading");

      Vue.prototype.$api.get("/api/user").then((response) => {
        this.commit("SET_USUARIO", response.data);

        const databaseRef = firebase.database().ref('users');
        let {   email, id, role_id, name } = response.data;
        let isOnline   = 1;


        if (id !== "") {
         //const user = Object.values(this.users).find(user => user.userId === id);
          databaseRef.once('value', (snapshot) => {
            const users = snapshot.val();
            const user = Object.values(users).find(user => user.userId === id);


          if (user) {
//si existe
           // console.log("User exists");
            const userKey = Object.keys(users).find(key => users[key].userId === id);
            const userRef = databaseRef.child(userKey);
            userRef.update({ isOnline: isOnline });

          } else {
//no existe se crea
          //  console.log("usuario NO existe, se crea");
            let newUser = {
              userId: id,
              roleId: role_id,
              username: name,
              email: email,
              isOnline: isOnline
            };

            databaseRef.push().set(newUser);
          }
        });
      }//if
      });
  },
    layoutModeAction (context, payload) {
      context.commit('layoutModeCommit', payload.dark)
    },
    fetchUsers(context) {
      const databaseRef = firebase.database().ref('users');
      databaseRef.on('value', (snapshot) => {
        context.commit("SET_USERS", snapshot.val());
      });
    }
  },
  getters: {
    formattedDate: (state) => {
      console.log((state+"---"));
      return '/';
    },
    usuario: (state) => {
      return state.usuario;
    },
    appName: state => { return state.appName },
    logo: state => { return state.logo },
    img: state => { return state.img },
    image1: state => { return state.user.image },
    name: state => { return state.user.name },
    image2:state => { return state.user.image2},
    image3:state => { return state.user.image3},
    dark: state => { return state.dark },

  },
  modules: {
  }
})
