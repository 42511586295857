<template>
  <b-container fluid="lg">

    <!-- modal crear  -->
    <b-modal   no-close-on-backdrop
               centered size="lg"
               hide-footer
               no-close-on-esc
               hide-header-close
               ref="ModalEscuela"
               title="Cursos"
               v-model="ModalEscuela"
               persistent
    >
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="info" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
         En esta sección, podrá agregar nuevas escuelas, así como su gestor y la cantidad de docentes que ese gestor podrá invitar a la plataforma Aprende.
        </b-alert>
        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  Nombre gestor
                </div>
                <b-form-input v-model="escuela.gestorNombre" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanplantelGestorNombre()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  Correo gestor
                </div>
                <b-form-input v-model="escuela.gestorCorreo" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanplantelGestorCorreo()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  Nombre de plantel
                </div>
                <b-form-input v-model="escuela.nombre" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanplantelNombre()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>


              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                 Lugares <small style="font-size: 7px"></small>
                </div>
                <b-form-input @input.native="handleLugaresInput" placeholder="Cantidad de lugares" v-model="escuela.cantidad" aria-label="Plantel"></b-form-input>

                <b-input-group-append>
                  <b-button @click="cleanplantelCantidad()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  Ciudad
                </div>
                <b-form-input v-model="escuela.ciudad" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanplantelCiudad()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  Dirección
                </div>
                <b-form-input v-model="escuela.direccion" aria-label="Plantel"></b-form-input>
                <b-input-group-append>
                  <b-button @click="cleanplantelDireccion()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  # Teléfono <small style="font-size: 7px"><i>{{digitCount}}</i></small>
                </div>
                <b-form-input @input.native="handleTelefonoInput" placeholder="10 dígitos" v-model="escuela.telefono" aria-label="Plantel"></b-form-input>

                <b-input-group-append>
                  <b-button @click="cleanplantelTelefono()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                  Correo Coach
                </div>
                <b-form-input v-model="escuela.correoCoach" aria-label="correoCoach"></b-form-input>

                <b-input-group-append>
                  <b-button @click="cleanplantelCorreoCoach()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>


              <b-input-group prepend class="my-3" >
                <div class="input-group-text custom_inputs" >
                Correo ip manager
                </div>
                <b-form-input v-model="escuela.correoIpManager" aria-label="correoIpManager"></b-form-input>

                <b-input-group-append>
                  <b-button @click="cleanplantelcorreoIpManager()" variant="outline-secondary btn-studioAPP">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <label for="">Ofertas Académicas</label>
              <b-form-select
                  size="sm"
                  placeholder="Ofertas disponibles"
                  class="my-1"
                  v-model="escuela.oferta"
                  :options="ofertas_items"
                  :selected="escuela.oferta"
              >
                <template #first>
                  <b-form-select-option disabled value="0"> Asignar oferta académica </b-form-select-option>
                </template>
              </b-form-select>

<!--              <v-select
                  class="my-3"
                  placeholder="Elegir una oferta "
                  v-model="escuela.oferta"
                  :options="ofertas_items">
              </v-select>-->

              <b-form-checkbox v-if="escuela.editar==true"
                               class="mr-3"
                               v-model="escuela.estatus"
                               name="check-button"
                               switch>
                Con acceso
              </b-form-checkbox>

              <p class="text-right my-2"><small><i>En caso de aplicar*</i></small></p>
                <b-row class="d-flex align-items-start justify-content-start mb-4">
                  <b-col>
                    <b-form-datepicker locale="es"
                                       reset-button
                                       label-reset-button="Borrar"
                                       placeholder="Fecha vigencia inicial"
                                       v-model="escuela.fecha_vigencia_inicio"
                                       size="sm"
                                       label-help=''
                                       label-today-button='Hoy'
                                       today-button
                                       label-no-date-selected='No se ha elegido fecha'
                    >
                    </b-form-datepicker>
                  </b-col>
                  <b-col>
                    <b-form-datepicker locale="es"
                                       reset-button
                                       label-reset-button="Borrar"
                                       placeholder="Fecha vigencia finalización"
                                       v-model="escuela.fecha_vigencia_finalizacion"
                                       size="sm"
                                       label-help=''
                                       label-today-button='Hoy'
                                       today-button
                                       label-no-date-selected='No se ha elegido fecha'
                    >
                    </b-form-datepicker>
                  </b-col>
                </b-row>

                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="HideModalEscuela"
                    >
                      Cancelar
                    </b-button>

                    <b-button v-if="escuela.editar==true"
                              variant="info"
                              size="sm"
                              class="float-right mx-2"
                              @click="update()"
                    >
                      Actualizar registro
                    </b-button>
                    <b-button v-if="escuela.editar==false"
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                              @click="save()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>

            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>
    </b-modal>

    <b-col cols="12">

      <div class="card card-block">
        <div class="card-body p-2">

          <div class="d-flex justify-content-between align-items-center p-2">
            <b-jumbotron lead="Gestión del centro escolar"></b-jumbotron>
          </div>
          <b-alert
              class="p-3"
              show
              variant="primary"
              dismissible>
            Esta sección te permite Administrar el  plantel escolar, la información del plantel escolar y la cantidad de docentes que tienen acceso
          </b-alert>

          <b-row  style="display: flex; justify-content: flex-end; align-items:end;" class="my-3 mx-1">

            <b-button
                @click="ShowModalEscuela"
                class="btn-studioAPP"
                variant="outline-warning"> Nuevo centro escolar
              💾
            </b-button>
          </b-row>

          <b-row class="d-flex align-items-end justify-content-end my-1">

            <div class="col-sm-12 my-2">
              <b-form-input  v-model="filter" debounce="500" placeholder="Buscar"  id="filter-input"></b-form-input>
            </div>

            <b-table class="table data-table"
                     striped="striped"
                     hover="hover"
                     outlined="outlined"
                     :items="escuelas.items"
                     :fields="escuelas.headers"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >

              <template #cell(actions)="row">
              <div class="d-flex justify-content-around align-items-center">

                <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                          @click="EditMode(row.item)" class="mr-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-pencil-square" viewBox="0 0 20 20">
                    <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
                </b-button>

              </div>
              </template>
            </b-table>


            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0">
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-row>


        </div>

      </div>

    </b-col>

  </b-container>
</template>

<script>
import Bugsnag from "@bugsnag/js";
import Service from '@/services/CentroEscolar/CentroEscolarService.js';
import toast from "@/mixins/ToastMixin.js";

export default {
  name: "AdminGestoresEscolares",
  mixins:[toast],

  mounted () {
    this.getData();
    this.getOfertas();
  },

  data() {
    return {

      ModalEscuela:false,
      digitCount:'',
      escuela:{
        oferta:0,
        correoCoach:"",
        correoIpManager:"",
        estatus:true,
        editar:false,
        id:0,
        ciudad:"",
        cantidad:0,
        direccion:"",
        nombre:"",
        telefono:"",
        gestorId:0,
        gestorNombre:"",
        gestorCorreo:"",
        fecha_vigencia_inicio:null,
        fecha_vigencia_finalizacion:null
      },

      escuelas:{
        items: [],
        headers:  [

          { label: 'Escuela',                 key: 'nombre' },
          { label: 'Dominio',                 key: 'domain' },
          { label: 'Nombre gestor',                 key: 'user.name' },
          { label: 'Email gestor',                 key: 'user.email' },
          { label: 'Usuarios',                 key: 'cantidad' },
          { label: 'Acciones',               key: 'actions' , class: 'text-center' }
        ],
      },
      ofertas_items:[],

      ///
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: ''

    }
  },
  methods: {
    async getOfertas(){

      try {
        let response = await Service.getOfertas();
        let { resultSet, cantidad, success } = response.data;

        if (success) {
          this.totalRows          = cantidad;
          this.ofertas_items     = resultSet;
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getOfertas() ' + error.message)
        );
      }
    },

    EditMode(item){
        this.ModalEscuela = true;
      this.escuela = {
        oferta : item.gestores_ofertas.oferta_id,
                    correoCoach:item.correoCoach,
                    correoIpManager: item.correoIpManager,

                      estatus :(  (item.estatus==1) ? true : false),
                      editar:true,
                      id        : item.id,
                      ciudad    : item.ciudad,
                      cantidad  : item.cantidad,
                      direccion : item.direccion,
                      nombre    : item.nombre,
                      telefono  : item.telefono,
                      gestorId                    : item.user.id,
                      gestorNombre                :item.user.name,
                      gestorCorreo                :item.user.email,
                      fecha_vigencia_inicio       :item.fecha_vigencia_inicio,
                      fecha_vigencia_finalizacion :item.fecha_vigencia_finalizacion
      }

    },

    cleanplantelCorreoCoach(){
      this.escuela.correoCoach="";
    },
    cleanplantelcorreoIpManager(){
        this.escuela.correoIpManager="";
    },

    handleLugaresInput() {
      this.escuela.cantidad = this.escuela.cantidad.replace(/\D/g, '').slice(0, 10);
    },

    handleTelefonoInput() {
      this.escuela.telefono = this.escuela.telefono.replace(/\D/g, '').slice(0, 10);
      this.digitCount = this.escuela.telefono.length;
      if( this.digitCount == 0) {
        this.digitCount=''
      }
    },

    cleanplantelGestorNombre() {
      this.escuela.gestorNombre="";
    },
    cleanplantelGestorCorreo() {
      this.escuela.gestorCorreo="";
    },
    cleanplantelCantidad() {
      this.escuela.cantidad=0;
    },
    cleanplantelNombre() {
      this.escuela.nombre="";
    },
    cleanplantelCiudad() {
      this.escuela.ciudad="";
    },
    cleanplantelDireccion() {
      this.escuela.direccion="";
    },
    cleanplantelTelefono() {
      this.escuela.telefono="";
    },

  isValid(){

      if (!this.escuela.nombre) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el nombre', 'danger');
        return false;
      }

      if (!this.escuela.ciudad) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la ciudad', 'danger');
        return false;
      }

      if (!this.escuela.direccion) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la dirección', 'danger');
        return false;
      }


      if (!this.escuela.telefono) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el teléfono', 'danger');
        return false;
      }

      if (!this.escuela.gestorNombre) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el nombre del gestor', 'danger');
        return false;
      }

      if (!this.escuela.gestorCorreo) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el correo del gestor', 'danger');
        return false;
      }

      if (!this.escuela.correoCoach) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el correoCoach', 'danger');
        return false;
      }

      if (!this.escuela.correoIpManager) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el correoIpManager', 'danger');
        return false;
      }


      if (!this.escuela.oferta) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar una oferta', 'danger');
        return false;
      }
      return true;

    },
  extractDomainFromEmail(email) {
  const parts = email.split('@');
  if (parts.length === 2) {
    return parts[1];
  }
  return null;
},

   async save(){
      if (!this.isValid()) {
        return;
      }

      const { nombre, ciudad, direccion, telefono, gestorNombre, gestorCorreo, fecha_vigencia_inicio, fecha_vigencia_finalizacion,cantidad, correoCoach,  correoIpManager} = this.escuela;
      const formData = new FormData();
            formData.append("nombre", nombre);
            formData.append("ciudad", ciudad);
            formData.append("direccion", direccion);
            formData.append("telefono", telefono);
            formData.append("cantidad", cantidad);
           

            formData.append("domain", this.extractDomainFromEmail(gestorCorreo) );
            formData.append("gestorNombre", gestorNombre);
            formData.append("gestorCorreo", gestorCorreo);

            formData.append("fecha_vigencia_inicio", fecha_vigencia_inicio);
            formData.append("fecha_vigencia_finalizacion", fecha_vigencia_finalizacion);

            formData.append("oferta_id", this.escuela.oferta);
            formData.append("correoCoach", correoCoach);
            formData.append("correoIpManager", correoIpManager);

      try {
        const response = await Service.save(formData);
        const { success, message, color } = response.data;

        if (success)
        {
          await this.getData();
          this.toast('b-toaster-bottom-center', true, message, color);
          //  await this.$router.push({path: `/centro-escolar`});
          this.ModalEscuela=false;
          this.resetForm();
        }
      } catch (error){
        console.error(error);
      }

    },
   async update(){
      //mcastillo@slp.andessanluis.com

/*      if (!this.isValid()) {
        return;
      }*/
      const {correoCoach, correoIpManager, estatus,id, gestorId, nombre, ciudad, direccion, telefono, gestorNombre, gestorCorreo, fecha_vigencia_inicio, fecha_vigencia_finalizacion,cantidad } = this.escuela;

      const formData = new FormData();
            formData.append("id", id);
            formData.append("nombre", nombre);
            formData.append("ciudad", ciudad);

            formData.append("direccion", direccion);
            formData.append("telefono", telefono);
            formData.append("cantidad", cantidad);

            formData.append("domain", this.extractDomainFromEmail(gestorCorreo) );

            formData.append("gestorId", gestorId);
            formData.append("gestorNombre", gestorNombre);
            formData.append("gestorCorreo", gestorCorreo);

            formData.append("fecha_vigencia_inicio", fecha_vigencia_inicio);
            formData.append("fecha_vigencia_finalizacion", fecha_vigencia_finalizacion);

            formData.append("oferta_id", this.escuela.oferta);
            formData.append("correoCoach", correoCoach);
            formData.append("correoIpManager", correoIpManager);

            formData.append("_method", "PUT");

             if(estatus==true){
               formData.append("estatus"                 , 1);
             }else{
               formData.append("estatus"                 , 0);
             }

      try {
        const response = await Service.update(id, formData);
        const { success, message, color } = response.data;
        if (success)
        {
          await this.getData();
          this.toast('b-toaster-bottom-center', true, message, color);
          //  await this.$router.push({path: `/centro-escolar`});
          this.HideModalEscuela();
          this.resetForm();
          this.ModalEscuela=false;
        }
      } catch (error){
        console.error(error);
      }

    },
   async getData(){
      try {
        let response = await Service.getData();
        let { resultSet, cantidad, success } = response.data;

        if (success) {
          this.totalRows          = cantidad;

          this.escuelas.items     = resultSet;
          this.escuelas.items.forEach((escuela) => {
            escuela._rowVariant = escuela.estatus === 0 ? 'danger' : null;
          });
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getData() ' + error.message)
        );
      }
    },

    HideModalEscuela(){
      this.resetForm();
      this.ModalEscuela = false;
    },
    ShowModalEscuela(){
      this.ModalEscuela = true;
    },

    resetForm(){
      this.ModalEscuela = true;
      this.escuela = {
        estatus   :true,
        editar    :false,
        id        : 0,
        ciudad    : "",
        cantidad  : 0,
        direccion : "",
        nombre    : "",
        telefono  : "",
        gestorId                    : 0,
        gestorNombre                :"",
        gestorCorreo                :"",
        fecha_vigencia_inicio       :"",
        fecha_vigencia_finalizacion :""
      }
    },

  }


}
</script>

<style lang="scss" scoped>
$studioAPPColor:#952f81;

.btn-studioAPP
{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active
{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>