//import api from '@/services/api';
import {api} from "../main";

export default{

    getUser(search){
        return api({
            url: `/api/gestion-u/get-user`,
            method: 'post',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                search: search
            }
        });
    },


    ///////////////////////////////////////////
    getEscuelas(){
            return api({
                url: `/api/gestion-u/escuelas`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
                }
            });
        },
    getRoles(){
        return api({
            url: `/api/gestion-u/roles`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getData(search){
        return api({
            url: '/api/gestion-u',
            method: 'post',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                search: search
            }
        });
    },

    save(formData){
        return api({
            url: '/api/gestion-u/store',
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    update(id, formData){
        return api({
            url:  `/api/gestion-u/update/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    delete(id){
        let url = `/api/gestion-u/destroy/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    getEmailUser(email){
        return api({
            url: `/api/configuracion/get-role-email-user/${email}`,
            method: 'get',
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
}