<template>
  <div class="container-fluid">
    <main class="flex-shrink-0 my-5">
      <div class="container-fluid text-center">
        <div class="row d-flex align-items-center">

          <img
              class="img-fluid mx-auto d-block"
              src="https://storage.googleapis.com/jalisco-aprende/pagina-web/nivelando2.0/faqs.gif"
              alt=""
          />


        </div>
      </div>
    </main>
    <section style=" text-align: justify">
      <div class="container-fluid">
        <div class="row py-2">
          <div class="col-3">
          </div>
          <div class="col-6">
            <div class="mi-div mb-5">
              <p style="text-align: center; color:#13C6B9;font-size: x-large; text-align: center;">¿Cómo navegar en los programas de capacitación autogestivos?</p>
            </div>

            <p align="center">
              <iframe width="100%" height="550" src="https://www.youtube.com/embed/91ZPoMHVl8s?si=WR0xic6ysp23t_Yd"
                      title="YouTube video player" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
            </p>


          </div>
          <div class="col-3">
          </div>

        </div>
      </div>
    </section>


    <footer
        class="footer mt-auto py-5 bg-body-tertiary"
        style="background-color: #373737 !important"
    >
      <div class="container">
        <div
            class="row justify-content-md-between justify-content-evenly py-3"
        >
          <div
              class="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start"
          >
            <p class="fs--1 my-2 fw-bold text-light">© 2023 nivelA | elevA</p>
          </div>
          <div class="col-12 col-sm-8 col-md-6">
            <p class="fs--1 my-2 text-center text-md-end text-light">
              EstudioApp es un producto de nivelA
            </p>
          </div>
        </div>
      </div>

    </footer>
  </div>
</template>
<script>
//import router from '@/router';
export default {
  name: "Novedades",
  data() {
    return {
      slide: 0,
      logged: 0,
      sliding: null,
      // vurl: process.env.VUE_APP_API_URL + "/redirect",
      vurl: process.env.VUE_APP_URL + "/login"
    }
  }, methods: {


    async getUserisLogged() {
      let url = `/api/user/logged`;
      const response = await this.$api.get(url);
      let {resultSet} = response.data;

      this.logged = resultSet;

      if (this.logged == 1) {

        this.$router.push({
          path: `/crear-contenido-curricula`
        });

      }

    },

    onSlideStart(slide) {
      console.log(slide + "-");
      this.sliding = true
    },
    onSlideEnd(slide) {
      console.log(slide + "-");
      this.sliding = false
    }
  },
  mounted() {
    //this.getUserisLogged();
  }
};
</script>

<style scoped>

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {

  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
  inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

.txt_title {
  font-family: "Roboto", sans-serif;
}

.btn-studioAPP {
  color: white !important;
  border: 1px solid #0A00B5;
  background-color: #0A00B5 !important;
}

.btn-studioAPP:hover,
.btn-studioAPP:active {
  background-color: white !important;
  color: #952f81 !important;
}


.btn-studioAPP2 {
  color: #952f81 !important;
  border: 1px solid #952f81;
  background-color: white !important;
}

.btn-studioAPP2:hover,
.btn-studioAPP2:active {
  background-color: #952f81 !important;
  color: white !important;

}

.text-light {
  font-size: small;
  font-size: small;
  font-weight: 400;
  line-height: 1.5;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol;
  opacity: 1;
}

.text-subtitle {
  font-size: small;
  font-weight: 400;
  line-height: 1.5;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol;
  opacity: 1;
  color: #717075 !important;
}

body {
  background-image: url("https://storage.googleapis.com/estudiolab-aprende-cloud-run/assets/images/2.png");
  background-repeat: repeat;
}






.mi-div {
  background-color: gray; /* Fondo gris */
  padding: 20px; /* Espacio alrededor del contenido del div */
}

/* Estilos para el texto dentro del div */
.mi-div p {
  color: white; /* Color del texto dentro del div */
}



</style>


<!--
<template>
  <div>Programa</div>
</template>

<script>
export default {
  name: "Programa"
}
</script>

<style scoped>

</style>-->
