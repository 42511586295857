<template>
  <div>

    <b-button @click="abrir">
      abrir
    </b-button>
    HOLA MUNDO
  </div>
</template>

<script>

export default {
  name: "Filechooser.vue",
  data() {
    return {
      developerKey: 'AIzaSyCgVd9Ko_9-rrbb5-oZFLbJYbqLaLmdcRw',
      clientId: "72198095137-om4d4fhos1gsps9isrl1ggkgj3dge7s9.apps.googleusercontent.com",
      appId: "72198095137",
      scope: "https://www.googleapis.com/auth/drive.readonly",



      tokenClient: null,
      accessToken: null,
      pickerInited: false,
      gisInited: false,
    }
  },
  methods: {
    abrir(){
      const showPicker = () => {
        // eslint-disable-next-line no-undef
        const picker = new google.picker.PickerBuilder()
            // eslint-disable-next-line no-undef
            .addView(google.picker.ViewId.DOCS)
            .setOAuthToken(this.accessToken)
            .setDeveloperKey(this.developerKey)
            .setCallback(this.pickerCallback)
            .build();
        picker.setVisible(true);
      }

      // Request an access token.
      this.tokenClient.callback = async (response) => {
        if (response.error !== undefined) {
          throw (response);
        }
        this.accessToken = response.access_token;
        showPicker();
      };

      if (this.accessToken === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        this.tokenClient.requestAccessToken({prompt: 'consent'});
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        this.tokenClient.requestAccessToken({prompt: ''});
      }
    },
    pickerCallback(data) {
      let url = 'nothing';
      // eslint-disable-next-line no-undef
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        // eslint-disable-next-line no-undef
        let doc = data[google.picker.Response.DOCUMENTS][0];
        // eslint-disable-next-line no-undef
        url = doc[google.picker.Document.URL];
      }
      console.log(`You picked: ${url}`);
    },
    onApiLoad() {
      // This is the callback for the Google API script
      // You can access the loaded API here
      // eslint-disable-next-line no-undef
      gapi.load('picker', this.onPickerApiLoad);
    },
    gisLoaded() {
      // This is the callback for the Google Sign-In Client script
      // You can access the loaded client here
      // TODO(developer): Replace with your client ID and required scopes.
      // eslint-disable-next-line no-undef
      this.tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: this.clientId,
        scope: this.scope,
        callback: '', // defined later
      });
      this.gisInited = true;
    },
    onPickerApiLoad() {
      this.pickerInited = true;
    }
  },//methods

  mounted() {
    // The script callbacks will be executed once the scripts are loaded
    // You can call the methods here if needed
    this.gisLoaded();
    this.onApiLoad();
  },
}
</script>

<style scoped>

</style>