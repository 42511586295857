<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <b-jumbotron lead="Mi Salón"></b-jumbotron>
            </div>
            <b-alert
                class="p-3"
                show
                variant="primary"
                dismissible>
              En esta sección se muestran los cursos que están siendo llevados actualmente
            </b-alert>
            <b-row style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">
              <b-button @click="volver()"
                        class="btn-studioAPP"
                        variant="outline-warning"> Volver
              </b-button>

            </b-row>
            <div class="col-lg-12 my-2">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Buscar"
              >
              </b-form-input>
            </div>
            <b-table class="table data-table"
                     striped="striped"
                     hover="hover"
                     outlined="outlined"
                     :items="curso_items"
                     :fields="cursos_fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">
                  {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                </h5>
              </template>
              <template #emptyfiltered="scope">
                <h5 class="text-center">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </h5>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-around align-items-center">

                  <b-button   variant="outline-warning"  size="sm" @click="navegar(row.item.id)" class="btn-studioAPP ml-1">
                    Revisar Entregables
                  </b-button>

                </div>
              </template>

            </b-table>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>


          </div><!--card-body p-2-->
        </div><!--card card-block-->
      </b-col>  <!--cols="12"-->
    </b-row>
  </b-container>
</template>

<script>
import toast from "../../../mixins/ToastMixin";
import CursoService from "../../../services/CursoService";
import Bugsnag from "@bugsnag/js";

export default {
  name: "GeneralCursos",
  mixins:[toast],
  data() {
    return {
      cursos_fields: [
        {   label: 'Clave',         key: 'clave_interna',        sortable: true,    class: 'text-start' },
        {   label: 'Nombre',   key: 'nombre_curso',   sortable: true,    class: 'text-start' },
        {   label: 'Descripción',   key: 'descripcion',   sortable: true,    class: 'text-start' },

        {   label: 'Acciones',      key: 'actions',             sortable: true,    class: 'text-center'}
      ],
      cursos_items: [],

      /////////////
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: '',
    }
  },

  mounted() {
    this.showCursosConEntregables();
    },

  methods: {
    volver(){
      this.$router.push({
        path:`/miSalon`
      });
    },
    navegar(curso_id){
      this.$router.push(`/curso-entregables/${curso_id}`);
      //this.$router.push(`/entregas-general/${curso_id}`);
    },

    async showCursosConEntregables() {

      try {
        let response = await CursoService.getCursosConEntregables();
        let {   message,
                cursos,
                color,
                cantidad
        } = response.data;
        this.curso_items            = cursos;
        this.totalRows              = cantidad;

    //    console.log(this.curso_items);

        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: showCursos() ' + error.message)
        );
      }


    },
  },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>