import { render, staticRenderFns } from "./vselect2.vue?vue&type=template&id=71f55a94&scoped=true&"
import script from "./vselect2.vue?vue&type=script&lang=js&"
export * from "./vselect2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f55a94",
  null
  
)

export default component.exports