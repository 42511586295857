import firebase from "firebase";
import "firebase/firestore"

const firebaseConfig = {
    //test suite
/*    apiKey: "AIzaSyAuPtCnp5TAVh8ihM9b38wKR60XQdCYF-Y",
    authDomain: "vuejs-firebase-rtb.firebaseapp.com",
    databaseURL: "https://vuejs-firebase-rtb-default-rtdb.firebaseio.com",
    projectId: "vuejs-firebase-rtb",
    storageBucket: "vuejs-firebase-rtb.appspot.com",
    messagingSenderId: "649355495179",
    appId: "1:649355495179:web:2ef31c424b2eb41a7bc40e"*/

    /* aprende estudioapp-v2*/
    /*
        apiKey: "AIzaSyAG6tR1HV3Z2M-Q2nBFjD-5vX-_6R6eoQU",
        authDomain: "estudioapp2.firebaseapp.com",
        databaseURL: "https://estudioapp2-default-rtdb.firebaseio.com",
        projectId: "estudioapp2",
        storageBucket: "estudioapp2.appspot.com",
        messagingSenderId: "95089445529",
        appId: "1:95089445529:web:7a4713f819ca4079258608",
        measurementId: "G-TMQLNCBTYB"
     */
        /* aprende estudioapp*/

apiKey: "AIzaSyD-ayywVTv-jkNqyFnq0sFBsQoIGJGrkf0",
authDomain: "estudioapp.firebaseapp.com",
databaseURL: "https://estudioapp.firebaseio.com",
projectId: "estudioapp",
storageBucket: "estudioapp.appspot.com",
messagingSenderId: "589193790165",
appId: "1:589193790165:web:7be8b3327b2460da51d85c",
measurementId: "G-L19RJDR7V5"


};
// Initialize Firebase
var firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp;