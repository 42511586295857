<template>
  <div>

    <b-button variant="link" size="sm" class="my-1" type="button" @click="driveIconClicked();">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 1443.061 1249.993"><path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/><path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/><path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/></svg>
    </b-button>

    <!-- <AttachmentList :tempAttachments="getTempAttachments"/> -->
    <small class="txt_attachmnts"> {{this.fileName}} </small>

  </div>
</template>

<script>
//import AttachmentList from "./AttachmentList";
import ClienteConsola from "../services/ClienteConsola/ClienteConsola";

export default {
  name: "Attachment",
  data() {
    return {
      fileName:'',
      tempAttachments   : [],
      attachments       : [],
      pickerApiLoaded   : false,
      developerKey      : 'AIzaSyCgVd9Ko_9-rrbb5-oZFLbJYbqLaLmdcRw',
      clientId          : "72198095137-om4d4fhos1gsps9isrl1ggkgj3dge7s9.apps.googleusercontent.com",
      appId             : "72198095137",
      scope             : "https://www.googleapis.com/auth/drive.readonly",
      oauthToken        : null,

      tokenClient: null,
      accessToken: null,
      pickerInited: false,
      gisInited: false,

    };
  },
  components: {
    //  AttachmentList: AttachmentList,
  },
  mounted() {
    this.getClientKeys();


    let gDrive = document.createElement("script");
    gDrive.setAttribute("type", "text/javascript");
    gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
    document.head.appendChild(gDrive);
  },
  methods: {

/*    async getClientKeys(){
      const response   = await ClienteConsola.get();
      let { app_id, client_id, developer_key }   = response.data.data;
      this.developerKey = developer_key;
      this.clientId = client_id;
      this.appId=app_id;

    },*/

    getClientKeys() {
      ClienteConsola.get()
          .then(response => {
            let { app_id, client_id, developer_key } = response.data.data;
            this.developerKey = developer_key;
            this.clientId = client_id;
            this.appId = app_id;
            this.gisLoaded();
            this.onApiLoad();
          })
          .catch(error => {
            console.error("Error fetching client keys:", error);
          });
    },

    onApiLoad() {
      console.log("onApiLoad");
/*      console.log(this.developerKey);*/
      // This is the callback for the Google API script
      // You can access the loaded API here
      // eslint-disable-next-line no-undef
      gapi.load('picker', this.onPickerApiLoad);
    },
    gisLoaded() {
      console.log("gisLoaded");
      console.log(this.developerKey);
      // This is the callback for the Google Sign-In Client script
      // You can access the loaded client here
      // TODO(developer): Replace with your client ID and required scopes.
      // eslint-disable-next-line no-undef
      this.tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: this.clientId,
        scope: this.scope,
        callback: '', // defined later
      });
      this.gisInited = true;
    },
    onPickerApiLoad() {
      this.pickerInited = true;
    },


    // function called on click of drive icon
     driveIconClicked() {
// eslint-disable-next-line no-undef
       this.tokenClient.callback = async (response) => {
         if (response.error !== undefined) {
           throw (response);
         }
         this.accessToken = response.access_token;
         this.createPicker();
       };

       if (this.accessToken === null) {
         // Prompt the user to select a Google Account and ask for consent to share their data
         // when establishing a new session.
       //  this.tokenClient.requestAccessToken({prompt: 'consent'});
       } else {
         // Skip display of account chooser and consent dialog for an existing session.
         this.tokenClient.requestAccessToken({prompt: ''});
       }
      // eslint-disable-next-line no-undef
/*      gapi.load("picker", () => {
        this.pickerApiLoaded = true;
        this.createPicker();
      });*/
    },

/*    handleAuthResult(authResult) {
      console.log("Handle Auth result");
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },*/

    // Create and render a Picker object for picking user Photos.
    createPicker() {
      console.log("Create and render a Picker object for picking user Photos.");
      if ( this.accessToken) {
        // eslint-disable-next-line no-undef
        var picker = new google.picker.PickerBuilder()
            .setLocale('es')
            // eslint-disable-next-line no-undef
            // .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            // eslint-disable-next-line no-undef
            .enableFeature(google.picker.Feature.MINE_ONLY)
            // eslint-disable-next-line no-undef
            .addView(new google.picker.DocsUploadView())

            // eslint-disable-next-line no-undef
            .addView(google.picker.ViewId.DOCS)
            .setOAuthToken(this.accessToken)
            .setDeveloperKey(this.developerKey) // developer key
            .setCallback(this.pickerCallback)
            .build();
        picker.setVisible(true);
      }
    },

    async pickerCallback(data = 0) {
      //console.log("PickerCallback Files : ", data);
      this.$emit('handlePickerFiles', data);

      this.$parent.$emit('handlePickerFiles', data);

      // eslint-disable-next-line no-prototype-builtins
      if(data.hasOwnProperty("docs")){
        this.fileName = data.docs[0].name;
      }

/*
      console.log("data del gdrive");
      console.log(data.docs[0].name);
      console.log(data.docs[0].id);
      console.log(data.docs[0].url);
*/


      // eslint-disable-next-line no-undef,no-unused-vars
      var url = "nothing";
      var name = "nothing";
      // eslint-disable-next-line no-undef
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        // eslint-disable-next-line no-undef
        var doc = data[google.picker.Response.DOCUMENTS][0];
        // eslint-disable-next-line no-undef
        url = doc[google.picker.Document.URL];
        name = doc.name;
        let docs = data.docs;
        // eslint-disable-next-line no-undef,no-unused-vars
        var param = { fileId: doc.id, oAuthToken: this.oauthToken, name: name };
        let attachments = [];
        for (let i = 0; i < docs.length; i++) {
          let attachment = {};
          attachment._id = docs[i].id;
          attachment.title = docs[i].name;
          attachment.name = docs[i].name + "." + docs[i].mimeType.split("/")[1];
          attachment.type = "gDrive";
          attachment.description = "Shared with GDrive";
          attachment.extension =
              "." +
              docs[i].mimeType.substring(docs[i].mimeType.lastIndexOf(".") + 1);
          attachment.iconURL = docs[i].iconUrl;
          attachment.size = docs[i].sizeBytes;
          attachment.user = JSON.parse(localStorage.getItem("user"));
          attachment.thumb = null;
          attachment.thumb_list = null;
          attachments.push(attachment);
        }
        this.tempAttachments = [...attachments];
      }
      this.oauthToken = null;
      this.pickerApiLoaded = false;


    }
  },
  computed: {
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.txt_attachmnts{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px !important;
  font-weight: 400;

}
</style>
