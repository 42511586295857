<template>
  <b-container>

    <b-modal   no-close-on-backdrop
               centered size="lg"
               hide-footer
               no-close-on-esc
               hide-header-close
               ref="ModalVideo"
               title="Video"
               v-model="ModalVideo"
               persistent>
      <b-card
          tag="article"
          class="mb-2">
        <b-alert show variant="info" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
         Añadir un video al objetivo, deberá de cargarlo desde su drive
        </b-alert>
        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>
                <b-form-input
                    class="my-2"
                    ref="form_video.nombre"
                    v-model="form_video.nombre"
                    type="text"
                    placeholder="Nombre del curso">
                </b-form-input>

                <b-form-input
                    ref="form_video.descripcion"
                    class="my-2"
                    v-model="form_video.clave"
                    type="text"
                    placeholder="Clave interna del curso"
                ></b-form-input>

                <GDriveSelector v-on:handlePickerFiles="getData" />

                  <b-form-file class="mb-1"
                               size="sm"
                               v-model="form_video.videoFile"
                               placeholder="Archivo adjunto..."
                               accept="image/*"
                               drop-placeholder="Arrastrar un archivo para adjuntar."
                  ></b-form-file>

                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalCrear()"
                    >
                      Cancelar
                    </b-button>

                      <b-button
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                              @click="save()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>


              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>
    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Videos de este objetivo"></b-jumbotron>
            </div>

            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible
            >
              En esta sección se pueden añadir videos para este objetivo, tanto si son autogestionados como para sesiones en vivo.
            </b-alert>

            <b-row style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-3">
              <b-button @click="volver()"
                        class="btn-studioAPP"
                        variant="outline-warning"> Volver
              </b-button>

              <b-button
                  variant="outline-warning"
                  :class="visible ? 'btn-studioAPP' : 'btn-studioAPP collapsed'"
                  :aria-expanded="visible ? 'true' : 'false'"
                  aria-controls="collapse-4"
                  @click="visible = !visible"
              >
                Nuevo video
              </b-button>

              <b-button variant="outline-warning" class="ml-2 btn-studioAPP" @click="loadVideoPlayer">Ver video actual</b-button>

            </b-row>




            <b-collapse id="collapse-4" v-model="visible" class="mt-2">
              <b-card
                  class="mx-2"
              >

                <b-card-text>
                  <b-row>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                      <b-form>
                        <b-form-input
                            class="my-2"
                            ref="form_video.nombre"
                            v-model="form_video.nombre"
                            type="text"
                            placeholder="Nombre del video">
                        </b-form-input>

                        <b-form-input
                            ref="form_video.descripcion"
                            class="my-2"
                            v-model="form_video.clave"
                            type="text"
                            placeholder="Clave interna"
                        ></b-form-input>

                        <div style="display: none; align-items: center;">
                          <b-button size="small" variant="outline-light">
                            <GDriveSelector v-on:handlePickerFiles="getData" />
                          </b-button>

                          <p class="mt-2" style="margin-left: 10px;"><small>Elegir archivo de tu equipo de computo</small></p>
                        </div>

                        <b-form-file
                                    class="mb-1"
                                     size="sm"
                                     v-model="form_video.videoFile"
                                     placeholder="Archivo adjunto..."
                                    accept=".mp4"
                                    drop-placeholder="Arrastrar un archivo para adjuntar."
                        ></b-form-file>

                        <b-row class="d-flex align-items-start justify-content-start my-1">
                          <div class="w-100">
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right"
                                @click="hideModalCrear()"
                            >
                              Cancelar
                            </b-button>

                            <b-button
                                variant="success"
                                size="sm"
                                class="float-right mx-2"
                                @click="save()"
                            >
                              Guardar
                            </b-button>
                          </div>
                        </b-row>


                      </b-form>
                    </div>
                    <div class="col-sm-1"></div>
                  </b-row>
                </b-card-text>

              </b-card>
            </b-collapse>

            <b-row>
              <b-col cols="12">

                <div :class="{ 'hide-video': !showCurrentVideo }" >

                  <div class="my-2" v-if="file.url">
                    <div style="text-align: center">
                      <h2>{{file.name}}</h2>
                      <video
                          ref="videoPlayer"
                          class="video-js vjs-default-skin"
                          preload="auto"
                          width="840"
                          height="560"
                          controls
                          @contextmenu.prevent
                      >
                        <source :src="file.url" type="video/mp4" />
                      </video>
                    </div>

                  </div>

                </div>

              </b-col>
            </b-row>


          </div>

        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import toast from "../../mixins/ToastMixin";
import GDriveSelector from "../../components/GDriveSelector";
import Bugsnag from "@bugsnag/js";
import ObjetivoVideosService from "../../services/ObjetivoVideosService";
import videojs from "video.js";
import 'video.js/dist/video-js.css';

export default {
  name: "ObjetivosVideos",
  mixins:[toast],
  components: {
    GDriveSelector
  },
  mounted() {
    this.getVideoObjetivo(this.objetivo.id);
  },
  data() {
    return {
      showCurrentVideo: false, // Flag to control rendering of Video.js player
      visible:false,

      ModalBorrar:false,
      ModalVideo:false,

      videos_headers: [
        {   label: 'Nombre',        key: 'nombre', class: 'text-start'},

        {  label: 'Acciones',       key: 'actions', class: 'text-center' }
      ],
      videos_items: [],
      objetivo:{
        id:this.$route.params.id
      },
      form_video: {
        nombre: '',
        clave: '',
        descripcion: '',
        id                          :0,
        editar                      :false,
        videoFile                      :null
      },

      player: null,
      file:{
        name:null,
        url:null,
        id:0,
        type:null,
        iconUrl:''
      }

    }
  },
  methods: {
    volver(){
      this.$router.push({
        path:`/dashboardObjetivos`
      });
    },
    //método del google picker que toma la info del archivo
    getData( data ) {
      // eslint-disable-next-line no-prototype-builtins
      if(data.hasOwnProperty("docs")){
        this.file.id        = data.docs[0].id;
        this.file.name      = data.docs[0].name;
        this.file.url       = data.docs[0].url;
        this.file.type      = data.docs[0].type;
        this.file.iconUrl   = data.docs[0].iconUrl;
      }

      console.log("data");
      console.log(data);

    },
    async getVideoObjetivo(objetivo_id) {

      try{
      let response = await ObjetivoVideosService.get(objetivo_id);
      let {   message,
              resultSet,
              color,
              success
      } = response.data;

      if(success){
        this.file.url = resultSet.URL;
        this.file.name  = resultSet.file_name;
        this.file.clave = resultSet.clave;
      }

      this.toast('b-toaster-bottom-center', true, message, color);
    } catch (error) {
      this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏 ', "danger" );
      Bugsnag.notify(
          new Error('Método: getVideoObjetivo() ' + error.message)
      );
    }

    },

    async save(){
      this.toast('b-toaster-bottom-center', true, "Se está subiendo el video...", "info" );
      let formData = new FormData();

      formData.append("nombre",           this.form_video.nombre);
      formData.append("clave",            this.form_video.clave);
      formData.append("objetivo_id",      this.objetivo.id);
      formData.append("videoFile",        this.form_video.videoFile);

      formData.append("file_id",          this.file.id );
      formData.append("file_name",        this.file.name);
      formData.append("file_url",         this.file.url );
      formData.append("file_type",        this.file.type);
      formData.append("file_iconUrl",     this.file.iconUrl);

   /*   for (let entry of formData.entries()) {
        console.log(entry[0] + ': ' + entry[1]);
      }*/

      try {
        const response = await ObjetivoVideosService.save(formData);
        let {   success,
                message,
                color
        } = response.data;

        if(success){
          this.form_video.videoFile   ="";
          this.form_video.nombre      = "";
          this.form_video.clave      = "";
          this.file.name      = null;
          this.file.url       = null;
          this.file.id        = 0;
          this.file.type      = null;
          this.file.iconUrl   = null;

          this.toast('b-toaster-bottom-center', true, message, color );
        }
        await this.getVideoObjetivo(this.objetivo.id);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador: '+error.message, "danger" );
        Bugsnag.notify(
            new Error('Método: save() ' + error)
        );
      }

    },
    delete(){

    },

    loadVideoPlayer() {
      this.player = videojs(this.$refs.videoPlayer);
      this.showCurrentVideo = true;
    },


  },

}
</script>

<style lang="scss" scoped>

$studioAPPColor:#FF8250;

.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

.hide-video {
  display: none;
}

</style>