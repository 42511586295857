<template>
    <b-container fluid="lg">

<!-- modal borrar objetivo de la unidad -->
    <b-modal
      v-model="ModalQuitarCursoCurricula"
      persistent
      title=" Confirmación"
      size="lg"
      centered
      >
  <b-container fluid>
      <b-row class="m-1 text-left">
              <b-col cols="12">
                  ¿ Estás seguro de quitar el curso <b>"{{ nombreCurso }}"</b> de la currícula <b> {{ current_curricula.nombre_curricula }}</b> ?
              </b-col>
          </b-row>
      </b-container>
          <template #modal-footer>
          <div class="w-100">
                  <b-button
                      variant="primary"
                      size="sm"
                      class="float-right"
                      @click="cerrarModalQuitarCursoCurricula()">
                      Cancelar
                  </b-button>
                  <b-button
                      variant="danger"
                      size="sm"
                      class="float-right mx-2"
                      @click="borrarCursoCurricula()"
                  >
                  Eliminar
                  </b-button>
          </div>
          </template>

      </b-modal>

<!-- modal crear nuevo elemento -->
   <b-modal   no-close-on-backdrop centered size="lg"
              hide-footer
              ref="ModalAsignarCursos"
              title="Asignar curso(s) a esta currícula"
              v-model="ModalAsignarCursos"
              persistent
      >
          <b-card
                  tag="article"
                  class="mb-2">
              <b-alert show variant="primary" class="p-3 text-justify">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                  </svg>
                   Seleccione el o los cursos que quiere enlazar a esta currícula. Los cursos que no vea en el listado.
              </b-alert>
              <b-card-text>
               <b-row>
                <div class="col-sm-1"></div>
                  <div class="col-sm-10">
                    <b-form>

                      <v-select
                          class="my-3"
                          placeholder="Elegir .... "
                          v-model="cursos"
                          multiple
                          :options="cursos_disponibles">
                      </v-select>

                      <b-row class="d-flex align-items-start justify-content-start my-1">
                              <div class="w-100">
                              <b-button
                                      variant="primary"
                                      size="sm"
                                      class="float-right"
                                      @click="cerrarModalAsignarCursos()"
                                  >
                                      Cancelar
                              </b-button>
                                 <b-button
                                  variant="success"
                                  size="sm"
                                  class="float-right mx-2"
                                  @click="asignarCursoCurricula()"
                              >
                              Guardar
                              </b-button>
                          </div>
                      </b-row>


                    </b-form>
                 </div>
                 <div class="col-sm-1"></div>
               </b-row>
              </b-card-text>

              </b-card>
   </b-modal>

                <b-alert style="background-color: #14C6B9; border:0" show variant="success" class="p-3 text-justify alerta-studioAPP mb-2"  dismissible>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="25" fill="currentColor" class="bi bi-info-circle-fill mr-2 " viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                   A continuación se enlistan todos los cursos de la currícula  <strong class="ml-2">{{ (current_curricula.nombre_curricula !=null ) ? current_curricula.nombre_curricula : ''  }}</strong>
                </b-alert>

                    <b-row  style="display: flex; justify-content: space-between; align-items:center;" class="my-3 mx-1">
                                <b-button @click="volver()"
                                    class="btn-studioAPP"
                                    variant="outline-warning"> Volver
                                </b-button>

                                <b-button v-if="$store.getters.usuario.role_id==1" @click="showModalAsignarCursos()"
                                        class="btn-studioAPP ml-2"
                                        variant="outline-warning"> Vincular curso
                                </b-button>
                    </b-row>

      <div v-if="isLoading==true">
        <b-alert
            class=" mx-4 p-4"
            show
            variant="primary"
            dismissible
        >
          <div>Unos segundos, estamos cargando la información</div>
        </b-alert>

      </div>

      <div v-if="isLoading==false">

        <b-form-input
            size="lg"
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Buscar"
        ></b-form-input>

        <b-row>
          <b-col v-for=" (curso,index) in (filteredCursos)" cols="12" lg="4" md="6" sm="12" :key="index">
            <b-card
                :title="`${curso.nombre_curso}`"
                :img-src=" curso.imagen "
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 30rem;"
                class="my-2"
            >

              <div style="visibility: hidden; display: none"  v-if="$store.getters.usuario.role_id==1" >
                {{curso.id}}
              </div>

              <div class="d-flex justify-content-around align-items-center my-2 p-2">

                <div style="text-align: center;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                    <path  fill="green" fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    <path  fill="green" d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
                  </svg>
                  <br>
                  <span >
                    <small>Progreso {{ Math.ceil(curso.porcentajeAvance) }}%</small>
                  </span>
                </div>


                <div style="text-align: center;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
                    <path  fill="blue" fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                  </svg>
                  <br>
                  <small> Unidades {{curso.unidadesCantidad}}</small>
                </div>


                <div style="text-align: center;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                    <path  fill="orange"  d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                    <path  fill="orange"  d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                  </svg>
                  <br>
                  <small> {{calculateTime(curso.minutos_curso)}}</small>
                </div>

              </div>


              <p v-if="curso.terminado == 1" class="completado">
                ️ Completado ✔️
              </p>

              <div v-if="$store.getters.usuario.role_id==3">
                <div v-if="curso.puede_cursar == 0" class="advertencia">
                  ️ No se han completado los cursos previos 🔒

                  <div class="text-left jpanel my-2">
                    <span class="recetario">Requisitos:</span>
                  <div v-for="(item, index) in curso.cursos_faltantes.split(' - ')" :key="index">
                      <span class="recetario">-  {{ item }} </span>
                    </div>


                  </div>
                </div>
              </div>

              <div class="text-align:right" v-if="curso.evento!=0">
                <small>
                  <b>Fecha de la transmision:</b>
                  {{convertir_fecha(curso.curso_evento_fecha_consumo_inicio)}} de las {{curso.curso_evento_hora_inicio}} a las {{curso.curso_evento_hora_fin}} (Hora del centro de México)

                </small>
                <br>
                <small>
                  <b>Disponibilidad:</b>
                  del {{convertir_fecha(curso.curso_evento_fecha_seleccion_inicio)}} al {{convertir_fecha(curso.curso_evento_fecha_seleccion_termino)}}.
                </small>
              </div>

              <div class="text-align:right"  v-if="$store.getters.usuario.role_id==1" >
                <b-button class="text-right" variant="link" @click="showModalQuitarCursoCurricula(curso)"> <small> Quitarlo de la currícula </small></b-button>
              </div>
              <div style="height: 100px;overflow-y:scroll">
                <p class="text-justify p-2" v-text="curso.descripcion"></p>
              </div>
<!--              <small> <span v-html="calculateTime(curso.minutos_curso)"></span></small>-->
              <div class="my-3">

                <div v-if="curso.expired==1">
                  <b-button variant="outline-danger" size="" disabled class="ml-1">
                    ️ Curso con fecha fuera de rango ⚠️
                  </b-button>
                </div>
                <div v-else>
                  <div v-if="curso.cursoEnVivo==0">

                    <b-button
                        v-b-tooltip.hover
                        :title="curso.cursos_faltantes ? `Requisito: ${curso.cursos_faltantes}` : 'Clic para entrar'"
                        @click="contenidoCursoCurricula(curso)"
                        class="btn-studioAPP"
                        variant="outline-warning"
                        height="50"
                        :disabled=" (  $store.getters.usuario.role_id==3 && curso.puede_cursar == 0) "
                        block color="primary">

                      <div v-if="$store.getters.usuario.role_id!=3">
                        <b> Ver contenido </b>
                      </div>
                      <div v-else-if="$store.getters.usuario.role_id==3">
                        <div v-if="curso.puede_cursar == 0" >
                          Sin acceso
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                          </svg>
                        </div>
                        <div v-else-if="curso.puede_cursar == 1" >
                          <b> Ver contenido
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-unlock-fill" viewBox="0 0 16 16">
                              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/>
                            </svg>
                          </b>
                        </div>
                        <div v-else-if="curso.puede_cursar == 2" >
                          <b> Ver contenido

                          </b>
                        </div>
                      </div>



                    </b-button>
                  </div>

                  <div v-if="curso.cursoEnVivo==1">

                    <div v-if="curso.curso_evento_vigencia==1">

                      <b-button   @click="contenidoCursoCurriculaEnVivo(curso)"
                                  class="btn-studioAPP"
                                  variant="outline-warning"
                                  height="50"
                                  block color="primary"><b>
                        En vivo
                        <span class="mb-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-camera-reels-fill" viewBox="0 0 16 16">
                                <path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                <path d="M9 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                <path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h7z"/>
                              </svg>
                            </span>
                      </b>
                      </b-button>

                    </div>
                    <div v-else-if=" curso.curso_evento_vigencia==0 ">

                      <b-button style="display: none"   @click="contenidoCursoCurriculaEnVivo(curso)"
                                class="btn-studioAPP"
                                variant="outline-warning"
                                height="50"
                                block color="primary">
                        ir
                      </b-button>

                      <b-button  disabled
                                 class="btn-studioAPP"
                                 variant="outline-warning"
                                 height="50"
                                 block color="primary"><b>
                        <small> <i> <span class="my-2">Este evento ya expiradó</span></i></small>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                          <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                          <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                      </b>
                      </b-button>
                    </div>


                  </div>
                </div>



              </div>
            </b-card>
          </b-col>
        </b-row>

      </div>



    </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import moment from 'moment';
//moment.locale('es');
import 'moment/locale/es';
export default {
  mixins:[toast],
  data() {
        return {
          filter: '',
        type:0,
        isLoading:true,
        ModalQuitarCursoCurricula:false,
        cursos:[],
        oferta_actual: [],
        nombreCurso:'',
        cursos_disponibles: [
             { label: 'One',      id: 1 },
             { label: 'Two',      id: 2 },
          ],
        ModalAsignarCursos:false,

          //  current_curricula: null,
            cursos_current_curricula: [],
            current_curricula: {
                nombre_curricula: '',
            },
        }
    },
  computed: {
    filteredCursos() {
      if (this.filter === '') {
        return this.cursos_current_curricula; // Return all cursos if filter is empty
      } else {
        const filterText = this.filter.toLowerCase();
        return this.cursos_current_curricula.filter(curso => {
          return curso.nombre_curso.toLowerCase().includes(filterText);
        });
      }
    }
  },
  mounted () {
      this.current_curricula = this.$route.params.curricula;

        if( this.$route.params.curricula == undefined ) {

          if (localStorage.getItem('current_curricula')) {
            try {
              this.current_curricula = JSON.parse( localStorage.getItem('current_curricula') );
            } catch(e) {
              localStorage.removeItem('current_curricula');
            }
          }

        }else{
          const parsed = JSON.stringify(this.current_curricula);
          localStorage.setItem('current_curricula', parsed);
        }


    /*  if( this.$route.params.curricula == undefined ) {

      }else{
        const parsed = JSON.stringify(this.current_cursos_curricula);
        localStorage.setItem('current_cursos_curricula', parsed);
      }*/




         this.getCursosCurricula(this.current_curricula.id, this.current_curricula.tipo);


/*      console.log('this.current_curricula.id');
         console.log(this.current_curricula.id);*/

         if ( this.$route.params.oferta_actual ){
                this.oferta_actual = this.$route.params.oferta_actual
            }
    },
  methods: {
          convertir_fecha(fechahora) {
            moment.locale();
            //return moment(fechahora, "X").format("LLL")
            return moment(fechahora).format("LL")
          },
            volver(){
                this.$router.push({
                    path:`/crear-contenido-curricula`
                });
            },
            contenidoCursoCurriculaEnVivo(curso){
              this.$router.push({
                name: 'ContenidoCursoCurriculaVivo',
                params          : {
                  curso           : curso,
                  curricula       : this.current_curricula,
                  oferta_actual   : this.oferta_actual
                }
              });
            },

            contenidoCursoCurricula(curso){

                this.$router.push({
                    name: 'ContenidoCursoCurricula',
                    params          : {
                    curso           : curso,
                    curricula       : this.current_curricula,
                    oferta_actual   : this.oferta_actual
                    }
                });
            },
    calculateTime(minutes = 0) {
      let hours = Math.floor(minutes / 60);
      let minutes_diff = minutes % 60;
      let legend_text = '';

      if (hours > 0) {
        legend_text += `${hours} Hrs ${minutes_diff} Min`;
      } else {
        legend_text += `${minutes_diff} Min`;
      }

      return legend_text;
    },
        cerrarModalQuitarCursoCurricula(){
            this.ModalQuitarCursoCurricula = false;
        },
        showModalQuitarCursoCurricula(curso){
            this.nombreCurso    = curso.nombre_curso;
            this.id_curso       = curso.id;
            this.ModalQuitarCursoCurricula = true;
        },
        async borrarCursoCurricula(){
                    let url          = `/api/curriculas/quitarCursoCurricula/${this.current_curricula.id}/${this.id_curso}`;
                    const response   = await this.$api.get(url);
                    let {   success,
                            message,
                            color
                            } = response.data;
                    if(success){
                        await this.getCursosCurricula(this.current_curricula.id, this.current_curricula.tipo);
                        this.ModalQuitarCursoCurricula = false;
                        this.resetForm();
                    }
                    this.toast('b-toaster-bottom-center', true, message, color );
        },
        cerrarModalAsignarCursos(){
            this.ModalAsignarCursos=false;
            this.resetForm();
        },
        showModalAsignarCursos(){
            this.ModalAsignarCursos = true;
            this.getCursosDisponibles();
        },
        async asignarCursoCurricula(){
            let arrCursos = [];
              this.cursos.forEach(el => {
                         arrCursos.push(el.id);
                    });
            let formData = new FormData();
                formData.append("curricula_id"  , this.current_curricula.id);
                formData.append("cursos"        , arrCursos);

                    let url          = `/api/curriculas/asignarCursos/store`;
                    const response   = await this.$api.post(url, formData, { headers: {'content-type': 'multipart/form-data'} } );
                    let {   success,
                            message,
                            color
                            } = response.data;
                    if(success){
                      await this.getCursosCurricula(this.current_curricula.id, this.current_curricula.tipo);

                        this.ModalAsignarCursos = false;
                        this.resetForm();
                    }
                    this.toast('b-toaster-bottom-center', true, message, color );
        },
        async getCursosDisponibles() {
            //info del v-select
            const response        = await this.$api.get(`/api/cursos/select/${this.current_curricula.id}`);
           this.cursos_disponibles = response.data.cursos;

        },

      even(arr) {
            //enVivo
        if(this.type==2){
          return arr.slice().sort(function(a, b) {
            return b.position - a.position;
          });
        }else{
          //autogestivo y mixto
          return arr.slice().sort(function(a, b) {
            return a.position - b.position;
          });
        }

      },
       async getCursosCurricula( item, tipo ) {
              this.isLoading = true;
                const response = await this.$api.get(`/api/contenidos/curriculas/cursos/${item}/tipo/${tipo}`);
                let {   message,
                        cursos_curricula,
                        color,
                        success,
                        type,
                        } = response.data;
                if(success){
                    this.type = type;
                    this.cursos_current_curricula = cursos_curricula;
/*                  console.log( 'cursos_curricula');
                    console.log( cursos_curricula);*/
                    this.toast('b-toaster-bottom-center', true, message, color );
                    this.isLoading = false;
                }

        },
        resetForm(){
            this.cursos = [];
        }
    },
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#FF8250;


.card-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto Light";
}

.txt_subTitle{
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto Light";
}
.card-text-wrapper {
  max-height: 160px; /* Set your desired maximum height here */
  overflow-y: auto;
}

.card-text-wrapper p {
  margin: 0; /* Remove default margin for <p> element inside <b-card-text> */
  overflow: hidden;
  text-overflow: ellipsis; /* Use ellipsis to indicate text truncation */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}






.btn-studioAPP{
     color: $studioAPPColor !important;
     border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
    background-color: $studioAPPColor !important;
    color: white !important;
}

.alerta-studioAPP{
    background-color: #952f81;
    color   : white;
    border: 1px solid #952f81;
}

.jpanel {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f7f7f7;
  box-shadow: 2px 2px 5px #ccc;
}

.completado {
  color: green;
  font-size: 1.2rem;
  text-align: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 0.5rem;
}

.recetario {
  font-family: Arial, sans-serif;
  font-size: 11px;
  line-height: 1.5;
  color: slategray;
  font-style: italic;
  margin: 0;
  padding: 0;
}

.advertencia{
  color: red;
  font-size: 1.2rem;
  text-align: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 0.5rem;
}
</style>