<template>
  <b-container>

    <!-- modal subir cv -->
    <b-modal no-close-on-backdrop
             centered size="lg"
             hide-footer
             no-close-on-esc
             hide-header-close
             ref="ModalUploadcsv"
             title="Cargar alumnos"
             v-model="ModalUploadcsv"
             persistent>
      <b-card
          tag="article"
          class="mb-2">

        <b-alert show variant="info" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          En esta sección, se pueden subir archivos csv con la información de los alumnos a subir a la plataforma
          Aprende. Nombre, correo
        </b-alert>

        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>
                <div class="mb-1 my-2">

                  <label>Grupos en los que se vaciarán los alumnos del archivo CSV (pueden ser varios)</label>
                  <v-select
                      class="my-3"
                      placeholder="Grupos que visualizarán la oferta "
                      v-model="grupo_usuario_aprende_id"
                      multiple
                      :options="gruposAprende_items">
                  </v-select>

                </div>
                <div class="mb-1 my-2">
                  <label>Archivo .CSV que contiene la estructura --> nombre, correo, correo alternativo
                    (opcional)</label>
                  <b-form-file
                      size="sm"
                      id="file-default"
                      placeholder="Archivo adjunto..."
                      accept=".csv"
                      drop-placeholder="Arrastrar un archivo para adjuntar."

                  ></b-form-file>

                </div>

                <div class="mb-1 my-2">
                  <label for="form_user.ultima_fecha_inicio_sesion"> <small>Creación de cuentas de correo en consola
                    Aprende</small></label>
                  <b-form-checkbox
                      class="mt-3"
                      v-model="creacionCuentas"
                      value="1">
                    {{ (creacionCuentas == 1 ? 'Desactivar' : 'Activar') }}
                  </b-form-checkbox>
                </div>


                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hideModalUploadcsv()"
                    >
                      Cancelar
                    </b-button>

                    <b-button v-if="form_user.editar===false"
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>


              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>


    </b-modal>

    <!-- modal editar -->
    <b-modal no-close-on-backdrop
             centered size="lg"
             hide-footer
             no-close-on-esc
             hide-header-close
             ref="ModalUser"
             title="Usuario"
             v-model="ModalUser"
             persistent>
      <b-card
          tag="article"
          class="mb-2">

        <b-alert show variant="info" class="p-3 text-justify">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
               class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          En esta sección, se pueden actualizar ciertos datos del usuario, algunos de estos datos, se actualizan
          automáticamente al momento de que el usuario accede con sus credenciales de google, por ejemplo el avatar.
        </b-alert>

        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>
                <div class="mb-1 my-2">
                  <label for="form_user.name"> <small>Nombre</small></label>
                  <b-form-input
                      class="my-2"
                      ref="form_user.name"
                      v-model="form_user.name"
                      type="text"
                      placeholder="Nombre">
                  </b-form-input>
                </div>

                <label for="" class="mb-2 mt-2"> <small> Roles disponibles</small></label>
                <b-form-select
                    placeholder="Elegir "
                    :selected="form_user.role_id"
                    v-model="form_user.role_id"
                    :options="item_roles"
                >
                  <template #first>
                    <b-form-select-option disabled value="0"> Elegir opción</b-form-select-option>
                  </template>
                </b-form-select>

                <div class="mb-2 mt-2">
                  <label for="form_user.email"> <small>Correo principal</small></label>
                  <b-form-input
                      ref="form_user.email"
                      class="my-2"
                      v-model="form_user.email"
                      type="text"
                      placeholder="Correo principal"
                  ></b-form-input>
                </div>
                <div class="mb-2 mt-2">
                  <label for="form_user.correo_alternativo"> <small>Correo Alternativo</small></label>
                  <b-form-input
                      ref="form_user.correo_alternativo"
                      class="my-2"
                      v-model="form_user.correo_alternativo"
                      type="text"
                      placeholder="Correo alternativo (opcional)"
                  ></b-form-input>
                </div>

                <div class="mb-2 mt-2">
                  <label><small>Grupos en los que está inscrito el usuario</small></label>
                  <v-select
                      :disabled='(  form_user.role_id!=3  )'
                      class="my-3"
                      :placeholder=" (  form_user.role_id==3 ? 'Listado completo de grupos en la plataforma Aprende' : 'Sólo los usuarios con ROL -ESTUDIANTE- pueden estar en grupos' ) "
                      v-model="form_user.grupos"
                      multiple
                      :options="gruposAprende_items">
                  </v-select>

                </div>

                <!--                <div class="mb-2 mt-2">
                                  <label><small>Currículas inscritas</small></label>
                                  <b-list-group>

                                    <b-list-group-item v-for="(item, index) in form_user.curriculas" :key="index">
                                      {{item}}
                                    </b-list-group-item>

                                  </b-list-group>

                                </div>-->

                <b-row>
                  <b-col>
                    <label for="form_user.fecha_creacion"> <small>Fecha de creación</small></label>
                    <b-form-input
                        ref="form_user.fecha_creacion"
                        class="my-2"
                        v-model="form_user.fecha_creacion"
                        type="text"
                        disabled
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <label for="form_user.ultima_fecha_inicio_sesion"> <small>Ultimo inicio de sesión a la
                      plataforma</small></label>
                    <b-form-input
                        ref="form_user.ultima_fecha_inicio_sesion"
                        class="my-2"
                        v-model="form_user.ultima_fecha_inicio_sesion"
                        type="text"
                        disabled
                    ></b-form-input>

                  </b-col>

                </b-row>
                <b-row class="my-2">
                  <b-col>
                    <label for="form_user.fecha_creacion"> <small>Fecha de actualización</small></label>
                    <b-form-input
                        ref="form_user.fecha_creacion"
                        class="my-2"
                        v-model="form_user.fecha_actualizacion"
                        type="text"
                        disabled
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <label for="form_user.ultima_fecha_inicio_sesion"> <small>¿Usuario activo?</small></label>
                    <b-form-checkbox
                        class="mt-3"
                        v-model="form_user.estatus_user"
                        value="1">
                      {{ (form_user.estatus_user == 1 ? 'Desactivar' : 'Activar') }}
                    </b-form-checkbox>
                  </b-col>

                </b-row>


                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hiddenModalUser()"
                    >
                      Cancelar
                    </b-button>

                    <b-button v-if="form_user.editar===true"
                              variant="info"
                              size="sm"
                              class="float-right mx-2"
                              @click="update"
                    >
                      Actualizar registro
                    </b-button>
                    <b-button v-if="form_user.editar===false"
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                              @click="save()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>


              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>


    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Configuración"></b-jumbotron>
            </div>

            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                   class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              Aquí podrá GESTIONAR los roles de las cuentas de usuario en Aprende.
            </b-alert>
            <b-row class="d-flex align-items-end justify-content-end my-3">


              <div class="col-sm-12 text-right">
                <b-button
                    style="visibility: hidden"
                    class="btn-studioAPP"
                    variant="outline-warning"
                    @click="showModalUploadcsv"
                > Cargar

                </b-button>

              </div>
            </b-row>


            <div class="mx-5 ">
              <b-row>
                <b-col cols="12">
                  <b-alert v-model="visibleModal" variant="light" show>
                    <b-button variant="primary" disabled>
                      <b-spinner small type="grow"></b-spinner>

                    </b-button>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row class="d-flex align-items-end justify-content-end my-1">
                <div class="col-sm-8 my-2">
                  <b-form-input v-model="search" debounce="500" placeholder="Ingresar correo electrónico a buscar"
                                id="filter-input"></b-form-input>
                </div>
                <div class="col-sm-4 my-2">
                  <b-button
                      class="ml-4 btn-studioAPP"
                      variant="outline-warning"
                      @click="searchEmail()"
                      title="Clic para buscar correo electrónico"
                  > Buscar
                  </b-button>
                </div>

                <b-table class="table data-table"
                         striped="striped"
                         hover="hover"
                         outlined="outlined"
                         :items="usuario_items"
                         :fields="headers"
                         :current-page="currentPage"
                         :per-page="perPage"
                         :filter="filter"
                         :filter-included-fields="filterOn"
                         stacked="md"
                         show-empty
                         small
                >
                  <template #empty="scope">
                    <h5 class="text-center">
                      {{ scope.emptyText = 'Por el momento no hay registros que mostrar.' }}
                    </h5>
                  </template>
                  <template #emptyfiltered="scope">
                    <h5 class="text-center">
                      {{ scope.emptyFilteredText = 'No hay registros que concuerden con los parámetros de búsqueda.' }}
                    </h5>
                  </template>
                  <template #cell(estatus_user)="row">
                    <div v-if="`${row.item.estatus_user}`!=='0' ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                    </div>
                    <div v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </div>

                  </template>
                  <template #cell(actions)="row">
                    <div class="d-flex justify-content-around align-items-center">

                      <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                                @click="EditMode(row.item)" class="mr-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path
                              d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </b-button>


                    </div>
                  </template>

                </b-table>
                <div class="d-flex justify-content-between row p-2">
                  <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                  <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                    <div>


                      <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                          align="fill"
                          size="sm"
                          class="my-0"
                      >
                      </b-pagination>
                    </div>
                  </div>
                </div>
              </b-row>

            </div>


          </div>

        </div>

      </b-col>

    </b-row>

  </b-container>


</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
import GestionUsuariosService from '@/services/GestionUsuariosService';

export default {

  name: "Index",
  mixins: [toast],

  mounted() {
    /*    this.getGruposAprende();
        this.getRoles();
        this.getData();*/
  },
  data() {
    return {
      Formulario:{
        mensaje: "mensaje sin definir",
        visibleModal:false
      },
      creacionCuentas: 1,
      grupo_usuario_aprende_id: [],
      gruposAprende_items: [],
      ModalUser: false,
      ModalUploadcsv: false,
      usuario_items: [],
      headers: [
        {label: 'Nombre', key: 'name'},
        {label: 'email', key: 'email'},
        {label: 'Correo alternativo', key: 'correo_alternativo'},
        {label: 'Rol', key: 'role', class: 'text-center'},
        //{label: 'Fecha creación', key: 'fecha', class: 'text-center'},
        {label: 'Estatus', key: 'estatus_user', class: 'text-center'},
        {label: 'Acciones', key: 'actions', class: 'text-center'}
      ],
      item_roles: [
        {value: '0', text: 'Elegir rol', disabled: true},
        {value: '1', text: 'Admin'},
        {value: '2', text: 'Docente'},
        {value: '3', text: 'Estudiante'},
      ],
      form_user: {
        name: '',
        email: '',
        correo_alternativo: '',
        role_id: 0,
        grupos: [],
        estatus_user: 0,
        id: 0,
        fecha_creacion: '',
        fecha_actualizacion: '',
        ultima_fecha_inicio_sesion: '',
        editar: false,

        curriculas: [],
      },

      ///
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, {value: 100, text: "Ver muchos"}],
      filter: null,
      filterOn: [],
      search: null,

      searchQuery: '',
    }
  },

  methods: {
    async searchEmail() {
      if (this.search === null || this.search.length === 0) {
        alert("Favor de ingresar un correo electrónico.")
        return false;
      }
      let response
      try {
         response = await GestionUsuariosService.getEmailUser(this.search);
        let {
          success,
          message,
          color,
          cantidad
        } = response.data;
        if (success) {
          this.toast('b-toaster-bottom-center', true, message, color);
          this.usuario_items = response.data.resultSet;
          this.totalRows = cantidad;
        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");

      }

            console.log("searchEmail")
            console.log(response.data.resultSet)

    },

    showModalUser() {
      this.ModalUser = true;
    },
    async getGruposAprende() {
      const response = await this.$api.get("/api/users/GruposUsuario");
      let {gruposUsuarios} = response.data;
      this.gruposAprende_items = gruposUsuarios;
    },

    hideModalUploadcsv() {
      this.ModalUploadcsv = false;
    },
    showModalUploadcsv() {
      this.ModalUploadcsv = true;
    },

    async save() {
      try {

        //sólo role estudiante 1 pueden estar en grupos
        /*     if(this.form_user.role_id == 1 || this.form_user.role_id == 2){
               this.form_user.grupos = [];
             }*/

        let formData = new FormData();

        if (this.form_user.name) {
          formData.append("name", this.form_user.name);
        }

        if (this.form_user.role_id) {
          formData.append("role_id", this.form_user.role_id);
        }

        if (this.form_user.email) {
          formData.append("email", this.form_user.email);
        }

        formData.append("correo_alternativo", this.form_user.correo_alternativo);

        if (this.form_user.estatus_user === false || this.form_user.estatus_user == 0) {
          formData.append("estatus_user", 0);
        } else {
          formData.append("estatus_user", 1);
        }
        let arrGrupos = [];
        if (this.form_user.grupos.length > 0) {
          this.form_user.grupos.forEach(el => {
            arrGrupos.push(el.id);
          });
        }
        formData.append("grupo_usuario_aprende_id", arrGrupos);

        const response = await GestionUsuariosService.save(formData);
        let {
          success,
          message,
          color
        } = response.data;
        /*      console.log('response.data');
              console.log(response.data);*/
        if (success) {
          await this.getData();
          this.hiddenModalUser();
        }

        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: update() ' + error.message)
        );
      }
    },


    async update() {
      try {
        //sólo role estudiante 1 pueden estar en grupos
        /*        if(this.form_user.role_id == 1 || this.form_user.role_id == 2){
                  this.form_user.grupos = [];
                }*/
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("id", this.form_user.id);
        formData.append("name", this.form_user.name);
        formData.append("role_id", this.form_user.role_id);
        formData.append("email", this.form_user.email);
        formData.append("correo_alternativo", this.form_user.correo_alternativo);
        if (this.form_user.estatus_user === false || this.form_user.estatus_user == 0) {
          formData.append("estatus_user", 0);
        } else {
          formData.append("estatus_user", 1);
        }
        let arrGrupos = [];
        this.form_user.grupos.forEach(el => {
          arrGrupos.push(el.id);
        });
        formData.append("grupo_usuario_aprende_id", arrGrupos);

        const response = await GestionUsuariosService.update(this.form_user.id, formData);
        let {
          success,
          message,
          color
        } = response.data;

        if (success) {
          await this.getData();
          this.ModalUser = false;
          this.resetForm();
        }
        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: update() ' + error.message)
        );
      }

    },
    async getData() {

      try {
        let response = await GestionUsuariosService.getData(this.search);
        let {resultSet, cantidad, success} = response.data;

        if (success) {
          if (this.search) {
            this.usuario_items = resultSet.data;
          } else {
            this.usuario_items = resultSet;
          }
          this.totalRows = cantidad;
        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: showCursos() ' + error.message)
        );
      }

    },
    async getRoles() {
      try {
        let response = await GestionUsuariosService.getRoles();
        this.item_roles = response.data.resultSet;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: getRoles() ' + error.message)
        );
      }
    },
    resetForm() {
      this.form_user.name = '';
      this.form_user.email = '';
      this.form_user.correo_alternativo = '';
      this.form_user.estatus_user = 0;
      this.form_user.role_id = 0;
      this.form_user.grupos = [];
      this.form_user.curriculas = [];
      this.form_user.ultima_fecha_inicio_sesion = '';
      this.form_user.fecha_creacion = '';
      this.form_user.id = 0;
      this.form_user.editar = false;
    },
    hiddenModalUser() {
      this.ModalUser = false;
      this.resetForm();
    },
    EditMode(item) {


      this.form_user.id = item.id;
      this.form_user.name = item.name;
      this.form_user.email = item.email;
      this.form_user.correo_alternativo = item.correo_alternativo;
      this.form_user.estatus_user = item.estatus_user;
      this.form_user.role_id = item.role_id;
      this.form_user.ultima_fecha_inicio_sesion = item.ultima_fecha_inicio_sesion;
      this.form_user.fecha_creacion = item.fecha;
      this.form_user.fecha_actualizacion = item.updated_at;

      /*       console.log(item.grupos_usuarios[0].grupos.grupo_oferta[0].oferta_curricula[0].curricula.nombre_curricula);
             console.log(item.grupos_usuarios[1].grupos.grupo_oferta[0].oferta_curricula[0].curricula.nombre_curricula);
             for(let i = 0; i < item.grupos_usuarios.length; i++){
                 this.form_user.curriculas.push(item.grupos_usuarios[i].grupos.grupo_oferta[0].oferta_curricula[0].curricula.nombre_curricula);
             }*/

      for (let i = 0; i < item.grupos_usuarios.length; i++) {

        const grupo = item.grupos_usuarios[i].grupos;
        const nombreGrupo = grupo.nombre_grupo;
        const grupoId = grupo.id;
        this.form_user.grupos.push({id: grupoId, label: nombreGrupo});
      }

      this.form_user.editar = true;
      this.showModalUser()
    },


  },
}//export default
</script>

<style lang="scss" scoped>

.bi-check-circle-fill {
  fill: green;
}

.bi-x-circle-fill {
  fill: red;
}

$studioAPPColor: #952f81;

.btn-studioAPP {
  color: $studioAPPColor !important;
  border: 1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: $studioAPPColor !important;
  color: white !important;
}

</style>