import api from '@/services/api';

export default{
    getToken(){
        let url          = `/api/session/token`;
        let response   = api({
            url: url,
            method: 'GET',
            withCredentials: true,
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }

        });
        return response;
    }
}







