<template>
  <b-container fluid="lg">
    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Reportes 📊"></b-jumbotron>
            </div>

            <div class="my-2 w-25 d-flex justify-content-between">
              <v-select
                  style="width: 300px"
                  size="lg"
                  class="my-3"
                  placeholder="Elegir grupo .... "
                  v-model="grupo"
                  :options="updatedGruposItems">
              </v-select>
            </div>

          </div><!--card-body-->

        </div> <!--card card-block-->

      </b-col>

    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "reportesRafa",
  data() {
    return {

      escuela:[],
      item_escuelas:[],

    ////
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: ''
    }
  },
}
</script>

<style scoped>

</style>