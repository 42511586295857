<template>
  <b-container>
    <!-- modal borrar -->
    <b-modal
        hide-header-close
        v-model="ModalBorrarInscripcion"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar la inscripción al evento: <b>"{{ form_inscripciones.nombreEvento }}"</b> ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalBorrarInscripcion()"
          >
            Cancelar
          </b-button>
          <b-button
              variant="danger"
              size="sm"
              class="float-right mx-2"
              @click="deleteInscripcion"
          >
            Eliminar
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- modal crear/editar -->
    <b-modal no-close-on-backdrop
             centered size="lg"
             hide-footer
             no-close-on-esc
             hide-header-close
             ref="ModalUser"
             title="Usuario"
             v-model="ModalInscripcion"
             persistent>
      <b-card
          tag="article"
          class="mb-2">

        <b-card-text>
          <b-row>
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
              <b-form>
                <div class="mb-1 my-2">
                  <label for="Text1" class="form-label font-weight-bold text-muted text-uppercase">Nombre del evento</label>
                  <b-form-input
                      class="my-2"
                      ref="form_user.name"
                      v-model="form_inscripciones.nombreEvento"
                      type="text"
                      placeholder="Nombre del evento de inscripción">
                  </b-form-input>
                </div>

                <b-form-checkbox
                    class="my-3"
                    v-model="form_inscripciones.programarApertura"
                    value="1">
              Programar apertura
                </b-form-checkbox>
                <b-form-group class="form-label font-weight-bold text-muted text-uppercase" label="Fecha y Hora">
                  <div class="d-flex align-items-center">
                  <b-form-datepicker locale="es"
                                     placeholder="Fecha vigencia inicial"
                                     v-model="form_inscripciones.fecha_apertura"
                                     size="sm"
                                     label-help=''
                                     label-today-button='Hoy'
                                     today-button
                                     label-no-date-selected='No se ha elegido fecha'>
                  </b-form-datepicker>

                  <b-form-timepicker
                      class="ml-2"
                      size='sm'
                      now-button
                      label-now-button='Ahora'
                      label-hours='Hr'
                      label-minutes='Min'
                      hide-header
                      label-close-button='Cerrar'
                      placeholder="Hora_apertura"
                      v-model="form_inscripciones.hora_apertura" locale="es">
                  </b-form-timepicker>
                  </div>
                </b-form-group>

                <b-form-checkbox
                    class="my-3"
                    v-model="form_inscripciones.programarCierre"
                    value="1">
                  Programar cierre
                </b-form-checkbox>

                <b-form-group class="form-label font-weight-bold text-muted text-uppercase" label="Fecha y Hora">
                  <div class="d-flex align-items-center">
                    <b-form-datepicker locale="es"
                                       placeholder="Fecha vigencia inicial"
                                       v-model="form_inscripciones.fecha_cierre"
                                       size="sm"
                                       label-help=''
                                       label-today-button='Hoy'
                                       today-button
                                       label-no-date-selected='No se ha elegido fecha'>
                    </b-form-datepicker>

                    <b-form-timepicker
                        class="ml-2"
                        size='sm'
                        now-button
                        label-now-button='Ahora'
                        label-hours='Hr'
                        label-minutes='Min'
                        hide-header
                        label-close-button='Cerrar'
                        placeholder="Hora_apertura"
                        v-model="form_inscripciones.hora_cierre" locale="es">
                    </b-form-timepicker>
                  </div>
                </b-form-group>

                <hr>
                <label for="Text1" class="form-label font-weight-bold text-muted text-uppercase">Opciones a agregar <small>Separasd por ;</small></label>
                <div class="mx-3 mt-3 p-2 criterio" >
                  <div class="d-flex" >
                    <vue-tags-input
                        placeholder="Escribir o pegar aquí las opciones separadas por un punto y coma"
                        v-model="tag"
                        :tags="tags"
                        @tags-changed="newTags => tags = newTags"
                    />
                  </div>
<!--                  {{this.tags}}-->
                </div>

                <b-form-checkbox
                    class="my-3"
                    v-model="form_inscripciones.vincularAcurricula"
                    value="1">
                 Vincular a curricula
                </b-form-checkbox>

                <v-select
                    class="my-3"
                    placeholder="Curriculas en la plataforma Aprende"
                    v-model="form_inscripciones.curriculas"
                    multiple
                    :options="curriculas_items"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No resultados para <em>{{ search }}</em
                    >.
                    </template>
                    <em v-else style="opacity: 0.5">Elegir primero.</em>
                  </template>
                </v-select>

                <b-row class="d-flex align-items-start justify-content-start my-1">
                  <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        @click="hiddenModalInscripciones()"
                    >
                      Cancelar
                    </b-button>

                    <b-button v-if="form_inscripciones.editar===true"
                              variant="info"
                              size="sm"
                              class="float-right mx-2"
                              @click="update"
                    >
                      Actualizar registro
                    </b-button>
                    <b-button v-if="form_inscripciones.editar===false"
                              variant="success"
                              size="sm"
                              class="float-right mx-2"
                              @click="save()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-row>


              </b-form>
            </div>
            <div class="col-sm-1"></div>
          </b-row>
        </b-card-text>

      </b-card>

    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Gestión de inscripciones"></b-jumbotron>
            </div>

            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible
            >
              En esta sección puede gestionar y crear eventos de inscripción para los gestores
            </b-alert>


            <b-row class="d-flex align-items-end justify-content-end my-3">
              <div class="col-sm-12 text-right">
                <b-button   @click="showModalInscripcion"
                            class="btn-studioAPP"
                            variant="outline-warning"> Crear nuevo
                </b-button>
              </div>
            </b-row>

            <b-row class="d-flex align-items-end justify-content-end my-1">
              <div class="col-sm-12 my-2">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar"
                >
                </b-form-input>
              </div>
            </b-row>

            <b-table class="table data-table"
                     striped="striped"
                     hover="hover"
                     outlined="outlined"
                     :items="inscripciones_items"
                     :fields="inscripciones_headers"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">
                  {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                </h5>
              </template>
              <template #emptyfiltered="scope">
                <h5 class="text-center">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </h5>
              </template>

              <template #cell(estatus)="row">
                <div v-if="`${row.item.estatus}`!=='0' ">
                  Abierto 🗳️ - Visible
                </div>
                <div v-else>
                  Cerrado ⛔ - No visible
                </div>
              </template>

              <template #cell(periodo)="row">
                <div v-if="`${row.item.programarApertura}`==='1' ">
                 <small> {{row.item.fecha_apertura}} a {{row.item.fecha_apertura}}</small>
                </div>
                <div v-if="`${row.item.programarCierre}`==='1' ">
                 -  <small> {{row.item.fechaCierre}} a {{row.item.hora_cierre}}</small>
                </div>
              </template>

              <template #cell(actions)="row">
                <div class="d-flex justify-content-around align-items-center">

                  <b-button variant="success" v-b-modal.modal-prevent-closing size="sm"
                            @click="EditMode(row.item)" class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                  </b-button>

                  <b-button variant="danger" size="sm" @click="showModalBorrarInscripcion(row.item)" class="ml-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path
                          d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                    </svg>
                  </b-button>

                </div>
              </template>

            </b-table>

            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>
          </div><!-- card body-->

        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Bugsnag from "@bugsnag/js";
import toast from "@/mixins/ToastMixin.js";
import InscripcionesService from "../../../services/InscripcionesService";

export default {
  name: "Inscripciones",
  mixins:[toast],
  components: {
    VueTagsInput,
  },
  mounted () {
    this.getCurriculas();
    this.getData();
  },
  data() {
    return {
      ModalInscripcion:false,
      ModalBorrarInscripcion:false,

      inscripciones_headers: [
        {   label: 'Evento',        key: 'nombreEvento', class: 'text-start'},
        {   label: 'Estatus',         key: 'estatus', class: 'text-center' },
        {   label: 'Periodo de apertura',   key: 'periodo', class: 'text-center' },
        {   label: 'Inscritos',     key: 'usuarios_inscritos_count', class: 'text-center' },
        {  label: 'Acciones',       key: 'actions', class: 'text-center' }
      ],
      inscripciones_items: [],
      curriculas_items:[],
      tag: '',
      tags: [],
      form_inscripciones: {
        nombreEvento: '',
        programarApertura: 0,
        fecha_apertura       : '',
        hora_apertura:'',
        programarCierre: 0,
        fecha_cierre       : '',
        hora_cierre       : '',
        vincularAcurricula:0,
        curriculas: [],

        id                          :0,
        editar                      :false,

      },

      ///
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: '',
    }
  },
  methods: {
    EditMode(item){
      console.log(item);
      this.ModalInscripcion=true;
      this.form_inscripciones.editar=true;
      this.form_inscripciones.id = item.id;
      this.form_inscripciones.nombreEvento = item.nombreEvento;
      this.form_inscripciones.programarApertura = item.programarApertura;
      this.form_inscripciones.fecha_apertura = item.fecha_apertura;
      this.form_inscripciones.hora_apertura=item.hora_apertura;
      this.form_inscripciones.programarCierre=item.programarCierre;
      this.form_inscripciones.fecha_cierre=item.fechaCierre;
      this.form_inscripciones.hora_cierre=item.hora_cierre;
      this.form_inscripciones.vincularAcurricula=item.vincular_curricula;
      this.form_inscripciones.curriculas = item.inscripcion_curriculas.map(curr => {
        return {
          id: curr.id,
          label: curr.curricula.nombre_curricula
        };
      });
      this.tags = item.opciones_simples;

      //this tags en EditMode
      console.log("this tags en EditMode");
      console.log(this.tags);
      //opciones_simples en EditMode
      console.log("item.opciones_simples");
      console.log(item.opciones_simples);
    },
    async update(){
      try{
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("nombreEvento", this.form_inscripciones.nombreEvento);

        if(this.form_inscripciones.programarApertura===false || this.form_inscripciones.programarApertura===0){
          formData.append("programarApertura", 0 );
        }else {
          formData.append("programarApertura", 1 );
          formData.append("fecha_apertura", this.form_inscripciones.fecha_apertura);
          formData.append("hora_apertura", this.form_inscripciones.hora_apertura);
        }

        if(this.form_inscripciones.programarCierre===false || this.form_inscripciones.programarCierre===0){
          formData.append("programarCierre", 0 );
        }else {
          formData.append("programarCierre", 1 );
          formData.append("fecha_cierre", this.form_inscripciones.fecha_cierre);
          formData.append("hora_cierre", this.form_inscripciones.hora_cierre);
        }

        let arrCurriculas = [];
        if(this.form_inscripciones.vincularAcurricula===false || this.form_inscripciones.vincularAcurricula===0){
          formData.append("vincularAcurricula", 0 );
        }else {
          formData.append("vincularAcurricula", 1 );
          this.form_inscripciones.curriculas.forEach(el => {
            arrCurriculas.push(el.id);
          });
          formData.append("arrCurriculas", arrCurriculas);
        }

        const opciones = this.tags.map(tag => tag.text);
        formData.append("opciones", JSON.stringify(opciones));

        console.log("opciones");
        console.log(opciones);

        /*
        const response = await InscripcionesService.update(this.form_inscripciones.id, formData);
        let {
            success,
            message,
            color
        } = response.data;

        console.log("response.data");
        console.log(response.data);

        if (success) {
            this.hiddenModalInscripciones();
        }
        this.toast('b-toaster-bottom-center', true, message, color);*/
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
        Bugsnag.notify(
            new Error('Método: update() ' + error.message)
        );
      }

    },

    showModalBorrarInscripcion(item){
      this.form_inscripciones.id = item.id;
      this.form_inscripciones.nombreEvento = item.nombreEvento;
      this.ModalBorrarInscripcion=true;
    },
    hideModalBorrarInscripcion(){
      this.ModalBorrarInscripcion=false;
      this.resetForm();
    },
    async deleteInscripcion(){
      try {
        const response   = await InscripcionesService.delete(this.form_inscripciones.id);
        let {   success,
                message,
                color
           } = response.data;
        if(success==true){
          this.toast('b-toaster-bottom-center', true, message, color );
          this.ModalBorrarInscripcion = false;
          await this.getData();
        }

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: borrarCurso() ' + error.message)
        );
      }
    },
   async save(){
      try {
        let formData = new FormData();
        formData.append("nombreEvento", this.form_inscripciones.nombreEvento);

        if (this.form_inscripciones.programarApertura === false || this.form_inscripciones.programarApertura === 0) {
          formData.append("programarApertura", 0);
        } else {
          formData.append("programarApertura", 1);
          formData.append("fecha_apertura", this.form_inscripciones.fecha_apertura);
          formData.append("hora_apertura", this.form_inscripciones.hora_apertura);
        }

        if (this.form_inscripciones.programarCierre === false || this.form_inscripciones.programarCierre === 0) {
          formData.append("programarCierre", 0);
        } else {
          formData.append("programarCierre", 1);
          formData.append("fecha_cierre", this.form_inscripciones.fecha_cierre);
          formData.append("hora_cierre", this.form_inscripciones.hora_cierre);
        }

        let arrCurriculas = [];
        if (this.form_inscripciones.vincularAcurricula === false || this.form_inscripciones.vincularAcurricula === 0) {
          formData.append("vincularAcurricula", 0);
        } else {
          formData.append("vincularAcurricula", 1);
          this.form_inscripciones.curriculas.forEach(el => {
            arrCurriculas.push(el.id);
          });
          formData.append("arrCurriculas", arrCurriculas);
        }

        const opciones = this.tags.map(tag => tag.text);
        formData.append("opciones", JSON.stringify(opciones));

        console.log("opciones del save");
        console.log(opciones);

       /* const response = await InscripcionesService.save(formData);

        let {
              success,
              message,
              color
          } = response.data;

          if (success) {
           await this.getData();
            this.hiddenModalInscripciones();
          }
          this.toast('b-toaster-bottom-center', true, message, color);*/
   } catch (error) {
      this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger");
      Bugsnag.notify(
          new Error('Método: save() ' + error.message)
      );
    }

    },
    showModalInscripcion(){
      this.ModalInscripcion=true;
    },
    hiddenModalInscripciones(){
      this.ModalInscripcion=false;
      this.resetForm();
    },
    resetForm(){
          this.form_inscripciones.nombreEvento= '';
          this.form_inscripciones.programarApertura= 0;
          this.form_inscripciones.fecha_apertura= '';
          this.form_inscripciones.hora_apertura='';
          this.form_inscripciones.programarCierre= 0;
          this.form_inscripciones.fecha_cierre= '';
          this.form_inscripciones.hora_cierre= '';
          this.form_inscripciones.vincularAcurricula=0;
          this.form_inscripciones.curriculas= [];
          this.form_inscripciones.id=0;
          this.form_inscripciones.editar=false;
          this.tag = '';
          this.tags= [];
    },
    async getData(){

      try {
        let response = await InscripcionesService.getInscripciones();
        let {   message,
                resultSet,
                color,
                cantidad
        } = response.data;
        this.inscripciones_items            = resultSet;

        console.log("this.inscripciones_items");
        console.log(this.inscripciones_items);

        this.totalRows              = cantidad;
        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getData() ' + error.message)
        );
      }
    },
    async getCurriculas(){
      try {
         let response = await InscripcionesService.getCurriculas();
        this.curriculas_items   = response.data.resultSet;
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getEscuelas() ' + error.message)
        );
      }
    }
  },
}
</script>

<style lang="scss" scoped>
$studioAPPColor:#FF8250;
.btn-studioAPP{
  color: $studioAPPColor !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color: $studioAPPColor !important;
  color: white !important;
}

.bi-check-circle-fill {
  fill: green;
}

.bi-x-circle-fill {
  fill: red;
}

.criterio
{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border:2px #dedede solid;
  border-radius: 15px;
}

</style>