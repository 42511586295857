<template>
  <b-container>

<!--    modal confirmación-->
    <b-modal
        hide-header-close
        v-model="ModalCrearRegistro"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            La información a continuación está a punto de ser enviada, favor de confirmar che
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="ocultarModalCrear()"
          >
            Cancelar
          </b-button>
          <b-button
              variant="success"
              size="sm"
              class="float-right mx-2"
              @click="ConfirmSave()"
          >
            Confirmar
          </b-button>
        </div>
      </template>

    </b-modal>

    <b-row>
        <b-col cols="12">
          <div class="card card-block">
            <div class="card-body p-2">
              <div class="d-flex justify-content-between align-items-start p-2">
                <b-jumbotron lead="Cargando..."></b-jumbotron>
              </div>
              <b-alert
                  class="p-3"
                  show
                  variant="info"
                  dismissible
              >
<!--                Por favor, rellena las casillas con los datos solicitados para continuar con el proceso de inscripción.-->
               <h1> Redirigendo...</h1>
              </b-alert>

<!--              <b-card-->
<!--                  tag="article"-->
<!--                  class="mb-2">-->
<!--                <b-card-text>-->
<!--                  <b-row>-->
<!--                    <div class="col-sm-1"></div>-->
<!--                    <div class="col-sm-10">-->
<!--&lt;!&ndash;                      <b-form>&ndash;&gt;-->

<!--                      <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                          <b-col>-->
<!--                            <b-form-input-->
<!--                                class="my-2"-->
<!--                                ref="form_curso.curp"-->
<!--                                v-model="form_data.curp"-->
<!--                                type="text"-->
<!--                                placeholder="CURP">-->
<!--                            </b-form-input>-->
<!--                          </b-col>-->
<!--                          <b-col>-->
<!--                            <b-form-input-->
<!--                                class="my-2"-->
<!--                                ref="form_curso.rfc"-->
<!--                                v-model="form_data.rfc"-->
<!--                                type="text"-->
<!--                                placeholder="RFC">-->
<!--                            </b-form-input>-->
<!--                          </b-col>-->
<!--                        </b-row>-->
<!--                      <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.apellido_paterno"-->
<!--                              v-model="form_data.apellido_paterno"-->
<!--                              type="text"-->
<!--                              placeholder="Apellido Paterno">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.apellido_materno"-->
<!--                              v-model="form_data.apellido_materno"-->
<!--                              type="text"-->
<!--                              placeholder="Apellido Materno">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                      </b-row>-->
<!--                      <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.nombre_curso"-->
<!--                              v-model="form_data.nombres"-->
<!--                              type="text"-->
<!--                              placeholder="Nombre(s)">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.correo_alternativo"-->
<!--                              v-model="form_data.correo_alternativo"-->
<!--                              type="text"-->
<!--                              placeholder="Correo alternativo">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                      </b-row>-->
<!--                      <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.edad"-->
<!--                              v-model="form_data.edad"-->
<!--                              type="number"-->
<!--                              step="1"-->
<!--                              placeholder="Edad">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.cct"-->
<!--                              v-model="form_data.cct"-->
<!--                              type="text"-->
<!--                              placeholder="CCT (10 dígitos)">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <v-select-->
<!--                              class="my-3"-->
<!--                              placeholder="Género"-->
<!--                              v-model="form_data.genero"-->
<!--                              :options="generos">-->
<!--                          </v-select>-->
<!--                        </b-col>-->
<!--                      </b-row>-->
<!--                      <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.alcaldia_cct"-->
<!--                              v-model="form_data.alcaldia_cct"-->
<!--                              type="text"-->
<!--                              placeholder="ALCALDÍA CCT">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.numero_celular"-->
<!--                              v-model="form_data.numero_celular"-->
<!--                              type="number"-->
<!--                              step="1"-->
<!--                              placeholder="Número celular">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.numero_fijo"-->
<!--                              v-model="form_data.numero_fijo"-->
<!--                              type="number"-->
<!--                              step="1"-->
<!--                              placeholder="Número fijo">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                      </b-row>-->
<!--                      <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.funcion"-->
<!--                              v-model="form_data.funcion"-->
<!--                              type="text"-->
<!--                              placeholder="FUNCIÓN">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <v-select-->
<!--                              class="my-3"-->
<!--                              placeholder="Nivel Educativo"-->
<!--                              v-model="form_data.nivel_educativo"-->
<!--                              :options="niveles_educativos">-->
<!--                          </v-select>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <b-form-input-->
<!--                              class="my-2"-->
<!--                              ref="form_curso.direccion_operativa"-->
<!--                              v-model="form_data.direccion_operativa"-->
<!--                              type="text"-->
<!--                              placeholder="Dirección operativa">-->
<!--                          </b-form-input>-->
<!--                        </b-col>-->
<!--                      </b-row>-->
<!--                      <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                        <b-col>-->
<!--                          <v-select-->
<!--                              class="my-3"-->
<!--                              placeholder="Nombre de la oferta de formación"-->
<!--                              v-model="form_data.nombre_oferta_formacion"-->
<!--                              :options="nombre_ofertas_formaciones">-->
<!--                          </v-select>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <v-select-->
<!--                              class="my-3"-->
<!--                              placeholder="Tipos de formación"-->
<!--                              v-model="form_data.tipos_de_formacion"-->
<!--                              :options="formaciones">-->
<!--                          </v-select>-->
<!--                        </b-col>-->
<!--                        <b-col>-->
<!--                          <v-select-->
<!--                              class="my-3"-->
<!--                              placeholder="Modalidad"-->
<!--                              v-model="form_data.modalidad"-->
<!--                              :options="modalidades">-->
<!--                          </v-select>-->
<!--                        </b-col>-->
<!--                      </b-row>-->

<!--                        &lt;!&ndash;save&ndash;&gt;-->
<!--                        <b-row class="d-flex align-items-start justify-content-start my-1">-->
<!--                          <div class="w-100">-->
<!--                            <b-button-->

<!--                                      size="sm"-->
<!--                                      class="btn-studioAPP float-right mx-2"-->

<!--                                      variant="outline-warning"-->
<!--                                      @click="mostrarModalCrear()"-->
<!--                            >-->
<!--                              Guardar-->
<!--                            </b-button>-->
<!--                          </div>-->
<!--                        </b-row>-->

<!--&lt;!&ndash;                      </b-form>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div class="col-sm-1"></div>-->
<!--                  </b-row>-->
<!--                </b-card-text>-->

<!--              </b-card>-->

            </div><!-- card-body -->
          </div><!--card card-block -->

      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
//import Vue from "vue";
import axios from "axios";


export default {
  name: "Index",
  mixins:[toast],

  mounted() {
     this.getToken();
  },
  data() {
      return {
        token:null,
        ModalCrearRegistro:false,
        form_data: {
          apellido_paterno:"KALE",
          apellido_materno:"HAAK",
          nombres:"JUAN",
          correo_alternativo: "jkah@outlook.com",

          curp:"KAHO641101B",
          rfc:"KAHO641101B39",
          tipos_de_formacion:null,
          modalidad:null,

          nombre_oferta_formacion:null,
          funcion:"aquí va la función",
          nivel_educativo:null,
          direccion_operativa:"ni idea",

          alcaldia_cct:"sabe que va",
          numero_celular:6622334762,
          numero_fijo:2507619,
          edad:35,

          cct:"iwqpoeiwqpoe",
          genero:null,
           /* apellido_paterno:null,
            apellido_materno:null,
            nombres:null,
            correo_alternativo:null,

            curp:null,
            rfc:null,
            tipos_de_formacion:null,
            modalidad:null,

            nombre_oferta_formacion:null,
            funcion:null,
            nivel_educativo:null,
            direccion_operativa:null,

            alcaldia_cct:null,
            numero_celular:null,
            numero_fijo:null,
            edad:null,

            cct:null,
            genero:null,*/
        },

        nombre_ofertas_formaciones :[
          { label: 'DGENAM',      id: "DGENAM" },
          { label: 'DGOSE',      id: "DGOSE" },
          { label: 'DATEDPD',     id: "DATEDPD" },
        ],
        niveles_educativos :[
          { label: 'Técnico',      id: "Tecnico" },
          { label: 'Licenciatura',      id: "Licenciatura" },
          { label: 'Maestría',      id: "Maestria" },
          { label: 'Doctorado',      id: "Doctorado" },
        ],
        formaciones:[
          { label: 'Taller',      id: "Taller" },
          { label: 'Curso',      id: "Curso" },
          { label: 'Diplomado',     id: "Diplomado" },
        ],
        modalidades:[
          { label: 'Presencial',      id: "Presencial" },
          { label: 'Línea',      id: "Linea" },
          { label: 'Bimodal',     id: "Bimodal" },
        ],
        generos: [
          { label: 'Hombre',      id: "Hombre" },
          { label: 'Mujer',      id: "Mujer" },
          { label: 'No binario',     id: "No_binario" },
          { label: 'Hombre transgénero',   id: "Hombre_transgenero" },
          { label: 'Mujer transgénero',   id: "Mujer_transgenero" },
          { label: 'Agénero',   id: "Agenero" },
          { label: 'Identidad de género no incluida',   id: "Identidad_no_incluida" },
          { label: 'Prefiero no especificar',   id: "Prefiero_no_especificar" }
        ],
      }

    },
  methods: {
  /*  setToken(){
      console.log(document.cookie);
      this.token = document.cookie
          .split('; ')
          .find(row => row.startsWith('XSRF-TOKEN-APRENDE'))
          ?.split('=')[1];

      //aprende_session
      console.log(this.token);
    },*/
    getToken(){

      axios.get(process.env.VUE_APP_API_URL+"/sanctum/csrf-cookie");
      console.log(document.cookie);

    },


    isValid(){

      if (!this.form_data.nombres) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el nombre', 'danger');
        return false;
      }

      if (!this.form_data.apellido_materno) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el apellido materno', 'danger');
        return false;
      }

      if (!this.form_data.apellido_paterno) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el apellido paterno', 'danger');
        return false;
      }

      if (!this.form_data.correo_alternativo) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el correo alternativo', 'danger');
        return false;
      }

      if (!this.form_data.curp) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la CURP', 'danger');
        return false;
      }

      if (!this.form_data.rfc) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el RFC', 'danger');
        return false;
      }

      if (!this.form_data.tipos_de_formacion) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el tipo de formacion', 'danger');
        return false;
      }

      if (!this.form_data.modalidad) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la modalidad', 'danger');
        return false;
      }

      if (!this.form_data.nombre_oferta_formacion) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el nombre de la oferta de formación', 'danger');
        return false;
      }

      if (!this.form_data.funcion) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la función', 'danger');
        return false;
      }

      if (!this.form_data.nivel_educativo) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el nivel educativo', 'danger');
        return false;
      }

      if (!this.form_data.direccion_operativa) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la dirección operativa', 'danger');
        return false;
      }

      if (!this.form_data.alcaldia_cct) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la alcaldía CCT', 'danger');
        return false;
      }

      if (!this.form_data.numero_celular) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el número celular', 'danger');
        return false;
      }

      if (!this.form_data.numero_fijo) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar el número fijo', 'danger');
        return false;
      }

      if (!this.form_data.edad) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la edad actual', 'danger');
        return false;
      }

      if (!this.form_data.cct) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar la CCT', 'danger');
        return false;
      }

      if (!this.form_data.genero) {
        this.toast('b-toaster-bottom-center', true, 'Hace falta especificar tu género', 'danger');
        return false;
      }

      return true;

    },

    async ConfirmSave() {

      //this.setToken();
      console.log(this.token);

     this.ocultarModalCrear();
     try {
        let formData = new FormData();
        formData.append("apellido_paterno", this.form_data.apellido_paterno);
        formData.append("apellido_materno", this.form_data.apellido_materno);
        formData.append("nombres", this.form_data.nombres);
        formData.append("correo_alternativo", this.form_data.correo_alternativo);

        formData.append("curp", this.form_data.curp);
        formData.append("rfc", this.form_data.rfc);
        formData.append("tipos_de_formacion", this.form_data.tipos_de_formacion.id);
        formData.append("modalidad", this.form_data.modalidad.id);

        formData.append("nombre_oferta_formacion", this.form_data.nombre_oferta_formacion.id);
        formData.append("funcion", this.form_data.funcion);
        formData.append("nivel_educativo", this.form_data.nivel_educativo.id);
        formData.append("direccion_operativa", this.form_data.direccion_operativa);

        formData.append("alcaldia_cct", this.form_data.alcaldia_cct);
        formData.append("numero_celular", this.form_data.numero_celular);
        formData.append("numero_fijo", this.form_data.numero_fijo);
        formData.append("edad", this.form_data.edad);

        formData.append("cct", this.form_data.cct);
        formData.append("genero", this.form_data.genero.id);

       let url = process.env.VUE_APP_API_URL+'/api/usicamm-registro';

       const response = await axios.post(url, formData, {
         headers: { 'X-XSRF-TOKEN-APRENDE': this.token},
         withCredentials: true,
       });

        let {   success,
                message,
                color
        } = response.data;

        if(success===true){
          this.toast('b-toaster-bottom-center', true, message, color );
          this.resetForm();
        }
      } catch (error) {
       console.log(error);
    //    this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
       this.toast('b-toaster-bottom-center', true, error.message, 'danger' );
        Bugsnag.notify(
            new Error('Método: guardarCurso() ' + error.message)
        );
      }


    },

    mostrarModalCrear(){
      if (!this.isValid()) {
        return;
      }
      this.ModalCrearRegistro=true;
    },
    ocultarModalCrear(){
      this.ModalCrearRegistro=false;
    },
    resetForm() {
      this.form_data.nombres              = null;
      this.form_data.apellido_paterno    = null;
      this.form_data.apellido_materno    = null;
      this.form_data.correo_alternativo   = null;
      this.form_data.curp                = null;
      this.form_data.rfc                  = null;

      this.form_data.tipos_de_formacion   = null;
      this.form_data.modalidad            = null;
      this.form_data.nombre_oferta_formacion   = null;
      this.form_data.funcion               = null;

      this.form_data.nivel_educativo       = null;
      this.form_data.direccion_operativa    = null;
      this.form_data.alcaldia_cct           = null;
      this.form_data.numero_celular         = null;

      this.form_data.numero_fijo            = null;
      this.form_data.edad                = null;
      this.form_data.cct               = null;
      this.form_data.genero            = null;
    },
  }
}
</script>

<style lang="scss" scoped>

$studioAPPColor:#952f81;

.btn-studioAPP{

  background-color: $studioAPPColor !important;
  color: white !important;
  border:1px solid $studioAPPColor;
}

.btn-studioAPP:hover, .btn-studioAPP:active{
  background-color:transparent !important;
  color: $studioAPPColor !important;
}


</style>