<template>
  <b-container>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Escuelas - alumnos"></b-jumbotron>
            </div>

            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible
            >
              En esta sección se pueden revisar los correos de la gente que haya sido dada de alta por sus gestores escolares.
            </b-alert>

            <div class="my-2 w-50 d-flex justify-content-between">
              <v-select
                  style="width: 600px"
                  size="lg"
                  class="my-3"
                  placeholder="Elegir grupo .... "
                  v-model="grupo"
                  :options="updatedGruposItems">
              </v-select>
              <div class="d-inline-block" >
                <b-button
                    variant="primary"
                    size="sm"
                    class="mx-2 my-2"
                    @click="getByEscuelaId()"
                >Obtener correos 📧
                </b-button>
              </div><!--d-inline-block-->
            </div>

            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar"
            >
            </b-form-input>

            <b-table class="table data-table mx-1"
                     striped="striped"
                     hover="hover"
                     outlined="outlined"
                     :items="reportes.items"
                     :fields="reportes.headers"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     stacked="md"
                     show-empty
                     small
            >
              <template #empty="scope">
                <h5 class="text-center">
                  {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                </h5>
              </template>
              <template #emptyfiltered="scope">
                <h5 class="text-center">
                  {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                </h5>
              </template>

            </b-table>

            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">

                <b-button  @click="exportar()"  variant="outline-primary" class="btn-studioAPP" sm>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"/>
                  </svg>
                  Exportar a CSV</b-button>
              </div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                  >
                  </b-pagination>
                </div>
              </div>
            </div>

          </div>

        </div>

      </b-col>

    </b-row>

  </b-container>
</template>

<script>
/*import jsPDF from "jspdf";
import 'jspdf-autotable';*/

import toast from "../../mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
import Service from '@/services/CoachesEscuelasAlumnosService.js';
export default {
  name: "CoachesEscuelasAlumnos",
  mixins:[toast],
  data() {
    return {
      grupo:{ id: 0, label: 'Elegir...' },
      grupos_items: [],

      reportes:{
        items:[],
        headers:[
          {   label: 'Alumno',        key: 'name', class: 'text-start'},
          {   label: 'Correo',         key: 'email', class: 'text-start' },
          {   label: 'Escuela',         key: 'nombre', class: 'text-center' }
        ]
      },

      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: ''
    }
  },
  mounted () {
      this.getData();
      this.getGrupos();
  },
  computed: {
   updatedGruposItems() {
      const customOption = { id: 0, label: 'Todos' };
      return [customOption, ...this.grupos_items];
    },
  },
  methods: {

    exportar(){

      const url = process.env.VUE_APP_API_URL+`/api/escuela-correos-csv/${this.grupo.id}`;
            window.open(url, '_blank');

/*
        let columns = [];
        var vm = this
        var doc = new jsPDF('p', 'pt');
        doc.text('Analitica', 40, 40);

          columns = [
            {title: "Alumno", dataKey: "name"},
            {title: "Email", dataKey: "email"},
            {title: "Escuela", dataKey: "nombre"},
          ];
          doc.autoTable(columns, vm.reportes.items, {
            margin: {top: 60},
          });

        doc.save('Reporte.pdf');
*/

/*      this.$refs.csv.exportCSV({
        fields: this.reportes.headers, // Replace with your column headers
        filename: 'exported-data.csv', // Adjust the filename as needed
      });*/
    },

   async getByEscuelaId(){
      try {

        var response =(this.grupo.id==0) ?  await Service.get() :  await Service.getByEscuelaId(this.grupo.id);

        let {   message,
                resultSet,
                color,
                cantidad
        } = response.data;
        this.reportes.items         = resultSet;
        this.totalRows              = cantidad;
        console.log("response.data");
        console.log(response.data);
        this.toast('b-toaster-bottom-center', true, message, color);

      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getData() ' + error.message)
        );
      }
    },

    async getData(){
          try {
            let response = await Service.get();

            let {   message,
                    resultSet,
                    color,
                    cantidad
            } = response.data;
            this.reportes.items         = resultSet;
            this.totalRows              = cantidad;
            console.log("response.data");
            console.log(response.data);
            this.toast('b-toaster-bottom-center', true, message, color);

          } catch (error) {
              this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
              Bugsnag.notify(
                  new Error('Método: getData() ' + error.message)
              );
          }
        },

    async getGrupos(){
      let url = `/api/coach-dominios-serv`;
      const response = await this.$api.get(url);
      let {   resultSet,
              success,
              message,
              color
      } = response.data;
      if(success){
        this.toast('b-toaster-bottom-center', true, message, color );
        this.grupos_items=resultSet;
       // console.log(this.grupos_items);
      }
    },
  },
}
</script>

<style scoped>

</style>