<template>
  <b-container>

    <!-- modal administrar usuarios -->
    <b-modal   no-close-on-backdrop centered size="lg"
               hide-footer
               ref="update_students"
               title="Administrar usuarios"
               v-model="modal_administrar_alumnos"
               persistent>
      <b-card
          tag="article"
          class="mb-2">
        <b-card-text>

            <b-alert show variant="info" class="p-3 text-justify">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle-fill mr-2" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              Lista de cursos activos disponibles. Puede seleccionar uno o más cursos. Al hacer clic en 'Revalidar', los cursos elegidos se marcarán como completados para el grupo seleccionado, pero por medio de REVALIDACION, no por AVANCE NORMAL </b-alert>
          <v-select
              class="my-3"
              placeholder="Listado de cursos "
              v-model="grupo_usuario_actual.cursos_a_revalidar"
              multiple
              :options="curso_items">
            <template slot="option" slot-scope="option">
              {{ option.label }}
            </template>

            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No resultados para <em>{{ search }}</em
              >.
              </template>
              <em v-else style="opacity: 0.5">Elegir curso primero</em>
            </template>
          </v-select>



          <b-row>
            <div class="w-100">
              <b-button
                  variant="secondary"
                  size="sm"
                  class="float-right"
                  @click="revalidarCurso"
              >
                Revalidar
              </b-button>

              <b-button
                  variant="dark"
                  size="sm"
                  class="float-right mx-2"
                  @click="hideModalAdministrarUsuarios()"
              >
                Cancelar
              </b-button>
            </div>
          </b-row>
        </b-card-text>

      </b-card>
    </b-modal>

    <b-row>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">

            <div class="d-flex justify-content-between align-items-start p-2">
              <b-jumbotron lead="Revalidar"></b-jumbotron>
            </div>

            <b-alert
                class="p-3"
                show
                variant="info"
                dismissible
            >
              En esta sección se muestra un listado de grupos para así, en las acciones, asignarle cursos terminados del listado a continuación.
            </b-alert>

            <div class="mx-5 px-5">
              <b-row class="d-flex align-items-end justify-content-end my-1">
                <div class="col-sm-12 my-2">
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar"
                  >
                  </b-form-input>
                </div>
              </b-row>

              <b-table class="table data-table"
                       striped="striped"
                       hover="hover"
                       outlined="outlined"
                       :items="usuario_items"
                       :fields="usuario_headers"
                       :current-page="currentPage"
                       :per-page="perPage"
                       :filter="filter"
                       :filter-included-fields="filterOn"
                       stacked="md"
                       show-empty
                       small
              >
                <template #empty="scope">
                  <h5 class="text-center">
                    {{ scope.emptyText='Por el momento no hay registros que mostrar.' }}
                  </h5>
                </template>
                <template #emptyfiltered="scope">
                  <h5 class="text-center">
                    {{ scope.emptyFilteredText='No hay registros que concuerden con los parámetros de búsqueda.' }}
                  </h5>
                </template>

                <template #cell(administrar)="row">
                  <b-button variant="outline-primary" size="sm" @click="showModalAdministrarUsuarios(row.item)" class="ml-1">
                    Administrar
                  </b-button>
                </template>

              </b-table>
              <div class="d-flex justify-content-between row p-2">
                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                  <div>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import Bugsnag from "@bugsnag/js";
import CursoService from "../../../services/CursoService";

export default {
  name: "RevalidacionCursos",
  mixins:[toast],
  data() {
    return {
      modal_administrar_alumnos:false,
      grupo_usuario_actual : {
        id:0,
        cursos_a_revalidar:[]
      },
      usuario_headers: [
        {   label: 'Grupo',        key: 'label', class: 'text-start'},
       /* {   label: '# Usuarios en el grupo',         key: 'numero_usuarios', class: 'text-center' },*/
        {   label: 'Administrar',   key: 'administrar', class: 'text-center' }
      ],
      usuario_items: [],

      curso_items: [],

      ///
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [5, 10, 15, { value: 100, text: "Ver muchos" }],
      filter: null,
      filterOn: [],
      search: ''
    }
  },
  mounted() {
   // this.getGruposUsuarios();
    this.getCursos();
    this.getEscuelas();
  },
  methods: {

    async getEscuelas(){
      try {
       // let response = await GestionUsuariosService.getEscuelas();
        const response   = await this.$api.get(`/api/gestion-u/escuelas`);
        console.log("response.data getEscuelas");
        console.log(response.data);

        let {
          resultSet
        } = response.data;



        this.totalRows      = resultSet.length;
        this.usuario_items  =  resultSet;
      } catch (error) {
       // this.toast('b-toaster-bottom-center', true, error, "danger");
        const errorMessage = error.response && error.response.status
            ? `Error ${error.response.status}: ${error.response.statusText}`
            : error.message;
        this.toast('b-toaster-bottom-center', true, errorMessage, "danger");

        Bugsnag.notify(new Error('Método: getEscuelas() ' + errorMessage));


        /*        Bugsnag.notify(
                    new Error('Método: getEscuelas() ' + error)
                );*/
      }
    },

   async revalidarCurso(){

     if (this.grupo_usuario_actual.cursos_a_revalidar && this.grupo_usuario_actual.cursos_a_revalidar.length === 0) {
       this.toast('b-toaster-bottom-center', true, "Seleccionar al menos un curso", 'danger' );
       return false;
     }

      this.toast('b-toaster-bottom-center', true, "Revalidando al grupo...esto puede tardar un momento", 'info' );

      try {
        let arrCursos = [];
        this.grupo_usuario_actual.cursos_a_revalidar.forEach(el => {
          arrCursos.push(el.id);
        });

        let formData = new FormData();
            formData.append("grupo_id",         this.grupo_usuario_actual.id);
            formData.append("arrCursos",        arrCursos);


/*            console.log("this.grupo_usuario_actual.cursos_a_revalidar");
            console.log(this.grupo_usuario_actual.cursos_a_revalidar);*/

        this.hideModalAdministrarUsuarios();
        const response = await CursoService.revalidarCursos(formData);
        let {   success,
              message,
              color
            } = response.data;

        console.log("response.data");
        console.log(response.data);

        if(success===true){
          this.toast('b-toaster-bottom-center', true, message, color );
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: revalidarCurso() ' + error.message)
        );
      }
    },
    resetForm(){
      this.grupo_usuario_actual.cursos_a_revalidar=[];
      this.grupo_usuario_actual.id= 0;
    },
    hideModalAdministrarUsuarios(){
      this.resetForm();
      this.modal_administrar_alumnos  = false;
      this.grupo_usuario_actual.id=0;
      this.grupo_usuario_actual.cursos_a_revalidar=[]
    },

    async getCursos() {
      try {
        let response = await CursoService.getCursos();

        let {   message,
                cursos,
                color
        } = response.data;
        this.curso_items            = cursos;

        this.toast('b-toaster-bottom-center', true, message, color);
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getCursos() ' + error.message)
        );
      }


    },

    async getGruposUsuarios(){
      try {
        const response   = await this.$api.get(`/api/users/get-grupos-usuarios-aprende`);
        let {   success,
                message,
                color,
                grupos_usuarios,
        } = response.data;
        this.totalRows      = await grupos_usuarios.length;
        this.usuario_items  = await grupos_usuarios;

        if(success){
          this.toast('b-toaster-bottom-center', true, message, color );
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador 🙏', "danger" );
        Bugsnag.notify(
            new Error('Método: getGruposUsuarios() ' + error.message)
        );
      }

    },
    showModalAdministrarUsuarios(item){
      this.grupo_usuario_actual.id    = item.id;
      this.modal_administrar_alumnos  = true;
    },
  },
}
</script>

<style scoped>

</style>