//import api from '@/services/api';
import {api} from "../main";

export default{
    
    saveEntregable(formData){
        let url          = `/api/entregables/store`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData 
        });
        return response;
    },
    saveEntregableURL(formData){
        let url          = `/api/entregables/store/url`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    cuadernoCalificaciones( idEntregable, calificacion ){
       
        let url          = `/api/entregables/calificaciones/cuaderno/${idEntregable}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                calificacion       : calificacion,
                idEntregable:idEntregable
            }           
             
        });

        return response;
    },
    getVerificarPresentarExam(actividad_id){
        return api({
            url: `/api/verificar-present-exam/${actividad_id}`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    getEntregablesSencillos(grupo_id, curso_id){
        return api({
            url: `/api/entregables/getEntregablesSencillos/${curso_id}/${grupo_id}`,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },
    getEntregablesCalificables(grupo_id,curso_id){

        let url          = `/api/entregables/entregablesCalificables/${curso_id}/${grupo_id}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }         
        });
        return response;
    },
    getEntregablesRubrica(grupo_id, curso_id){

        let url          = `/api/entregables/getEntregablesRubrica/${curso_id}/${grupo_id}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }         
        });
        return response;
    },
    updateEntregableCalifRubrica(idEntregable, calificacion, rubrica, criterio, nivel, alumno ){
     
        let url          = `/api/entregables/calificaciones/cuadernoRubrica/${idEntregable}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                calificacion       : calificacion,
                rubrica            : rubrica,
                criterio           : criterio,
                nivel              : nivel,
                alumno             : alumno
            }           
             
        });

        return response;
    },
    getComentario(idEntregable){
        let url          = `/api/entregables/getComentarioEntregable/${idEntregable}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }         
        });
        return response;
    },
    updateComentario(idEntregable, comentarios){
        let url          = `/api/entregables/updateComentario/${idEntregable}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                comentarios       : comentarios
            }           
             
        });

        return response;
    },
    calificarEntregaComoRecibido(idEntregable, comentarios=null){
        let url          = `/api/entregables/calificarEntregaComoRecibido/${idEntregable}`;
        let response     = api({
            url: url,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: {
                comentarios       : comentarios
            }

        });

        return response;
    },
    getEntregablesSencillosGeneral(curso_id){
        let url          = `/api/entregables/calificablesSencillosGeneral/${curso_id}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getEntregablesCalificablesGeneral(curso_id){

        let url          = `/api/entregables/getEntregablesCalificablesGeneral/${curso_id}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    getCalificablesRubricasGeneral(curso_id){

        let url          = `/api/entregables/calificablesRubricasGeneral/${curso_id}`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },

    }



