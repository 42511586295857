import {api} from "../main";

export default{

    delete(id){
        let url = `/api/inscripciones/delete/${id}`;
        return api({
            url: url,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
    },

    update(id, formData){
        return api({
            url:  `/api/inscripciones/update/${id}`,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    inscribir(formData){
        let url          = `/api/inscripciones/inscripcion-usuario`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },

    save(formData){
        let url          = `/api/inscripciones/store`;
        return api({
            url: url,
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: formData
        });
    },
    getInscripciones(){
        let url          = `/api/inscripciones`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getInscripcionesPorCurricula(){
        let url          = `/api/inscripciones/inscripcionesCurricula`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    getCurriculas(){
        let url          = `/api/inscripciones/get-curriculas`;
        let response     = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
}
